const msTeamsSubDomain = 'msteams'

// NOTE: Temporary workaround for msteams
export const getShareableOrigin = () => {
    const origin = window.location.origin

    return origin.replace(`${msTeamsSubDomain}.`, '')
}

export default getShareableOrigin
