import { createUseGetNextRandomResetHook } from './createUseGetNextRandomResetHook'
import {
    ThriveReset,
    useGetRandomThriveResetBasedOnUserHistoryLazyQuery
} from '../../graphql/generated/autogenerated'
import { ResetKeywords } from '../../components/features/ThriveResets'

export const useGetNextRandomThriveReset = createUseGetNextRandomResetHook({
    useGetNextRandomResetLazyQuery:
        useGetRandomThriveResetBasedOnUserHistoryLazyQuery,
    mapQueryResult: (query) => {
        return query?.reset?.thrive
            ?.getRandomResetBasedOnUserHistory as ThriveReset
    },
    staticQueryVariables: {
        filter: { keyword: { notIn: [ResetKeywords.Intro] } }
    }
})
