import React from 'react'
import { IconButtonProps } from '@mui/material'
import { defineMessages, useIntl } from 'react-intl'
import { PauseIcon, PlayIcon } from '../../../icons/CFPlayer'
import { IconButton, useTheme } from '@thriveglobal/thrive-web-leafkit'

export interface PlayControlProps extends IconButtonProps {
    isPlaying: boolean
}

const messages = defineMessages({
    playButtonAriaLabel: {
        defaultMessage: 'Play',
        description: 'Play button aria label'
    },
    pauseAriaLabel: {
        defaultMessage: 'Pause',
        description: 'Pause button aria label'
    }
} as const)

function PlayControl(props: PlayControlProps) {
    const { isPlaying, ...rest } = props

    const intl = useIntl()
    const theme = useTheme()

    return (
        <IconButton
            {...rest}
            aria-label={
                isPlaying
                    ? intl.formatMessage(messages.pauseAriaLabel)
                    : intl.formatMessage(messages.playButtonAriaLabel)
            }
            aria-pressed={isPlaying}
            color="secondary"
            data-testid="PlayControl"
        >
            {isPlaying ? (
                <PauseIcon sx={{ color: theme.palette.primary.contrastText }} />
            ) : (
                <PlayIcon sx={{ color: theme.palette.primary.contrastText }} />
            )}
        </IconButton>
    )
}

export default React.memo(PlayControl)
