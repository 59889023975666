export enum ResetFeatureFlags {
    IsPersonalResetEnabled = 'isPersonalResetEnabled',
    IsResetInLibraryEnabled = 'isResetTabEnabled',
    IsLearnToResetRedirectEnabled = 'isLearnToResetRedirectEnabled',
    IsResetAudioSearchEnabled = 'IsResetAudioSearchEnabled',
    IsNewSharePersonalizedResetPopupEnabled = 'IsNewSharePersonalizedResetPopupEnabled',
    HasAutomaticPersonalResets = 'hasAutomaticPersonalResets',
    IsResetFeedbackEnabled = 'isResetFeedbackEnabled',
    IsResetAutoPlayEnabled = 'IsResetAutoPlayEnabled',
    IsSkipIOSResetPosterEnabled = 'isSkipIOSResetPosterEnabled',
    IsSkipAndroidResetPosterEnabled = 'isSkipAndroidResetPosterEnabled',
    IsResetBannerEnabled = 'isResetBannerEnabled',
    IsMsTeamsPostResetSharingEnabled = 'isMsTeamsPostResetSharingEnabled'
}
