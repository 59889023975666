import { useContext } from 'react'

import {
    FavoriteResetsContext,
    FavoriteResetsProviderValue
} from './FavoriteResetsProvider'

export default function useFavoriteResets(): FavoriteResetsProviderValue {
    return useContext(FavoriteResetsContext)
}
