import { createContext, ReactNode, useCallback, useMemo } from 'react'
import { ThriveReset } from '../../graphql/generated/autogenerated'
import { useFavoriteResetsLazyListData } from './useFavoriteResetsLazyListData'

export type FavoriteResetsProviderProps = {
    children: ReactNode
}

export interface FavoriteResetsProviderValue {
    favoriteResets: ThriveReset[]
    hasMore: boolean
    isLoading: boolean
    total: number
    limit: number
    addOneFavoriteReset: (reset: ThriveReset) => void
    removeOneFavoriteReset: (reset: ThriveReset) => Promise<void>
    loadMoreFavoriteResets: () => void
}

export const defaultContextValue: FavoriteResetsProviderValue = {
    favoriteResets: [],
    hasMore: false,
    isLoading: false,
    total: 0,
    limit: 0,
    addOneFavoriteReset: () => {
        /*noop*/
    },
    removeOneFavoriteReset: () => Promise.resolve(),
    loadMoreFavoriteResets: () => {
        /*noop*/
    }
}

export const FavoriteResetsContext = createContext(defaultContextValue)

export function FavoriteResetsProvider(
    props: FavoriteResetsProviderProps
): JSX.Element {
    const { children } = props

    const {
        hasMore,
        items,
        isLoading,
        total,
        limit,
        loadMoreItems,
        addManyItems,
        removeOneItem
    } = useFavoriteResetsLazyListData()

    const handleAddOneFavoriteReset = useCallback(
        (reset: ThriveReset) => addManyItems([reset]),
        [addManyItems]
    )

    const providerValue = useMemo<FavoriteResetsProviderValue>(
        () => ({
            favoriteResets: items,
            hasMore,
            isLoading,
            total,
            limit,
            addOneFavoriteReset: handleAddOneFavoriteReset,
            removeOneFavoriteReset: removeOneItem,
            loadMoreFavoriteResets: loadMoreItems
        }),
        [
            items,
            hasMore,
            isLoading,
            total,
            limit,
            handleAddOneFavoriteReset,
            removeOneItem,
            loadMoreItems
        ]
    )

    return (
        <FavoriteResetsContext.Provider value={providerValue}>
            {children}
        </FavoriteResetsContext.Provider>
    )
}
