import React, { PropsWithChildren, useEffect, useMemo } from 'react'
import { useHistory, useLocation, matchPath } from 'react-router-dom'
import { Routes } from './routes'
import { ThriveFullscreenLoading } from '@thriveglobal/thrive-web-leafkit'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import { ResetFeatureFlags } from '../config/features'

export type LearnResetRedirectProps = PropsWithChildren<{}>

// Redirects from /learn/reset to /home if the feature flag is enabled
function LearnResetRedirect(props: LearnResetRedirectProps): JSX.Element {
    const { children } = props

    const { pathname, hash, search, state, key } = useLocation()
    const history = useHistory()

    const isLearnToResetRedirectEnabled = useIsFeatureEnabled(
        ResetFeatureFlags.IsLearnToResetRedirectEnabled,
        false,
        true
    )

    const isLearnRoute = useMemo(
        () => !!matchPath(pathname, { path: Routes.LearnReset }),
        [pathname]
    )

    useEffect(() => {
        if (isLearnRoute && isLearnToResetRedirectEnabled) {
            history.replace(
                {
                    pathname: pathname
                        .toLowerCase()
                        .replace(Routes.LearnReset, Routes.Home),
                    search,
                    hash,
                    key,
                    state
                },
                state
            )
        }
    }, [
        hash,
        history,
        isLearnRoute,
        key,
        search,
        state,
        pathname,
        isLearnToResetRedirectEnabled
    ])

    return isLearnRoute && isLearnToResetRedirectEnabled ? (
        <ThriveFullscreenLoading />
    ) : (
        <>{children}</>
    )
}

export default React.memo(LearnResetRedirect)
