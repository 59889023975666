import { useMemo, useState } from 'react'
import { DEFAULT_VOLUME } from '@thriveglobal/thrive-web-core'

export type UseResetPlayerVolumeResult = [number, (value: number) => void]

function useResetPlayerVolume(): UseResetPlayerVolumeResult {
    const [stateVolume, setStateVolume] = useState(DEFAULT_VOLUME)
    return useMemo(
        () => [stateVolume, setStateVolume],
        [stateVolume, setStateVolume]
    )
}

export default useResetPlayerVolume
