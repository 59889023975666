import { Box, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { AnimationPlaybackControls, motion, useAnimate } from 'framer-motion'
import React, { useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import useScreenOrientation from '../../../../hooks/useScreenOrientation'
import useSyncStateWithRef from '../../../../hooks/useSyncStateWithRef'
import { breathAnimationZIndex } from '../constants'
import {
    getExhaleAnimationTransactions,
    getInhaleAnimationTransactions
} from './animations'
import { defaultTextBottomMargin, mobileTextBottomMargin } from './constants'

export type ThriveInhaleExhaleIndicatorProps = {
    paused?: boolean
    restartAnimationToken?: number
    isFullScreen?: boolean
}

function ThriveInhaleExhaleIndicator(props: ThriveInhaleExhaleIndicatorProps) {
    const { paused = false, restartAnimationToken, isFullScreen = true } = props

    const inhaleAnimationControlsRef = useRef<AnimationPlaybackControls>(null)
    const [inhaleScope, animateInhale] = useAnimate()

    const exhaleAnimationControlsRef = useRef<AnimationPlaybackControls>(null)
    const [exhaleScope, animateExhale] = useAnimate()

    const theme = useTheme()
    const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))
    const isMobileLandscapeView = useMediaQuery(theme.breakpoints.down('md'))

    const { orientation } = useScreenOrientation()
    const pausedRef = useSyncStateWithRef(paused)

    useEffect(() => {
        const inhaleControls = animateInhale(
            getInhaleAnimationTransactions(inhaleScope),
            {
                repeat: Infinity,
                repeatType: 'loop'
            }
        )
        inhaleAnimationControlsRef.current = inhaleControls

        if (pausedRef.current) {
            inhaleControls.pause()
        }

        const exhaleControls = animateExhale(
            getExhaleAnimationTransactions(exhaleScope),
            {
                repeat: Infinity,
                repeatType: 'loop'
            }
        )
        exhaleAnimationControlsRef.current = exhaleControls

        if (pausedRef.current) {
            exhaleControls.pause()
        }

        return () => {
            inhaleControls.stop()
            inhaleAnimationControlsRef.current = null

            exhaleControls.stop()
            exhaleAnimationControlsRef.current = null
        }
    }, [
        animateExhale,
        animateInhale,
        exhaleScope,
        inhaleScope,
        pausedRef,
        orientation
    ])

    useEffect(() => {
        if (paused) {
            inhaleAnimationControlsRef.current?.pause()
            exhaleAnimationControlsRef.current?.pause()
        } else {
            inhaleAnimationControlsRef.current?.play()
            exhaleAnimationControlsRef.current?.play()
        }
    }, [paused])

    useEffect(() => {
        if (restartAnimationToken) {
            inhaleAnimationControlsRef.current?.stop()
            inhaleAnimationControlsRef.current?.play()

            exhaleAnimationControlsRef.current?.stop()
            exhaleAnimationControlsRef.current?.play()
        }
    }, [restartAnimationToken])

    return (
        <Box
            sx={{
                position: 'absolute',
                display: 'flex',
                overflow: 'hidden',
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                zIndex: breathAnimationZIndex
            }}
            data-testid="ThriveInhaleExhaleIndicator"
            aria-hidden
            aria-live="polite"
        >
            <motion.div
                key={'thrive-inhale-indicator'}
                style={{
                    position: 'absolute',
                    color: theme.palette.common.white,
                    marginBottom: `${
                        !isFullScreen || isMobileLandscapeView
                            ? mobileTextBottomMargin
                            : defaultTextBottomMargin
                    }rem`,
                    alignSelf: 'flex-end',
                    opacity: 0
                }}
                data-testid="ThriveInhaleExhaleIndicatorAnimation"
                ref={inhaleScope}
                initial={false}
            >
                <CoreTypography
                    {...(isMobileView || !isFullScreen
                        ? { variant: 'body1' }
                        : { customVariant: 'stat3' })}
                >
                    <FormattedMessage
                        defaultMessage="Inhale"
                        description="Personalized reset player inhale text"
                    />
                </CoreTypography>
            </motion.div>
            <motion.div
                key={'thrive-exhale-indicator'}
                style={{
                    position: 'absolute',
                    color: theme.palette.common.white,
                    marginBottom: `${
                        !isFullScreen || isMobileLandscapeView
                            ? mobileTextBottomMargin
                            : defaultTextBottomMargin
                    }rem`,
                    alignSelf: 'flex-end',
                    opacity: 0
                }}
                ref={exhaleScope}
                initial={false}
            >
                <CoreTypography
                    {...(isMobileView || !isFullScreen
                        ? { variant: 'body1' }
                        : { customVariant: 'stat3' })}
                >
                    <FormattedMessage
                        defaultMessage="Exhale"
                        description="Personalized reset player exhale text"
                    />
                </CoreTypography>
            </motion.div>
        </Box>
    )
}

export default React.memo(ThriveInhaleExhaleIndicator)
