import { useCallback, useState } from 'react'
import { defaultLoadMoreLimit } from '../usePagination/constants'

export type UseLazyListWithBreakpointsOptions = {
    loadedItemsCount: number
    hasMore: boolean
    isLoading: boolean
    loadMore: () => void
    breakpoint?: number
    getNextBreakPoint?: (
        previousBreakpoint: number,
        initialBreakpoint: number
    ) => number
}

export type UseLazyListWithBreakpointsResult = {
    isBreakpointReached: boolean
    canLoadMoreWithScroll: boolean
    loadMoreWithScroll: () => void
    canLoadMoreManually: boolean
    loadMoreManually: () => void
}

// Wrapper for useLazyList to break infinite loading when breakpoint is reached
// 1. Load elements with scrolling until breakpoint-1 is reached (loadMoreWithScroll)
// 2. Load elements manually (loadMoreManually) and set next breakpoint
// 3. Load elements with scrolling until breakpoint-2 is reached again (loadMoreWithScroll)
// 4. Load elements manually (loadMoreManually) and set next breakpoint
// ...
export function useLazyListWithBreakpoints({
    breakpoint: initialBreakpoint = defaultLoadMoreLimit,
    loadedItemsCount,
    hasMore,
    isLoading,
    loadMore,
    getNextBreakPoint = (previous: number, initial: number) =>
        previous + initial
}: UseLazyListWithBreakpointsOptions): UseLazyListWithBreakpointsResult {
    const [breakpoint, setBreakpoint] = useState(initialBreakpoint)

    const isBreakpointReached = loadedItemsCount >= breakpoint

    const canLoadMoreWithScroll = !isBreakpointReached && hasMore

    const canLoadMoreManually = hasMore

    const handleLoadMoreWithScroll = useCallback(() => {
        if (canLoadMoreWithScroll && !isLoading) {
            loadMore()
        }
    }, [isLoading, canLoadMoreWithScroll, loadMore])

    const handleLoadMoreManually = useCallback(() => {
        if (!isLoading && canLoadMoreManually) {
            if (isBreakpointReached) {
                setBreakpoint((prevBreakpoint) =>
                    getNextBreakPoint(prevBreakpoint, initialBreakpoint)
                )
            }
            loadMore()
        }
    }, [
        canLoadMoreManually,
        isLoading,
        loadMore,
        getNextBreakPoint,
        initialBreakpoint,
        isBreakpointReached
    ])

    return {
        isBreakpointReached,
        canLoadMoreWithScroll,
        canLoadMoreManually,
        loadMoreManually: handleLoadMoreManually,
        loadMoreWithScroll: handleLoadMoreWithScroll
    }
}

export default useLazyListWithBreakpoints
