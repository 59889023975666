import { RefObject, useLayoutEffect, useState } from 'react'
import debounce from 'lodash/debounce'

function useElementSize(
    elementRef: RefObject<HTMLElement>,
    debounceTime = 100
) {
    const [size, setSize] = useState(() => {
        return {
            width: elementRef.current?.offsetWidth ?? 0,
            height: elementRef.current?.offsetHeight ?? 0
        }
    })

    useLayoutEffect(() => {
        let observer: ResizeObserver

        if (elementRef.current) {
            observer = new ResizeObserver(
                debounce((entries: ResizeObserverEntry[]) => {
                    const [entry] = entries

                    setSize({
                        width: entry.contentRect.width ?? 0,
                        height: entry.contentRect.height ?? 0
                    })
                }, debounceTime)
            )

            observer.observe(elementRef.current)
        }

        return () => {
            observer?.disconnect()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementRef.current, debounceTime])

    return size
}

export default useElementSize
