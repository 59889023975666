import { ComponentType } from 'react'
import { FavoriteResetsProvider } from './FavoriteResetsProvider'

export default function withFavoriteResetsProvider<Props>(
    Component: ComponentType<Props>
) {
    return (props: Props): JSX.Element => {
        return (
            <FavoriteResetsProvider>
                <Component {...props} />
            </FavoriteResetsProvider>
        )
    }
}
