import { useEffect, useRef } from 'react'

function useSyncStateWithRef<T>(state: T): React.MutableRefObject<T> {
    const ref = useRef<T>(state)

    useEffect(() => {
        ref.current = state
    }, [state])

    return ref
}

export default useSyncStateWithRef
