import { createUseGetNextRandomResetHook } from './createUseGetNextRandomResetHook'
import {
    ThriveResetAudio,
    useGetRandomThriveAudioResetBasedOnUserHistoryLazyQuery
} from '../../graphql/generated/autogenerated'

export const useGetNextRandomThriveAudioReset = createUseGetNextRandomResetHook(
    {
        useGetNextRandomResetLazyQuery:
            useGetRandomThriveAudioResetBasedOnUserHistoryLazyQuery,
        mapQueryResult: (query) => {
            return query?.reset?.thriveAudio
                ?.getRandomBasedOnUserHistory as ThriveResetAudio
        }
    }
)
