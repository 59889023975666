import {
    ErrorScreen,
    ErrorScreenVariant
} from '@thriveglobal/thrive-web-leafkit'
import React from 'react'

export type PageFallbackProps = {}

function PageFallback(props: PageFallbackProps): JSX.Element {
    return <ErrorScreen variant={ErrorScreenVariant.General} />
}

export default React.memo(PageFallback)
