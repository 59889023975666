import { useCallback, useState } from 'react'
import {
    useCreateLikeThriveVideoResetMutation,
    ThriveReset,
    useDeleteLikeThriveVideoResetMutation,
    GetThriveResetsQuery,
    ThriveResetAudio
} from '../../graphql/generated/autogenerated'
import { upsertOneThriveReset } from '../../slices/thriveResets'
import { useAppDispatch } from '../../slices'
import { useApolloClientCacheEvict } from '../../hooks/useApolloClientCacheEvict'

export type UseThriveResetLikeResult = {
    isLoading: boolean
    createResetLike: (reset: ThriveReset) => Promise<void>
    deleteResetLike: (reset: ThriveReset) => Promise<void>
}

function useThriveVideoResetLike(): UseThriveResetLikeResult {
    const [isLoading, setIsLoading] = useState(false)
    const [createLikeReset] = useCreateLikeThriveVideoResetMutation()
    const [deleteResetLike] = useDeleteLikeThriveVideoResetMutation()
    const dispatch = useAppDispatch()
    const { evictFieldFromCache } = useApolloClientCacheEvict()

    const handleCreateResetLike = useCallback(
        async (reset: ThriveReset) => {
            if (isLoading) return

            setIsLoading(true)

            try {
                const likesCount = reset.likes.numberOfLikes + 1
                const totalLikesCount = reset.likes.totalNumberOfLikes + 1
                const updatedReset: ThriveReset = {
                    ...reset,
                    likes: {
                        isLiked: true,
                        numberOfLikes: likesCount,
                        totalNumberOfLikes: totalLikesCount
                    }
                }
                dispatch(upsertOneThriveReset(updatedReset))

                await createLikeReset({
                    variables: {
                        resetId: reset.id
                    }
                })

                evictFieldFromCache<GetThriveResetsQuery>('reset', 'thrive')
            } finally {
                setIsLoading(false)
            }
        },
        [createLikeReset, evictFieldFromCache, dispatch, isLoading]
    )

    const handleDeleteResetLike = useCallback(
        async (reset: ThriveReset) => {
            if (isLoading) return

            setIsLoading(true)
            try {
                const likesCount = Math.max(reset.likes.numberOfLikes - 1, 0)
                const totalLikesCount = Math.max(
                    reset.likes.totalNumberOfLikes - 1,
                    0
                )
                const updatedReset: ThriveReset = {
                    ...reset,
                    likes: {
                        isLiked: false,
                        numberOfLikes: likesCount,
                        totalNumberOfLikes: totalLikesCount
                    }
                }
                dispatch(upsertOneThriveReset(updatedReset))

                await deleteResetLike({
                    variables: {
                        resetId: reset.id
                    }
                })

                evictFieldFromCache<GetThriveResetsQuery>('reset', 'thrive')
            } finally {
                setIsLoading(false)
            }
        },
        [evictFieldFromCache, dispatch, deleteResetLike, isLoading]
    )

    return {
        isLoading,
        createResetLike: handleCreateResetLike,
        deleteResetLike: handleDeleteResetLike
    }
}

export default useThriveVideoResetLike
