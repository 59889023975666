import React, { Suspense } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import {
    useIsFeatureEnabled,
    useAppSelector
} from '@thriveglobal/thrive-web-core'
import {
    ProtectedRoute,
    hasTroLicense
} from '@thriveglobal/thrive-web-auth-core'
import {
    useTheme,
    ThriveFullscreenLoading
} from '@thriveglobal/thrive-web-leafkit'
import Home from '../pages/Home'
import NewPersonalizedReset from '../pages/NewPersonalizedReset'
import EditPersonalizedReset from '../pages/EditPersonalizedReset'
import { Routes } from './routes'
import ThriveReset from '../pages/ThriveReset'
import PersonalizedReset from '../pages/PersonalizedReset'
import ThriveAudioReset from '../pages/ThriveAudioReset'
import ThriveRandomResetPage from '../pages/ThriveReset/RandomReset'
import ThriveDailyResetPage from '../pages/ThriveReset/DailyReset'
import { ResetFeatureFlags } from '../config/features'
import LearnResetRedirect from './LearnResetRedirect'

const Router: React.FC = () => {
    const theme = useTheme()

    const isResetInLibraryEnabled = useIsFeatureEnabled(
        ResetFeatureFlags.IsResetInLibraryEnabled,
        false,
        true
    )

    const isLearnToResetRedirectEnabled = useIsFeatureEnabled(
        ResetFeatureFlags.IsLearnToResetRedirectEnabled,
        false,
        true
    )

    const authSlice = useAppSelector(({ auth }) => auth)
    const isThriveResetOnly = hasTroLicense(authSlice)

    const basename =
        isResetInLibraryEnabled &&
        !isThriveResetOnly &&
        !isLearnToResetRedirectEnabled
            ? Routes.Learn
            : undefined

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter basename={basename} key={basename}>
                <Suspense
                    fallback={
                        !isResetInLibraryEnabled ? (
                            <ThriveFullscreenLoading />
                        ) : (
                            <></>
                        )
                    }
                >
                    <LearnResetRedirect>
                        <Switch>
                            <ProtectedRoute
                                path={Routes.EditPersonalizedReset}
                                component={EditPersonalizedReset}
                            />
                            <ProtectedRoute
                                path={Routes.NewPersonalizedReset}
                                component={NewPersonalizedReset}
                            />
                            <ProtectedRoute
                                path={Routes.ThriveAudioReset}
                                component={ThriveAudioReset}
                            />
                            <ProtectedRoute
                                path={Routes.RandomReset}
                                component={ThriveRandomResetPage}
                            />
                            <ProtectedRoute
                                path={Routes.DailyReset}
                                component={ThriveDailyResetPage}
                            />
                            <ProtectedRoute
                                path={Routes.ThriveReset}
                                component={ThriveReset}
                            />
                            <ProtectedRoute
                                path={Routes.PersonalizedReset}
                                component={PersonalizedReset}
                            />
                            <ProtectedRoute
                                path={Routes.Home}
                                component={Home}
                            />
                        </Switch>
                    </LearnResetRedirect>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default React.memo(Router)
