export function triggerElementReflow(element: HTMLElement) {
    if (element) {
        // HACK: to trigger DOM reflow
        // https://stackoverflow.com/questions/6268508/restart-animation-in-css3-any-better-way-than-removing-the-element
        element.offsetWidth
    }
}

export function restartAnimation(element: HTMLElement) {
    if (element) {
        element.style.animation = 'none'

        triggerElementReflow(element)

        // fallback to CSS rule that set animation
        element.style.animation = null
    }
}
