import React, { useMemo } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export interface ThriveAudioResetLogoProps extends SvgIconProps {
    lineColor?: string
    logoColor?: string
}

function ThriveAudioResetLogo(props: ThriveAudioResetLogoProps) {
    const {
        sx,
        lineColor = '#725985',
        logoColor = '#4C2C65',
        ...restProps
    } = props

    const iconStyles = useMemo(() => {
        return sx ?? {}
    }, [sx])

    return (
        <SvgIcon
            width="262"
            height="262"
            viewBox="0 0 262 262"
            fill="none"
            sx={{
                width: '262px',
                height: '262px',
                ...iconStyles
            }}
            {...restProps}
            data-testid="ThriveAudioResetLogoIcon"
        >
            <circle
                cx="131"
                cy="131"
                r="95.2385"
                fill="none"
                stroke="#725985"
                strokeWidth="6.02299"
            />
            <circle
                cx="131"
                cy="131.002"
                r="52.7639"
                fill="#7C6288"
                stroke="#7C6288"
                strokeWidth="6.02299"
            />
            <g clipPath="url(#clip0_5509_43207)">
                <path
                    d="M115.908 120.292C113.619 122.642 111.626 124.699 109.902 126.542L123.017 139.897C123.26 139.763 123.502 139.656 123.798 139.656C124.687 139.656 125.414 140.378 125.414 141.259C125.414 141.553 125.306 141.847 125.145 142.087L129.13 146.147C129.319 145.96 129.534 145.826 129.804 145.72V125.313C129.319 125.126 128.969 124.699 128.861 124.165L120.89 116.045C120.728 116.098 120.567 116.152 120.405 116.152C120.324 116.152 120.243 116.152 120.163 116.125C118.735 117.461 117.308 118.849 115.908 120.292Z"
                    fill={logoColor}
                />
                <path
                    d="M130.907 122.322C131.177 122.402 131.419 122.535 131.607 122.722L149.516 104.506C146.58 102.182 143.214 100.393 139.579 99.2441C137.236 101.087 134.273 103.518 130.934 106.376V122.322H130.907Z"
                    fill={logoColor}
                />
                <path
                    d="M122.021 114.523C122.021 114.843 121.913 115.137 121.725 115.377L129.13 122.936C129.319 122.696 129.534 122.482 129.831 122.375V107.284C127.353 109.448 124.687 111.825 121.994 114.336C122.021 114.416 122.021 114.469 122.021 114.523Z"
                    fill={logoColor}
                />
                <path
                    d="M142.514 135.009C142.433 134.902 142.38 134.768 142.353 134.635C142.218 134.929 142.11 135.249 141.976 135.57L142.514 135.009Z"
                    fill="#5DB4BC"
                />
                <path
                    d="M143.887 132.632C144.156 132.632 144.426 132.712 144.641 132.846L159.183 118.048C158.025 114.816 156.329 111.825 154.174 109.181C151.643 113.908 148.519 119.838 143.079 132.846C143.348 132.712 143.618 132.632 143.887 132.632Z"
                    fill={logoColor}
                />
                <path
                    d="M151.698 106.457C151.24 106.029 150.782 105.602 150.297 105.201L132.039 123.818C132.039 123.845 132.039 123.871 132.039 123.871C132.039 124.593 131.554 125.18 130.881 125.394V144.732C132.82 140.191 134.705 135.918 136.186 133.06C139.795 126.035 147.712 112.974 151.698 106.457Z"
                    fill={logoColor}
                />
                <path
                    d="M128.726 147.242L124.363 142.781C124.202 142.835 124.013 142.888 123.825 142.888C122.936 142.888 122.209 142.167 122.209 141.286C122.209 141.072 122.263 140.885 122.316 140.698L109.175 127.316C105.108 131.697 102.685 134.635 101.257 136.451C105.028 147.456 115.072 158.888 122.963 164.23C124.39 160.597 126.787 154.534 129.318 148.471C128.968 148.177 128.726 147.75 128.726 147.242Z"
                    fill={logoColor}
                />
                <path
                    d="M133.546 166.5C143.026 162.333 160.988 143.075 160.988 128.198C160.988 125.046 160.503 122.001 159.588 119.116L145.369 133.593C145.449 133.78 145.503 133.994 145.503 134.207C145.503 135.089 144.776 135.81 143.887 135.81C143.672 135.81 143.484 135.757 143.322 135.703L140.898 138.16C140.467 139.202 140.036 140.27 139.606 141.392C136.401 149.325 134.354 161.184 133.546 166.5Z"
                    fill={logoColor}
                />
                <path
                    d="M118.789 114.523C118.789 114.336 118.843 114.176 118.897 114.015L110.333 105.281C106.374 108.673 103.331 113.054 101.554 118.022L109.902 126.542C111.625 124.699 113.618 122.616 115.907 120.292C117.308 118.877 118.735 117.461 120.135 116.125C119.381 115.992 118.789 115.324 118.789 114.523Z"
                    fill={logoColor}
                />
                <path
                    d="M130.907 106.35C134.22 103.492 137.209 101.088 139.552 99.218C136.832 98.3633 133.923 97.8825 130.907 97.8291V106.35Z"
                    fill={logoColor}
                />
                <path
                    d="M111.141 104.586L119.57 113.187C119.813 113.027 120.082 112.92 120.405 112.92C121.24 112.92 121.913 113.534 121.994 114.335C124.687 111.798 127.353 109.421 129.83 107.284V97.8555C122.748 97.989 116.258 100.473 111.141 104.586Z"
                    fill={logoColor}
                />
                <path
                    d="M101.15 119.116C100.234 122.001 99.7495 125.046 99.7495 128.198C99.7495 130.842 100.315 133.62 101.258 136.451C102.658 134.635 105.109 131.697 109.175 127.316L101.15 119.116Z"
                    fill={logoColor}
                />
                <path
                    d="M131.554 146.174L141.975 135.57C142.11 135.25 142.245 134.956 142.352 134.635C142.299 134.502 142.272 134.368 142.272 134.208C142.272 133.62 142.595 133.086 143.106 132.819C148.546 119.811 151.67 113.882 154.202 109.154C153.421 108.193 152.586 107.284 151.697 106.43C147.685 112.974 139.794 126.035 136.212 133.006C134.731 135.864 132.846 140.138 130.907 144.678V145.72C131.15 145.827 131.365 145.987 131.554 146.174Z"
                    fill={logoColor}
                />
                <path
                    d="M129.83 148.738C129.641 148.685 129.479 148.578 129.318 148.444C126.786 154.507 124.39 160.597 122.962 164.203C125.602 165.993 127.998 167.088 129.83 167.275V148.738Z"
                    fill={logoColor}
                />
                <path
                    d="M130.907 148.738V167.301C131.688 167.221 132.577 166.954 133.546 166.5C134.354 161.185 136.401 149.326 139.579 141.419C140.036 140.298 140.467 139.229 140.871 138.188L131.931 147.296C131.931 147.963 131.5 148.524 130.907 148.738Z"
                    fill={logoColor}
                />
            </g>
            <circle
                cx="131"
                cy="131"
                r="127.989"
                fill="none"
                stroke="#70537B"
                strokeWidth="6.02299"
            />
            <defs>
                <clipPath id="clip0_5509_43207">
                    <rect
                        width="61.2386"
                        height="69.4458"
                        fill="white"
                        transform="translate(99.7495 97.8555)"
                    />
                </clipPath>
            </defs>
        </SvgIcon>
    )
}

export default ThriveAudioResetLogo
