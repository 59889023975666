import {
    ErrorBoundary,
    type ErrorBoundaryProps
} from '@thriveglobal/thrive-web-errors'
import React from 'react'

export type ErrorBoundaryWrapperProps = Omit<
    ErrorBoundaryProps,
    'packageName' | 'packageVersion' | 'packageOwner'
>

function ErrorBoundaryWrapper(props: ErrorBoundaryWrapperProps): JSX.Element {
    const { boundaryName, boundaryType, children, ...restProps } = props

    return (
        <ErrorBoundary
            boundaryName={boundaryName}
            boundaryType={boundaryType}
            packageName={process.env.APP_NAME}
            packageOwner={process.env.PROJECT_OWNER}
            packageVersion={process.env.APP_VERSION}
            {...restProps}
        >
            {children}
        </ErrorBoundary>
    )
}

export default React.memo(ErrorBoundaryWrapper)
