/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useState } from 'react'
import unionBy from 'lodash/unionBy'
import { UseLazyListState } from './types'
import { without } from '../../utils/arrays/without'

export type CreateUseLazyListStateHookOptions<T> = {
    mapItemToKey: (item: T) => string | number | Symbol
}

// Create hook to keep items in state
export function createUseLazyListStateHook<T>({
    mapItemToKey
}: CreateUseLazyListStateHookOptions<T>): UseLazyListState<T> {
    return () => {
        const [items, setItems] = useState<T[]>([])
        const [isLoading, setIsLoading] = useState(false)
        const [total, setTotal] = useState(0)

        const handleAddManyItems = useCallback(
            (newItems: T[], isPrepend?: boolean) => {
                if (newItems.length === 0) {
                    return
                }

                if (isPrepend) {
                    setItems((previous) =>
                        unionBy(newItems, previous, mapItemToKey)
                    )
                } else {
                    setItems((previous) =>
                        unionBy(previous, newItems, mapItemToKey)
                    )
                }
            },
            []
        )

        const handleRemoveManyItems = useCallback((removedItems: T[]) => {
            setItems((previous) => {
                if (removedItems.length === 0) {
                    return previous
                }

                return without(
                    previous,
                    removedItems,
                    (first, second) =>
                        mapItemToKey(first) === mapItemToKey(second)
                )
            })
        }, [])

        const handleResetItems = useCallback(() => {
            setTotal(0)
            setItems([])
            setIsLoading(false)
        }, [])

        return {
            items,
            addManyItems: handleAddManyItems,
            removeManyItems: handleRemoveManyItems,
            isLoading,
            setIsLoading,
            total,
            setTotal,
            reset: handleResetItems
        }
    }
}

export default createUseLazyListStateHook
