import {
    isMicrosoftTeamsTest,
    useGenerateTeamsDeepLink
} from '@thriveglobal/thrive-web-core'
import { useMemo } from 'react'
import { generatePath } from 'react-router-dom'
import { Routes } from '../../routes/routes'
import {
    appendSearchParams,
    getShareableOrigin,
    stringifySearchParams
} from '../../utils/url'

export const useResetShareUrls = <
    TRoute extends object,
    TSearch extends Record<string, string> = Record<string, string>
>(
    routeParams: TRoute,
    route: string,
    searchParams: TSearch = {} as TSearch
) => {
    const generateTeamsDeepLink = useGenerateTeamsDeepLink()
    const isInLibrary = location.pathname.startsWith(Routes.LearnReset)

    return useMemo(() => {
        const path = generatePath(
            isInLibrary ? `${Routes.Learn}${route}` : route,
            routeParams
        )

        let url: URL
        if (isMicrosoftTeamsTest()) {
            const pathParams = stringifySearchParams(searchParams, '')
            const teamsPath = appendSearchParams(path.substring(1), pathParams)

            url = generateTeamsDeepLink(teamsPath)
        } else {
            const origin = getShareableOrigin()
            url = new URL(path, origin)

            Object.keys(searchParams).forEach((key) => {
                if (searchParams[key] && !url.searchParams.has(key)) {
                    url.searchParams.append(key, searchParams[key])
                }
            })
        }

        return { resetUrl: url.toString(), resetPath: path }
    }, [isInLibrary, route, routeParams, searchParams, generateTeamsDeepLink])
}

export default useResetShareUrls
