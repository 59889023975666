import {
    CombinedState,
    createSelector,
    createSlice,
    Draft,
    isDraft,
    original
} from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'
import { ThriveReset } from '../graphql/generated/autogenerated'

export const thriveResetsSliceName = 'thriveWebReset_thriveResets'

export type ThriveResetsState = {
    byId: { [id: string]: ThriveReset }
}

const initialState: ThriveResetsState = {
    byId: {}
}

export type RootStateWithThriveResetsState = CombinedState<{
    [thriveResetsSliceName]: ThriveResetsState
}>

const upsertOneReducer = (
    draft: Draft<ThriveResetsState>,
    reset: ThriveReset
): ThriveResetsState => {
    const { byId } = draft

    const oldReset = byId[reset.id]

    // If reset has not changed, do nothing
    if (
        oldReset && isDraft(oldReset)
            ? isEqual(original(oldReset), reset)
            : isEqual(oldReset, reset)
    ) {
        return draft
    }

    byId[reset.id] = reset

    return draft
}

export const thriveResetsSlice = createSlice({
    name: thriveResetsSliceName,
    initialState,
    reducers: {
        upsertManyThriveResets: (
            state,
            { payload }: { payload: ThriveReset[] }
        ) => payload.reduce(upsertOneReducer, state),
        upsertOneThriveReset: (state, { payload }: { payload: ThriveReset }) =>
            upsertOneReducer(state, payload)
    }
})

export const { upsertManyThriveResets, upsertOneThriveReset } =
    thriveResetsSlice.actions

export type ThriveResetsSliceActions =
    (typeof thriveResetsSlice.actions)[keyof typeof thriveResetsSlice.actions]

export const selectThriveResetsState = (
    rootState: RootStateWithThriveResetsState
) => rootState[thriveResetsSliceName]

// TODO: Temporary workaround, remove hardcoded return types once pnpm + typescript issue is resolved.

export const selectThriveResetById = createSelector(
    (state) => selectThriveResetsState(state),
    (_, id: string) => id,
    (state, id) => state.byId[id]
) as (state: RootStateWithThriveResetsState, id: string) => ThriveReset

export const selectThriveResetsByIds = createSelector(
    (state) => selectThriveResetsState(state),
    (_, ids: string[]) => ids,
    (state, ids) => ids.map((id) => state.byId[id]).filter(Boolean)
) as (state: RootStateWithThriveResetsState, ids: string[]) => ThriveReset[]

export default thriveResetsSlice.reducer
