/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { LazyQueryHookOptions } from '@apollo/client'
import {
    usePagination,
    defaultPaginationLimit,
    initialPage
} from '../usePagination'
import {
    GetItemsQueryVariables,
    UseGetItemsQuery,
    UseGetItemsQueryMapper,
    UseLazyList,
    UseLazyListState
} from './types'

export type CreateUseLazyListHookOptions<TItem, TQuery, TVariables> = {
    useGetItemsLazyQuery: UseGetItemsQuery<TQuery, TVariables>
    useLazyListState: UseLazyListState<TItem>
    mapQueryResult: UseGetItemsQueryMapper<TItem, TQuery>
    mapQueryVariables: (page: number, limit: number) => TVariables
    paginationLimit?: number
}

// Create hook to CRUD list of items.
// NOTE: This code should be simplified once page+limit will be replaced with offset+limit.
export function createUseLazyListHook<
    TItem,
    TQuery,
    TVariables extends GetItemsQueryVariables
>({
    useGetItemsLazyQuery,
    useLazyListState,
    mapQueryResult,
    mapQueryVariables,
    paginationLimit = defaultPaginationLimit
}: CreateUseLazyListHookOptions<TItem, TQuery, TVariables>): UseLazyList<
    TItem,
    TVariables
> {
    return function useLazyListHook({ onItemsLoaded, variables } = {}) {
        const {
            isLoading,
            items,
            setIsLoading,
            addManyItems,
            removeManyItems,
            setTotal,
            total,
            reset: resetState
        } = useLazyListState()
        const [offset, setOffset] = useState(0) // offset is the number of upserted items affecting pagination

        // NOTE: need to prevent adding data to state from aborted request
        const requestIdRef = useRef<number>(null)

        const { page, setNextPage, limit, hasMore, resetPagination } =
            usePagination({
                total,
                limit: paginationLimit
            })

        const initialVariables = useMemo<TVariables>(
            () => ({
                ...mapQueryVariables(initialPage, paginationLimit),
                ...variables
            }),
            [variables]
        )

        // WORKAROUND: We need to increment current page when user uploaded enough items to fill in the entire page
        // Scenario 1: Upload less than limit items:
        // - Load 1st page: [1,2,3] (page: 1, total: 8, hasMore: true)
        // - Upload 2 items [a,b]: [a,b,1], [2,3] (page: 1, total: 10, hasMore: true)
        // - Scroll down to the next page: [a,b,1], [2,3,4] (page: 2, total: 10, hasMore: true)
        // Scenario 2: Upload limit items:
        // - Load 1st page: [1,2,3] (page: 1, total: 8, hasMore: true)
        // - Upload 3 items [a,b, c]: [a,b,c], [1,2,3] (page: 1, total: 11, hasMore: true)
        // - Effect is triggered to set current page to 2
        // - Scroll down to the next page: [a,b,c], [1,2,3], [4,5,6] (page: 3, total: 10, hasMore: true)
        // Scenario 3: Upload more items when there is no more pages:
        // - Load all pages: [1,2,3] (page: 1, total: 3, hasMore: false)
        // - Upload 1 item [a]: [a,1,2], [3] (page: 1, total: 4, hasMore: true)
        // - Effect is triggered to set current page to 2
        useEffect(() => {
            if (offset >= limit && hasMore) {
                // Scenario 1 & 2
                setNextPage()
                setOffset((previousOffset) => previousOffset - limit)
            } else if (
                // Scenario 3
                offset > 0 &&
                items.length === total &&
                hasMore
            ) {
                setNextPage()
            }
        }, [offset, limit, hasMore, setNextPage, items.length, total, page])

        const handleDataLoaded = useCallback(
            (data: TQuery, requestId: number) => {
                if (
                    requestId &&
                    requestIdRef.current &&
                    requestIdRef.current !== requestId
                ) {
                    return
                }

                const { items: newItems, total: newTotal } =
                    mapQueryResult(data)

                setTotal(newTotal)
                setIsLoading(false)

                if (newItems.length === 0) {
                    return
                }

                addManyItems(newItems)
                setOffset((previousOffset) =>
                    Math.max(previousOffset - newItems.length, 0)
                )

                onItemsLoaded?.(newItems)
            },
            [addManyItems, setTotal, setIsLoading, onItemsLoaded]
        )

        const queryOptions = useMemo<
            LazyQueryHookOptions<TQuery, TVariables>
        >(() => {
            return {
                onError: () => setIsLoading(false),
                nextFetchPolicy: 'standby', // NOTE: Prevent re-fetching when cache is wiped to prevent redundant requests as we keep items in useLazyListState.
                fetchPolicy: 'standby' // NOTE: To prevent auto firing first request without variables from GQL
            }
        }, [setIsLoading])

        // NOTE: fetchMore is recreated after 1st request (issue is fixed in @apollo/client@3.5.8)
        const [_, { fetchMore }] = useGetItemsLazyQuery(queryOptions)

        // Get 1st page. Use effect with useLazyQuery instead of useQuery to prevent multiple requests to fetch the 1st page.
        useEffect(() => {
            const requestId = Date.now()
            requestIdRef.current = requestId

            setIsLoading(true)
            const loadFirstPage = async () => {
                try {
                    const { data } = await fetchMore({
                        variables: initialVariables
                    })

                    handleDataLoaded(data, requestId)
                } catch {
                    setIsLoading(false)
                }
            }
            loadFirstPage()

            return () => {
                requestIdRef.current = null
                setOffset(0)
                resetState()
                resetPagination()
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            initialVariables,
            resetState,
            // Ignore fetchMore as it is recreated after 1st request. Remove when migrated to @apollo/client@^3.5.8
            // fetchMore,
            resetPagination,
            handleDataLoaded,
            setIsLoading
        ])

        const handleAddManyItems = useCallback(
            (newItems: TItem[]) => {
                if (newItems.length === 0) {
                    return
                }

                addManyItems(newItems, true)
                setOffset((previousOffset) => previousOffset + newItems.length)
                setTotal((previousTotal) => previousTotal + newItems.length)
            },
            [addManyItems, setTotal]
        )

        const handleRemoveOneItem = useCallback(
            async (removedItem: TItem) => {
                removeManyItems([removedItem])
                setTotal((previousTotal) => previousTotal - 1)

                if (hasMore && offset === 0) {
                    const requestId = Date.now()
                    requestIdRef.current = requestId

                    // WORKAROUND: Load 1 element from the next page as all elements indexes are shifted
                    // Load 1st page: [1,2,3]  (page: 1, total: 8, hasMore: true)
                    // Remove "3": [1,2] (page: 1, total: 7, hasMore: true)
                    // Load 1 element to compensate: [1,2,4] (page: 1, total: 7, hasMore: true)
                    setIsLoading(true)
                    const nextElementPage = page * paginationLimit
                    const { data } = await fetchMore({
                        variables: {
                            ...mapQueryVariables(nextElementPage, 1),
                            ...variables
                        }
                    })

                    handleDataLoaded(data, requestId)
                }

                setOffset((previousOffset) => Math.max(previousOffset - 1, 0))
            },
            [
                variables,
                removeManyItems,
                setTotal,
                hasMore,
                fetchMore,
                handleDataLoaded,
                page,
                setIsLoading,
                offset
            ]
        )

        const handleLoadMoreItems = useCallback(async () => {
            if (isLoading || !hasMore) {
                return
            }

            const requestId = Date.now()
            requestIdRef.current = requestId

            setIsLoading(true)

            const nextPage = setNextPage()
            try {
                const { data } = await fetchMore({
                    variables: {
                        ...mapQueryVariables(nextPage, limit),
                        ...variables
                    }
                })
                handleDataLoaded(data, requestId)
            } catch {
                setIsLoading(false)
            }
        }, [
            variables,
            isLoading,
            hasMore,
            setNextPage,
            fetchMore,
            limit,
            handleDataLoaded,
            setIsLoading
        ])

        return {
            items,
            hasMore,
            isLoading,
            total,
            limit,
            page,
            addManyItems: handleAddManyItems,
            removeOneItem: handleRemoveOneItem,
            loadMoreItems: handleLoadMoreItems
        }
    }
}

export default createUseLazyListHook
