import React, { PropsWithChildren } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { isTouchDevice } from '../../../utils/devices'

export type ResponsiveDndProviderProps = PropsWithChildren<{}>

const backend = isTouchDevice() ? TouchBackend : HTML5Backend

function ResponsiveDndProvider(props: ResponsiveDndProviderProps): JSX.Element {
    const { children } = props

    return <DndProvider backend={backend}>{children}</DndProvider>
}

export default React.memo(ResponsiveDndProvider)
