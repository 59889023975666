import { lazy } from 'react'
import { withPageFallback } from '../../components/fallbacks/PageFallback'

export const PersonalizedReset = lazy(() => import('./PersonalizedReset'))

const PersonalizedResetWithFallback = withPageFallback(PersonalizedReset, {
    pageName: 'PersonalizedResetPage'
})

export default PersonalizedResetWithFallback
