import {
    PersonalizedReset,
    useGetPersonalizedResetsByIdsLazyQuery
} from '../../../graphql/generated/autogenerated'
import { createUseSingleResetHook } from '../../../hooks/useSingleReset'
import {
    selectPersonalizedResetsByIds,
    upsertManyPersonalizedResets
} from '../../../slices/personalizedResets'

export const useSinglePersonalizedReset = createUseSingleResetHook({
    useGetSingleResetLazyQuery: useGetPersonalizedResetsByIdsLazyQuery,
    selectItemsByIds: selectPersonalizedResetsByIds,
    upsertManyItems: upsertManyPersonalizedResets,
    mapQueryResult: (query) => {
        return {
            items: (query?.reset?.personalized?.getByIds ??
                []) as PersonalizedReset[]
        }
    }
})
