import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { ComponentType, useCallback } from 'react'
import { PostThriveAudioResetActionsProps } from '../../components/features/ThriveAudioReset/PostThriveAudioReset/PostThriveAudioResetActions'
import { AudioProvider } from '../../graphql/generated/autogenerated'

export type PostThriveAudioResetActionsTrackableProps =
    PostThriveAudioResetActionsProps & {
        utmSource?: string
    }

function withPostThriveAudioResetActionsTracking(
    PostThriveAudioResetActions: ComponentType<PostThriveAudioResetActionsTrackableProps>
) {
    return React.memo(function PostThriveAudioResetActionsTrackable(
        props: PostThriveAudioResetActionsTrackableProps
    ) {
        const {
            onClapped,
            onFavored,
            onShared,
            onLiked,
            onDisliked,
            ...restProps
        } = props
        const { reset } = props
        const user = useAppSelector((state) => state.user)

        const trackOnClapped = useCallback(
            (isChecked) => {
                if (isChecked) {
                    Avo.reactionSubmitted({
                        featureType: 'content',
                        activityType: 'clap_submitted',
                        contentType: 'reset',
                        contentFormatType: 'audio',
                        contentId: reset?.id,
                        contentTitle: reset?.name,
                        contentUrl: reset?.mp3Url,
                        resetType: 'reset',
                        contentSource: 'thrive',
                        feedbackAnswer: 'positive',
                        contentSubtype: null,
                        conversationId: null,
                        feedbackQuestion: null,
                        messageType: null,
                        openAnswer: null,
                        openQuestion: null,
                        tabName: null,
                        transactionId: null,
                        isSystemEvent: false
                    })
                }
            },
            [reset?.id, reset?.mp3Url, reset?.name]
        )

        const onClappedTrackable: PostThriveAudioResetActionsProps['onClapped'] =
            useCallback(
                (...args) => {
                    trackOnClapped(...args)

                    return onClapped?.call(null, ...args)
                },
                [onClapped, trackOnClapped]
            )

        const trackOnFavored = useCallback(
            (isChecked) => {
                if (isChecked) {
                    Avo.reactionSubmitted({
                        featureType: 'content',
                        activityType: 'favorite_submitted',
                        contentType: 'reset',
                        contentFormatType: 'audio',
                        contentId: reset?.id,
                        contentTitle: reset?.name,
                        contentUrl: reset?.mp3Url,
                        resetType: 'reset',
                        contentSource: 'thrive',
                        feedbackAnswer: 'positive',
                        contentSubtype: null,
                        conversationId: null,
                        feedbackQuestion: null,
                        messageType: null,
                        openAnswer: null,
                        openQuestion: null,
                        tabName: null,
                        transactionId: null,
                        isSystemEvent: false
                    })
                }
            },
            [reset?.id, reset?.mp3Url, reset?.name]
        )

        const onFavoredTrackable: PostThriveAudioResetActionsProps['onFavored'] =
            useCallback(
                (...args) => {
                    trackOnFavored(...args)

                    return onFavored?.call(null, ...args)
                },
                [onFavored, trackOnFavored]
            )

        const trackOnShared = useCallback(
            () =>
                Avo.contentShared({
                    featureType: 'content',
                    activityType: 'reset_shared',
                    contentId: reset?.id,
                    contentType: 'reset',
                    contentTitle: reset?.name,
                    contentFormatType: 'audio',
                    contentSource:
                        reset.provider === AudioProvider.Umg ? 'umg' : 'thrive',
                    contentUrl: reset?.mp3Url,
                    resetType: 'reset',
                    contentSubtype: null,
                    pageSection: null,
                    isSystemEvent: false
                }),
            [reset?.id, reset?.mp3Url, reset?.name, reset.provider]
        )

        const onSharedTrackable: PostThriveAudioResetActionsProps['onShared'] =
            useCallback(
                (...args) => {
                    trackOnShared(...args)

                    return onShared?.call(null, ...args)
                },
                [onShared, trackOnShared]
            )

        const trackOnLiked = useCallback(() => {
            Avo.reactionSubmitted({
                featureType: 'content',
                activityType: 'reset_like_submitted',
                contentType: 'reset',
                contentFormatType: 'audio',
                contentId: reset?.id,
                contentTitle: reset?.name,
                contentUrl: reset?.mp3Url,
                resetType: 'reset',
                contentSource: 'thrive',
                feedbackAnswer: 'positive',
                contentSubtype: null,
                conversationId: null,
                feedbackQuestion: null,
                messageType: null,
                openAnswer: null,
                openQuestion: null,
                tabName: null,
                transactionId: null,
                isSystemEvent: false
            })
        }, [reset?.id, reset?.mp3Url, reset?.name])

        const onLikedTrackable: PostThriveAudioResetActionsProps['onLiked'] =
            useCallback(
                (...args) => {
                    trackOnLiked(...args)

                    return onLiked?.call(null, ...args)
                },
                [onLiked, trackOnLiked]
            )

        const trackOnDisliked = useCallback(() => {
            Avo.reactionSubmitted({
                featureType: 'content',
                activityType: 'reset_dislike_submitted',
                contentType: 'reset',
                contentFormatType: 'audio',
                contentId: reset?.id,
                contentTitle: reset?.name,
                contentUrl: reset?.mp3Url,
                resetType: 'reset',
                contentSource: 'thrive',
                feedbackAnswer: 'negative',
                contentSubtype: null,
                conversationId: null,
                feedbackQuestion: null,
                messageType: null,
                openAnswer: null,
                openQuestion: null,
                tabName: null,
                transactionId: null,
                isSystemEvent: false
            })
        }, [reset?.id, reset?.mp3Url, reset?.name])

        const onDislikedTrackable: PostThriveAudioResetActionsProps['onDisliked'] =
            useCallback(
                (...args) => {
                    trackOnDisliked(...args)

                    return onDisliked?.call(null, ...args)
                },
                [onDisliked, trackOnDisliked]
            )

        return (
            <PostThriveAudioResetActions
                {...restProps}
                onClapped={onClappedTrackable}
                onFavored={onFavoredTrackable}
                onShared={onSharedTrackable}
                onLiked={onLikedTrackable}
                onDisliked={onDislikedTrackable}
            />
        )
    })
}

export default withPostThriveAudioResetActionsTracking
