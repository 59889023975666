import { CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import { Img } from 'react-image'

export type ThriveImageProps = {
    src: string
}

const ImgStyled = styled(Img)({
    width: '100.25%',
    height: '100%',
    borderRadius: '10px',
    objectFit: 'contain'
})

function ThriveImage(props: ThriveImageProps) {
    return <ImgStyled src={props.src} loader={<CircularProgress />} />
}

export default React.memo(ThriveImage)
