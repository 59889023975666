import { LeafConfirmationDialog } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

const messages = defineMessages({
    playNextResetLabel: {
        defaultMessage: 'Keep watching',
        description:
            'Autoplay next reset confirmation dialog confirmation button label'
    },
    cancelLabel: {
        defaultMessage: 'Go to Home page',
        description:
            'Autoplay next reset confirmation dialog cancel button label'
    }
} as const)

export type PostResetNextResetAutoPlayConfirmationDialogProps = {
    open: boolean
    onConfirm: () => void
    onCancel: () => void
}

function PostResetNextResetAutoPlayConfirmationDialog(
    props: PostResetNextResetAutoPlayConfirmationDialogProps
): JSX.Element {
    const { open, onConfirm, onCancel } = props
    const intl = useIntl()

    return (
        <LeafConfirmationDialog
            data-testid="PostResetNextResetAutoPlayConfirmationDialog"
            open={open}
            dialogContent={
                <FormattedMessage
                    defaultMessage="It looks like you're inactive, so we've paused autoplay for you."
                    description="Autoplay next reset confirmation dialog content"
                />
            }
            dialogTitle={
                <FormattedMessage
                    defaultMessage="Are you still there?"
                    description="Autoplay next reset confirmation dialog title"
                />
            }
            fullWidth
            onClose={onCancel}
            primaryButtonOnClick={onConfirm}
            primaryButtonText={intl.formatMessage(messages.playNextResetLabel)}
            secondaryButtonOnClick={onCancel}
            secondaryButtonText={intl.formatMessage(messages.cancelLabel)}
        />
    )
}

export default React.memo(PostResetNextResetAutoPlayConfirmationDialog)
