import { Stack } from '@mui/material'
import React, { CSSProperties, useMemo } from 'react'
import { Img } from 'react-image'

export type ThriveBlurPhotoBackgroundProps = {
    src?: string
    fullScreen?: boolean
    children: React.ReactElement | React.ReactElement[]
}

function ThriveBlurPhotoBackground(props: ThriveBlurPhotoBackgroundProps) {
    const backgroundStyle: CSSProperties = useMemo(() => {
        return {
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: '-1',
            filter: 'blur(80px)'
        }
    }, [])

    return (
        <Stack
            display="flex"
            flexWrap="wrap"
            flexDirection="column"
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            position="relative"
            overflow={'hidden'}
            data-testid="ThriveBlurPhotoBackground"
            sx={[
                props.fullScreen && {
                    borderRadius: '0px',
                    height: '100%',
                    width: '100%',
                    zIndex: 0
                }
            ]}
        >
            {props.children}
            <Img src={props.src} style={backgroundStyle} />
        </Stack>
    )
}

export default React.memo(ThriveBlurPhotoBackground)
