export class ErrorWithCode extends Error {
    readonly code: string

    constructor(message: string, code: string) {
        super(message)

        this.code = code
    }
}

export enum PersonalizedResetFormErrorType {
    General = 'General',
    PersonalizedImageUploadError = 'PersonalizedImageUploadError'
}

export interface PersonalizedResetFormError {
    type: PersonalizedResetFormErrorType
    message: string
}

export interface PersonalizedResetFormImageUploadError
    extends PersonalizedResetFormError {
    type: PersonalizedResetFormErrorType.PersonalizedImageUploadError
    fileNames?: string[]
}
