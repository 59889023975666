import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'

export const CopiedTooltipStyled: React.FC<TooltipProps> = styled(
    ({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    )
)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        ...theme.typography.body2
    }
}))
