import clamp from 'lodash/clamp'
import React, {
    ComponentType,
    MutableRefObject,
    useCallback,
    useRef
} from 'react'
import { getProgressInPercent } from '../../../hooks/useAudioProgress/utils'
import useTrackOnce from '../../../tracking/useTrackOnce'

export type CustomPlayerProps = {
    onTimeUpdate?: (newCurrentTime: number) => void
}

export interface CustomPlayerWithOnWatchedProps extends CustomPlayerProps {
    // id to reset onWatched state
    resetId: string
    // Callback will trigger once the user reaches watchedPercentage
    onWatched?: () => void
    // Percentage value in [0, 100] range
    watchedPercentage?: number
}

export const maxPercentage = 100
export const minPercentage = 0
export const resetCompletePercentage = 80

function withOnWatched<TProps extends CustomPlayerProps>(
    CustomPlayerComponent: ComponentType<TProps>
) {
    return React.memo(
        React.forwardRef<
            HTMLAudioElement,
            TProps & CustomPlayerWithOnWatchedProps
        >(function CustomPlayerWithOnWatched(
            props: TProps & CustomPlayerWithOnWatchedProps,
            ref: MutableRefObject<HTMLAudioElement>
        ) {
            const {
                resetId,
                watchedPercentage = resetCompletePercentage,
                onWatched,
                ...restProps
            } = props

            const watchedPercentageNormalized = clamp(
                watchedPercentage,
                minPercentage,
                maxPercentage
            )

            const innerPlayerRef = useRef<HTMLAudioElement>(null)
            const playerRef = ref ?? innerPlayerRef

            const onWatchedOnce = useTrackOnce(onWatched, resetId)

            const handleTimeUpdate: CustomPlayerProps['onTimeUpdate'] =
                useCallback(
                    (...args) => {
                        const audioPlayerElement = playerRef.current

                        if (audioPlayerElement) {
                            const { currentTime = 0, duration = 0 } =
                                audioPlayerElement ?? {}

                            const progress = getProgressInPercent(
                                currentTime,
                                duration
                            )

                            const hasVideoCompleted =
                                progress >= watchedPercentageNormalized
                            if (hasVideoCompleted) {
                                onWatchedOnce()
                            }
                        }

                        props.onTimeUpdate?.call(null, ...args)
                    },
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    [
                        props.onTimeUpdate,
                        onWatchedOnce,
                        watchedPercentageNormalized
                    ] // Ignore playerRef
                )

            const decoratedProps = {
                ...restProps,
                onTimeUpdate: handleTimeUpdate
            } as TProps

            return (
                <CustomPlayerComponent
                    {...decoratedProps}
                    ref={playerRef}
                    onTimeUpdate={handleTimeUpdate}
                />
            )
        })
    )
}

export default withOnWatched
