import { Box } from '@mui/material'
import React from 'react'
import { ResetAsset } from '../../../types'
import { isImageAsset, isQuoteAsset } from '../../../utils/utils'
import { quoteCardBackgroundColor } from '../../elements/QuoteCard/constants'
import ThrivePhotoCard from './ThrivePhotoCard'
import ThriveQuoteCard from './ThriveQuoteCard'

export type FrameSlideProps = {
    frame: ResetAsset
}

function FrameSlide(props: FrameSlideProps) {
    const { frame } = props

    return (
        <Box height="100%" width="100%" data-testid="FrameSlide">
            {isQuoteAsset(frame) && (
                <ThriveQuoteCard
                    id={frame.id}
                    quote={frame.text}
                    author={frame.author}
                    textColor={frame.textColor}
                    backgroundColor={quoteCardBackgroundColor}
                    fullScreen
                />
            )}
            {isImageAsset(frame) && (
                <ThrivePhotoCard
                    id={frame.id}
                    src={frame.urls?.xl}
                    fullScreen
                />
            )}
        </Box>
    )
}

export default React.memo(FrameSlide)
