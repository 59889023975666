/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useState } from 'react'
import union from 'lodash/union'
import without from 'lodash/without'
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit'
import { UseLazyListState } from './types'
import { AppState, useAppDispatch, useAppSelector } from '../../slices'

export type CreateUseLazyListReduxStateHookOptions<T> = {
    mapItemToKey: (item: T) => string
    selectItems: (state: AppState, ids: string[]) => T[]
    upsertManyItems?: ActionCreatorWithOptionalPayload<T[], string>
    removeManyItems?: ActionCreatorWithOptionalPayload<T[], string>
}

// Create hook to keep items in state
export function createUseLazyListReduxStateHook<T>({
    mapItemToKey,
    selectItems,
    upsertManyItems,
    removeManyItems
}: CreateUseLazyListReduxStateHookOptions<T>): UseLazyListState<T> {
    return () => {
        const [itemsIds, setItemsIds] = useState<string[]>([])
        const [isLoading, setIsLoading] = useState(false)
        const [total, setTotal] = useState(0)

        const dispatch = useAppDispatch()

        const items = useAppSelector((state) => selectItems(state, itemsIds))

        const handleAddManyItems = useCallback(
            (newItems: T[], isPrepend?: boolean) => {
                if (newItems.length === 0) {
                    return
                }

                if (upsertManyItems) {
                    dispatch(upsertManyItems(newItems))
                }

                const newItemsIds = newItems.map(mapItemToKey)

                if (isPrepend) {
                    setItemsIds((previous) => union(newItemsIds, previous))
                } else {
                    setItemsIds((previous) => union(previous, newItemsIds))
                }
            },
            [dispatch]
        )

        const handleRemoveManyItems = useCallback(
            (removedItems: T[]) => {
                setItemsIds((previous) => {
                    if (removedItems.length === 0) {
                        return previous
                    }

                    if (removeManyItems) {
                        dispatch(removeManyItems(removedItems))
                    }

                    const removedItemsIds = removedItems.map(mapItemToKey)

                    return without(previous, ...removedItemsIds)
                })
            },
            [dispatch]
        )

        const handleResetItems = useCallback(() => {
            setTotal(0)
            setItemsIds([])
            setIsLoading(false)
        }, [])

        return {
            items: items,
            addManyItems: handleAddManyItems,
            removeManyItems: handleRemoveManyItems,
            isLoading,
            setIsLoading,
            total,
            setTotal,
            reset: handleResetItems
        }
    }
}

export default createUseLazyListReduxStateHook
