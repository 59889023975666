import { useMemo } from 'react'
import { useResetPlayerVolume } from '../../elements/CustomResetPlayer'
import { musicFadeDuration } from './constants'
import { roundVolume } from './utils'

export type UsePersonalizedResetPlayerVolumeOptions = {
    time: number
    duration: number
}

export type UsePersonalizedResetPlayerVolumeResult = [
    number,
    (value: number) => void,
    {
        adjustedVolume: number // fades in/out during the first/last 5 seconds of the audio
    }
]

function usePersonalizedResetPlayerVolume({
    time,
    duration
}: UsePersonalizedResetPlayerVolumeOptions): UsePersonalizedResetPlayerVolumeResult {
    const [volume, setVolume] = useResetPlayerVolume()

    const adjustedVolume = useMemo<number>(() => {
        if (time < musicFadeDuration) {
            return roundVolume((time / musicFadeDuration) * volume)
        }

        if (time > duration - musicFadeDuration) {
            return roundVolume(((duration - time) / musicFadeDuration) * volume)
        }

        return volume
    }, [time, duration, volume])

    return useMemo<UsePersonalizedResetPlayerVolumeResult>(
        () => [volume, setVolume, { adjustedVolume }],
        [volume, adjustedVolume, setVolume]
    )
}

export default usePersonalizedResetPlayerVolume
