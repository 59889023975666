import { useSyncStateWithRef } from '@thriveglobal/thrive-web-leafkit'
import { useCallback, useMemo, useRef, useState } from 'react'

export type UseTimerOptions = {
    from: number
    to: number
    step: number
    onCompleted?: () => void
}

export type UseTimerResult = {
    value: number
    isRunning: boolean
    start: () => void
    stop: () => void
    reset: () => void
}

function useTimer({
    from,
    to,
    step,
    onCompleted
}: UseTimerOptions): UseTimerResult {
    const intervalRef = useRef<number | null>(null)
    const [value, setValue] = useState<number>(() => from)
    const [isRunning, setIsRunning] = useState(false)

    const onCompletedRef = useSyncStateWithRef(onCompleted)

    const handleStart = useCallback(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
            intervalRef.current = null
        }

        setIsRunning(true)
        setValue(from)

        intervalRef.current = setInterval(() => {
            setValue((prev) => {
                const next = prev + step
                if (step > 0 ? next < to : next > to) {
                    return next
                }

                clearInterval(intervalRef.current as number)
                intervalRef.current = null
                setIsRunning(false)
                onCompletedRef.current?.()
                return to
            })
        }, Math.abs(step * 1000)) as unknown as number
    }, [step, from, to, onCompletedRef])

    const handleStop = useCallback(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
            intervalRef.current = null
        }

        setIsRunning(false)
    }, [])

    const handleReset = useCallback(() => {
        handleStop()

        setValue(from)
        setIsRunning(false)
    }, [from, handleStop])

    return useMemo<UseTimerResult>(
        () => ({
            value,
            isRunning,
            start: handleStart,
            stop: handleStop,
            reset: handleReset
        }),
        [handleReset, handleStart, handleStop, isRunning, value]
    )
}

export default useTimer
