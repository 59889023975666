import React, { useCallback } from 'react'
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import PlayControl from './PlayControl'
import VolumeControl from './VolumeControl'
import TimeLineControl from './TimeLineControl'

export type PlayerControlsProps = {
    onPlay: () => void
    onPause: () => void
    isPlaying: boolean
    onVolumeChange: (volume: number) => void
    volume: number
    duration: number
    currentTime: number
    onCurrentTimeChange?: (time: number) => void
    onCurrentTimeChangeCommitted?: (time: number) => void
}

function PlayerControls(props: PlayerControlsProps) {
    const {
        onPlay,
        onPause,
        isPlaying,
        volume,
        onVolumeChange,
        duration,
        currentTime,
        onCurrentTimeChange,
        onCurrentTimeChangeCommitted
    } = props

    const theme = useTheme()

    const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

    const handlePlayClick = useCallback(() => {
        if (isPlaying) {
            onPause()
        } else {
            onPlay()
        }
    }, [isPlaying, onPlay, onPause])

    // TODO: remove when changing current time will be implemented in PersonalizedResetPlayer
    const isTimeLineVisible =
        Boolean(onCurrentTimeChange) || Boolean(onCurrentTimeChangeCommitted)

    return (
        <Stack
            sx={{ height: '100%', width: '100%' }}
            data-testid="PersonalizedResetPlayerControls"
        >
            <Stack direction="column" sx={{ width: '100%' }}>
                <Box
                    sx={[
                        { pb: theme.spacing(1.5) },
                        isMobileView && { px: theme.spacing(1.625), pb: 0 }
                    ]}
                >
                    {isTimeLineVisible && (
                        <TimeLineControl
                            duration={duration}
                            currentTime={currentTime}
                            onCurrentTimeChange={onCurrentTimeChange}
                            onCurrentTimeChangeCommitted={
                                onCurrentTimeChangeCommitted
                            }
                        />
                    )}
                </Box>
                <Stack direction="row" spacing={1}>
                    <PlayControl
                        isPlaying={isPlaying}
                        onClick={handlePlayClick}
                        size={isMobileView ? 'small' : 'medium'}
                    />
                    <VolumeControl
                        volume={volume}
                        onVolumeChange={onVolumeChange}
                    />
                </Stack>
            </Stack>
        </Stack>
    )
}

export default React.memo(PlayerControls)
