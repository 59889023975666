import React from 'react'
import { Box, CircularProgress } from '@mui/material'

export type PlayerContentFullscreenLoadingProps = {
    loadingColor?: string
}

function PlayerContentFullscreenLoading(
    props: PlayerContentFullscreenLoadingProps
) {
    const { loadingColor } = props

    return (
        <Box
            justifyContent="center"
            alignItems="center"
            display="flex"
            height="100%"
            width="100%"
            position="absolute"
            zIndex={0}
            data-testid="PlayerContentFullscreenLoading"
        >
            <CircularProgress sx={{ color: loadingColor }} />
        </Box>
    )
}

export default React.memo(PlayerContentFullscreenLoading)
