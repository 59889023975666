import clamp from 'lodash/clamp'
import round from 'lodash/round'
import { defaultResetDurationInSeconds } from '../../../constants/limitations'
import { PersonalizedReset } from '../../../graphql/generated/autogenerated'
import { volumeLevelMax, volumeLevelMin } from './constants'

export function getPersonalizedResetAudioSegmentDuration(
    personalizedReset: PersonalizedReset
): number {
    if (!personalizedReset) return 0

    const audioStartTime = personalizedReset.audioStartTime ?? 0

    const audioEndTime =
        personalizedReset.audioEndTime ?? defaultResetDurationInSeconds

    const audioSegmentDuration = audioEndTime - audioStartTime

    return audioSegmentDuration
}

export const roundVolume = (value) =>
    clamp(round(value, 2), volumeLevelMin, volumeLevelMax)
