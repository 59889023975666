import {
    selectThriveAudioResetsByIds,
    upsertManyThriveAudioResets
} from '../../slices/thriveAudioResets'
import {
    ThriveResetAudio,
    useGetFavoriteThriveAudioResetsLazyQuery
} from '../../graphql/generated/autogenerated'
import {
    createUseLazyListHook,
    createUseLazyListReduxStateHook
} from '../../hooks/useLazyList'
import { initialLimit } from '../../components/features/ThriveResets/constants'

export const useFavoriteAudioThriveResetsState =
    createUseLazyListReduxStateHook<ThriveResetAudio>({
        mapItemToKey: (item) => item.id,
        selectItems: selectThriveAudioResetsByIds,
        upsertManyItems: upsertManyThriveAudioResets
    })

export const useFavoriteThriveAudioResetsLazyListData = createUseLazyListHook({
    useGetItemsLazyQuery: useGetFavoriteThriveAudioResetsLazyQuery,
    useLazyListState: useFavoriteAudioThriveResetsState,
    mapQueryResult: (data) => {
        return {
            items: (data?.resetV2?.audio?.bookmark?.bookmarked?.items ??
                []) as ThriveResetAudio[],
            total: data?.resetV2?.audio?.bookmark?.bookmarked?.total ?? 0
        }
    },
    mapQueryVariables: (page, limit) => ({ page, limit }),
    paginationLimit: initialLimit
})
