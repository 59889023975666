import { ErrorWithCode } from '../../types'

export const isErrorWithCode = (error: Error, code: string): boolean => {
    if (!!error && error instanceof ErrorWithCode) {
        return error.code === code
    }

    return false
}

export default isErrorWithCode
