import { AnimationScope, AnimationSequence } from 'framer-motion'

// inhale animation v3
export const getInhaleAnimationTransactions = (
    scope: AnimationScope<any>
): AnimationSequence => [
    [
        scope.current,
        {
            opacity: [0, 1]
        },
        {
            duration: 1.5,
            ease: 'easeOut'
        }
    ],
    [
        scope.current,
        {
            opacity: [1, 1]
        },
        {
            duration: 2
        }
    ],
    [
        scope.current,
        {
            opacity: [1, 0]
        },
        {
            duration: 3,
            ease: 'easeIn'
        }
    ],
    [
        scope.current,
        {
            opacity: [0, 0]
        },
        { duration: 8.5 }
    ]
]

// exhale animation v3
export const getExhaleAnimationTransactions = (
    scope: AnimationScope<any>
): AnimationSequence => [
    [
        scope.current,
        {
            opacity: [0, 0]
        },
        { duration: 7.5 }
    ],
    [
        scope.current,
        {
            opacity: [0, 1]
        },
        {
            duration: 1.5,
            ease: 'easeOut'
        }
    ],
    [
        scope.current,
        {
            opacity: [1, 1]
        },
        {
            duration: 2
        }
    ],
    [
        scope.current,
        {
            opacity: [1, 0]
        },
        {
            duration: 3,
            ease: 'easeIn'
        }
    ],
    [
        scope.current,
        {
            opacity: [0, 0]
        },
        { duration: 1 }
    ]
]
