import { copyTextToClipboardFallback } from './copyTextToClipboardFallback'
import { CopyTextToClipboardOptions } from './types'

export const copyTextToClipboard = async (
    text: string,
    options?: CopyTextToClipboardOptions
): Promise<void> => {
    try {
        await window.navigator.clipboard.writeText(text)
    } catch {
        const isCopied = copyTextToClipboardFallback(text, options)
        return isCopied ? Promise.resolve() : Promise.reject()
    }
}

export default copyTextToClipboard
