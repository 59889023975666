import { Stack } from '@mui/material'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import { LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useRef } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ResetFeatureFlags } from '../../../../config/features'
import { ResetTypes } from '../../../../constants/resetType'
import {
    Feedback,
    ThriveReset
} from '../../../../graphql/generated/autogenerated'
import { useThriveFavoriteReset } from '../../../../hooks/useResetFavorite'
import { useResetFeedback } from '../../../../hooks/useResetFeedback'
import { useThriveResetVideoLike } from '../../../../hooks/useResetLike'
import { PostResetActionButton } from '../../../elements/PostResetScreen'
import ShareResetButton from '../../../elements/ShareResetButton'

export type PostThriveResetActionsProps = {
    thriveReset: ThriveReset
    onReplay?: () => void
    onShared?: () => void
    onFavored?: (isChecked: boolean) => void
    onClapped?: (isChecked: boolean) => void
    onLiked?: () => void
    onDisliked?: () => void
}

const messages = defineMessages({
    replayAriaLabel: {
        defaultMessage: 'Replay',
        description: 'Post Reset replay button aria label'
    },
    favoriteAriaLabel: {
        defaultMessage: 'Favorite',
        description: 'Post Reset favorite button aria label'
    },
    clapAriaLabel: {
        defaultMessage: 'Clap',
        description: 'Post Reset clap button aria label'
    },
    shareAriaLabel: {
        defaultMessage: 'Share',
        description: 'Post Reset share button aria label'
    },
    copied: {
        defaultMessage: 'Copied!',
        description: 'Post Reset copy reset url to clipboard success message'
    },
    likeAriaLabel: {
        defaultMessage: 'Like',
        description: 'Post Reset like button aria label'
    },
    dislikeAriaLabel: {
        defaultMessage: 'Dislike',
        description: 'Post Reset dislike button aria label'
    },
    likeButtonTooltip: {
        defaultMessage: 'Show me more like this',
        description: 'Post Reset like button tooltip'
    },
    dislikeButtonTooltip: {
        defaultMessage: 'Show me less like this',
        description: 'Post Reset dislike button tooltip'
    },
    favoriteButtonTooltip: {
        defaultMessage: 'Favorite',
        description: 'Post Reset favorite button tooltip'
    },
    copyButtonTooltip: {
        defaultMessage: 'Copy link',
        description: 'Post Reset copy button tooltip'
    }
})

function PostThriveResetActions(
    props: PostThriveResetActionsProps
): JSX.Element {
    const {
        thriveReset,
        onReplay,
        onClapped,
        onFavored,
        onShared,
        onDisliked,
        onLiked
    } = props

    const isResetFeedbackEnabled = useIsFeatureEnabled(
        ResetFeatureFlags.IsResetFeedbackEnabled,
        false,
        true
    )
    const isMsTeamsPostResetSharingEnabled = useIsFeatureEnabled(
        ResetFeatureFlags.IsMsTeamsPostResetSharingEnabled,
        false,
        true
    )

    const intl = useIntl()

    const contentContainerRef = useRef<HTMLDivElement>()

    const {
        createResetLike,
        deleteResetLike,
        isLoading: isLikeLoading
    } = useThriveResetVideoLike()

    const {
        createFavoriteReset,
        deleteFavoriteReset,
        isLoading: isFavoriteLoading
    } = useThriveFavoriteReset()

    const { updateFeedback, isLoading: isFeedbackLoading } = useResetFeedback()

    const isUpdating = isLikeLoading || isFavoriteLoading || isFeedbackLoading

    const isClapped = thriveReset?.likes.isLiked ?? false
    const isFavorite = thriveReset?.bookmarked ?? false
    const isLiked = thriveReset?.latestFeedback === Feedback.Like || false
    const isDisliked = thriveReset?.latestFeedback === Feedback.Dislike || false

    const handleClapClick = useCallback(() => {
        if (isUpdating || !thriveReset) {
            return
        }

        const isChecked = !thriveReset.likes.isLiked
        if (isChecked) {
            createResetLike(thriveReset)
        } else {
            deleteResetLike(thriveReset)
        }

        onClapped?.(isChecked)
    }, [createResetLike, deleteResetLike, isUpdating, thriveReset, onClapped])

    const handleFavoriteClick = useCallback(() => {
        if (isUpdating || !thriveReset) {
            return
        }

        const isChecked = !thriveReset.bookmarked
        if (isChecked) {
            createFavoriteReset(thriveReset)
        } else {
            deleteFavoriteReset(thriveReset)
        }

        onFavored?.(isChecked)
    }, [
        createFavoriteReset,
        deleteFavoriteReset,
        isUpdating,
        thriveReset,
        onFavored
    ])

    const handleLikeClick = useCallback(async () => {
        if (thriveReset.latestFeedback !== Feedback.Like && !isUpdating) {
            await updateFeedback(Feedback.Like, thriveReset)
            onLiked?.()
        }
    }, [updateFeedback, isUpdating, thriveReset, onLiked])

    const handleDislikeClick = useCallback(async () => {
        if (thriveReset.latestFeedback !== Feedback.Dislike && !isUpdating) {
            await updateFeedback(Feedback.Dislike, thriveReset)
            onDisliked?.()
        }
    }, [updateFeedback, isUpdating, thriveReset, onDisliked])

    return (
        <Stack
            direction="row"
            spacing={{ xs: 1, md: 2 }}
            ref={contentContainerRef}
            data-testid="PostThriveResetActions"
        >
            {!isResetFeedbackEnabled && (
                <>
                    <PostResetActionButton
                        onClick={onReplay}
                        aria-label={intl.formatMessage(
                            messages.replayAriaLabel
                        )}
                        data-testid="ReplayButton"
                        id={`postResetReplayButton_${thriveReset?.id}`}
                    >
                        <LeafIcon icon={'arrow-rotate-left'} />
                    </PostResetActionButton>
                    <PostResetActionButton
                        isPressed={isClapped}
                        isUpdating={isUpdating}
                        onClick={handleClapClick}
                        data-testid="ClapButton"
                        aria-label={intl.formatMessage(messages.clapAriaLabel)}
                        id={`postResetClapButton_${thriveReset?.id}`}
                    >
                        <LeafIcon
                            icon={'hands-clapping'}
                            iconStyle={isClapped ? 'solid' : 'regular'}
                        />
                    </PostResetActionButton>
                </>
            )}

            {isResetFeedbackEnabled && (
                <>
                    <PostResetActionButton
                        isPressed={isLiked}
                        isUpdating={isFeedbackLoading}
                        onClick={handleLikeClick}
                        data-testid="LikeButton"
                        aria-label={intl.formatMessage(messages.likeAriaLabel)}
                        tooltip={intl.formatMessage(messages.likeButtonTooltip)}
                        id={`postResetLikeButton_${thriveReset?.id}`}
                    >
                        <LeafIcon
                            icon={'thumbs-up'}
                            iconStyle={isLiked ? 'solid' : 'regular'}
                        />
                    </PostResetActionButton>
                    <PostResetActionButton
                        isPressed={isDisliked}
                        isUpdating={isFeedbackLoading}
                        onClick={handleDislikeClick}
                        data-testid="DislikeButton"
                        aria-label={intl.formatMessage(
                            messages.dislikeAriaLabel
                        )}
                        tooltip={intl.formatMessage(
                            messages.dislikeButtonTooltip
                        )}
                        id={`postResetDislikeButton_${thriveReset?.id}`}
                    >
                        <LeafIcon
                            icon={'thumbs-down'}
                            iconStyle={isDisliked ? 'solid' : 'regular'}
                        />
                    </PostResetActionButton>
                </>
            )}

            <PostResetActionButton
                isPressed={isFavorite}
                isUpdating={isUpdating}
                onClick={handleFavoriteClick}
                data-testid="FavoriteButton"
                aria-label={intl.formatMessage(messages.favoriteAriaLabel)}
                tooltip={intl.formatMessage(messages.favoriteButtonTooltip)}
                id={`postResetFavoriteButton_${thriveReset?.id}`}
            >
                {isFavorite ? (
                    <LeafIcon
                        icon={'heart'}
                        iconStyle="solid"
                        data-testid="Favorite"
                    />
                ) : (
                    <LeafIcon icon={'heart'} data-testid="Favorite" />
                )}
            </PostResetActionButton>
            <ShareResetButton
                resetId={thriveReset?.id}
                resetName={thriveReset?.name}
                resetType={ResetTypes.ThriveVideo}
                ButtonComponent={PostResetActionButton}
                contentContainerRef={contentContainerRef}
                id={`postResetShareButton_${thriveReset?.id}`}
                onShared={onShared}
                data-testid="ShareButton"
                tooltip={intl.formatMessage(messages.copyButtonTooltip)}
                aria-label={intl.formatMessage(messages.shareAriaLabel)}
                isMsTeamsSharingDisabled={!isMsTeamsPostResetSharingEnabled}
            >
                <LeafIcon icon={'link'} />
            </ShareResetButton>
        </Stack>
    )
}

export default React.memo(PostThriveResetActions)
