import React from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { useTheme } from '@mui/material'
import InformationPopup from '../InformationPopup'

const messages = defineMessages({
    notFound: {
        defaultMessage: 'This Reset is not available:',
        description: 'Personalized reset not found error message'
    }
})

export type PersonalizedResetNotFoundPopupProps = {
    onClose: () => void
}

function PersonalizedResetNotFoundPopup(
    props: PersonalizedResetNotFoundPopupProps
) {
    const { onClose } = props

    const intl = useIntl()
    const theme = useTheme()

    return (
        <InformationPopup
            open={true}
            title={intl.formatMessage(messages.notFound)}
            onClose={onClose}
            dialogSx={{ zIndex: theme.zIndex.modal + 1 }}
        >
            <ul>
                <li>
                    <FormattedMessage
                        defaultMessage="It may have been deleted"
                        description="Personalized reset deleted error message"
                    />
                </li>
                <li>
                    <FormattedMessage
                        defaultMessage="It may not be available in your company"
                        description="Personalized reset not available error message"
                    />
                </li>
            </ul>
        </InformationPopup>
    )
}

export default React.memo(PersonalizedResetNotFoundPopup)
