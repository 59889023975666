import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { getOrientationTypeBasedOnScreenSize } from './utils'

type UseScreenOrientationResult = {
    orientation: OrientationType
    isPortrait: boolean
    isLandscape: boolean
}

function useScreenOrientation(): UseScreenOrientationResult {
    const [orientation, setOrientation] = useState<OrientationType>(() => {
        if (screen.orientation) {
            return screen.orientation.type
        } else {
            return getOrientationTypeBasedOnScreenSize()
        }
    })

    const handleOrientationChange = useCallback(() => {
        setOrientation(screen.orientation.type)
    }, [])

    const handleWindowResize = useCallback(() => {
        const orientationType = getOrientationTypeBasedOnScreenSize()

        setOrientation(orientationType)
    }, [])

    useLayoutEffect(() => {
        if (screen.orientation) {
            screen.orientation.addEventListener(
                'change',
                handleOrientationChange
            )
        } else {
            window.addEventListener('resize', handleWindowResize)
        }

        return () => {
            if (screen.orientation) {
                screen.orientation.removeEventListener(
                    'change',
                    handleOrientationChange
                )
            } else {
                window.removeEventListener('resize', handleWindowResize)
            }
        }
    }, [handleOrientationChange, handleWindowResize])

    return useMemo(() => {
        return {
            orientation,
            isLandscape: orientation.includes('landscape'),
            isPortrait: orientation.includes('portrait')
        }
    }, [orientation])
}

export default useScreenOrientation
