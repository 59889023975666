export function without<T, V = T>(
    source: T[],
    others: V[],
    comparePredicate: (first: T, second: V) => boolean
): T[] {
    const result: T[] = []
    for (const sourceItem of source) {
        let isInSource = false

        for (const otherItem of others) {
            const isEquals = comparePredicate(sourceItem, otherItem)

            if (isEquals) {
                isInSource = true
                break
            }
        }

        if (!isInSource) {
            result.push(sourceItem)
        }
    }

    return result
}
