import { Box } from '@mui/material'
import React, { useCallback } from 'react'
import { AspectRatio } from 'react-aspect-ratio'
import 'react-aspect-ratio/aspect-ratio.css'

const aspectRatio = 16 / 9

export type ThriveSolidBackgroundCardProps = {
    fullScreen?: boolean
    dominantColor?: string
    borderStyle?: string
    borderWidth?: string
    idx?: string
    children?: React.ReactElement | React.ReactElement[]
}

function ThriveSolidBackgroundCard(props: ThriveSolidBackgroundCardProps) {
    const aspectRatioWrapper = useCallback(
        (children): React.ReactElement => {
            return props.fullScreen ? (
                <>{children}</>
            ) : (
                <AspectRatio ratio={aspectRatio}>{children}</AspectRatio>
            )
        },
        [props.fullScreen]
    )

    return aspectRatioWrapper(
        <Box
            display="flex"
            flexWrap="wrap"
            flexDirection="column"
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            position="relative"
            data-testid="ThriveSolidBackgroundCard"
            sx={[
                {
                    borderRadius: '10px',
                    background: props.dominantColor || 'rgba(70, 71, 117, 0.1)',
                    borderStyle: props.borderStyle || 'none',
                    borderWidth: props.borderWidth || 'inherit',
                    borderColor: props.idx ? 'inherit' : 'rgba(70, 71, 117, 1)'
                },
                props.fullScreen && {
                    borderRadius: '0px',
                    height: '100%',
                    width: '100%'
                }
            ]}
        >
            {props.children}
        </Box>
    )
}

export default React.memo(ThriveSolidBackgroundCard)
