import { Avo, AvoTypes } from '@thriveglobal/thrive-web-tracking'
import React, { ComponentType, useCallback } from 'react'
import { PostThriveResetActionsProps } from '../../components/features/ThriveReset/PostThriveReset/PostThriveResetActions'
import { useAppSelector } from '@thriveglobal/thrive-web-core'

export type PostThriveResetActionsTrackableProps =
    PostThriveResetActionsProps & {
        utmSource?: string
        resetType?: AvoTypes.ResetTypeValueType
    }

function withPostThriveResetActionsTracking(
    PostThriveResetActions: ComponentType<PostThriveResetActionsTrackableProps>
) {
    return React.memo(function PostThriveResetActionsTrackable(
        props: PostThriveResetActionsTrackableProps
    ) {
        const {
            utmSource,
            resetType,
            onClapped,
            onFavored,
            onShared,
            onLiked,
            onDisliked,
            ...restProps
        } = props
        const { thriveReset } = props
        const user = useAppSelector((state) => state.user)

        const trackOnClapped = useCallback(
            (isChecked) => {
                if (isChecked) {
                    Avo.reactionSubmitted({
                        featureType: 'content',
                        activityType: 'clap_submitted',
                        contentType: 'reset',
                        contentFormatType: 'video',
                        contentId: thriveReset?.id,
                        contentTitle: thriveReset?.name,
                        contentUrl:
                            thriveReset?.landscape?.iframe ??
                            thriveReset?.portrait?.iframe,
                        resetType: resetType ?? 'reset',
                        contentSource: 'thrive',
                        feedbackAnswer: 'positive',
                        contentSubtype: null,
                        conversationId: null,
                        feedbackQuestion: null,
                        messageType: null,
                        openAnswer: null,
                        openQuestion: null,
                        tabName: null,
                        transactionId: null,
                        isSystemEvent: false
                    })
                }
            },
            [
                thriveReset?.id,
                thriveReset?.landscape?.iframe,
                thriveReset?.name,
                thriveReset?.portrait?.iframe,
                resetType
            ]
        )

        const onClappedTrackable: PostThriveResetActionsProps['onClapped'] =
            useCallback(
                (...args) => {
                    trackOnClapped(...args)

                    return onClapped?.call(null, ...args)
                },
                [onClapped, trackOnClapped]
            )

        const trackOnFavored = useCallback(
            (isChecked) => {
                if (isChecked) {
                    Avo.reactionSubmitted({
                        featureType: 'content',
                        activityType: 'favorite_submitted',
                        contentType: 'reset',
                        contentFormatType: 'video',
                        contentId: thriveReset?.id,
                        contentTitle: thriveReset?.name,
                        contentUrl:
                            thriveReset?.landscape?.iframe ??
                            thriveReset?.portrait?.iframe,
                        resetType: resetType ?? 'reset',
                        contentSource: 'thrive',
                        feedbackAnswer: 'positive',
                        contentSubtype: null,
                        conversationId: null,
                        feedbackQuestion: null,
                        messageType: null,
                        openAnswer: null,
                        openQuestion: null,
                        tabName: null,
                        transactionId: null,
                        isSystemEvent: false
                    })
                }
            },
            [
                thriveReset?.id,
                thriveReset?.landscape?.iframe,
                thriveReset?.name,
                thriveReset?.portrait?.iframe,
                resetType
            ]
        )

        const onFavoredTrackable: PostThriveResetActionsProps['onFavored'] =
            useCallback(
                (...args) => {
                    trackOnFavored(...args)

                    return onFavored?.call(null, ...args)
                },
                [onFavored, trackOnFavored]
            )

        const trackOnShared = useCallback(
            () =>
                Avo.contentShared({
                    featureType: 'content',
                    activityType: 'reset_shared',
                    contentId: thriveReset?.id,
                    contentType: 'reset',
                    contentTitle: thriveReset?.name,
                    contentFormatType: 'video',
                    contentSource: 'thrive',
                    contentUrl:
                        thriveReset?.landscape?.iframe ??
                        thriveReset?.portrait?.iframe,
                    resetType: resetType ?? 'reset',
                    contentSubtype: null,
                    pageSection: null,
                    isSystemEvent: false
                }),
            [
                resetType,
                thriveReset?.id,
                thriveReset?.landscape?.iframe,
                thriveReset?.name,
                thriveReset?.portrait?.iframe
            ]
        )

        const onSharedTrackable: PostThriveResetActionsProps['onShared'] =
            useCallback(
                (...args) => {
                    trackOnShared(...args)

                    return onShared?.call(null, ...args)
                },
                [onShared, trackOnShared]
            )

        const trackOnLiked = useCallback(() => {
            Avo.reactionSubmitted({
                featureType: 'content',
                activityType: 'reset_like_submitted',
                contentType: 'reset',
                contentFormatType: 'video',
                contentId: thriveReset?.id,
                contentTitle: thriveReset?.name,
                contentUrl:
                    thriveReset?.landscape?.iframe ??
                    thriveReset?.portrait?.iframe,
                resetType: resetType ?? 'reset',
                contentSource: 'thrive',
                feedbackAnswer: 'positive',
                contentSubtype: null,
                conversationId: null,
                feedbackQuestion: null,
                messageType: null,
                openAnswer: null,
                openQuestion: null,
                tabName: null,
                transactionId: null,
                isSystemEvent: false
            })
        }, [
            thriveReset?.id,
            thriveReset?.landscape?.iframe,
            thriveReset?.name,
            thriveReset?.portrait?.iframe,
            resetType
        ])

        const onLikedTrackable: PostThriveResetActionsProps['onLiked'] =
            useCallback(
                (...args) => {
                    trackOnLiked(...args)

                    return onLiked?.call(null, ...args)
                },
                [onLiked, trackOnLiked]
            )

        const trackOnDisliked = useCallback(() => {
            Avo.reactionSubmitted({
                featureType: 'content',
                activityType: 'reset_dislike_submitted',
                contentType: 'reset',
                contentFormatType: 'video',
                contentId: thriveReset?.id,
                contentTitle: thriveReset?.name,
                contentUrl:
                    thriveReset?.landscape?.iframe ??
                    thriveReset?.portrait?.iframe,
                resetType: resetType ?? 'reset',
                contentSource: 'thrive',
                feedbackAnswer: 'negative',
                contentSubtype: null,
                conversationId: null,
                feedbackQuestion: null,
                messageType: null,
                openAnswer: null,
                openQuestion: null,
                tabName: null,
                transactionId: null,
                isSystemEvent: false
            })
        }, [
            thriveReset?.id,
            thriveReset?.landscape?.iframe,
            thriveReset?.name,
            thriveReset?.portrait?.iframe,
            resetType
        ])

        const onDislikedTrackable: PostThriveResetActionsProps['onDisliked'] =
            useCallback(
                (...args) => {
                    trackOnDisliked(...args)

                    return onDisliked?.call(null, ...args)
                },
                [onDisliked, trackOnDisliked]
            )

        return (
            <PostThriveResetActions
                {...restProps}
                onClapped={onClappedTrackable}
                onFavored={onFavoredTrackable}
                onShared={onSharedTrackable}
                onLiked={onLikedTrackable}
                onDisliked={onDislikedTrackable}
            />
        )
    })
}

export default withPostThriveResetActionsTracking
