import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'

export const stringifySearchParams = (
    params: Record<string, string | null>,
    prefix = '?'
): string => {
    if (isEmpty(params) || every(params, isEmpty)) {
        return ''
    }

    return prefix + new URLSearchParams(params).toString()
}

export default stringifySearchParams
