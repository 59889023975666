import React from 'react'
import { alpha, Box, useTheme } from '@mui/material'
import { PlayIcon, PauseIcon } from '../../icons/CFPlayer'

export type PlayStateProps = {
    paused: boolean
}

function PlayState(props: PlayStateProps) {
    const { paused } = props

    const theme = useTheme()

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
                borderRadius: '50%',
                padding: theme.spacing(4.125),
                color: theme.palette.primary.contrastText,
                backgroundColor: alpha('#000000', 0.5),
                width: '15.4rem',
                height: '15.4rem'
            }}
            data-testid="PlayState"
        >
            {paused ? (
                <PauseIcon sx={{ height: '100%', width: '100%' }} />
            ) : (
                <PlayIcon sx={{ height: '100%', width: '100%' }} />
            )}
        </Box>
    )
}

export default React.memo(PlayState)
