import React, { useCallback, useMemo } from 'react'
import {
    Box,
    Slider,
    Stack,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material'
import { defineMessages, useIntl } from 'react-intl'
import { formatPlaybackTime } from '../../../../../utils/time'
import {
    defaultCurrentTime,
    defaultDuration,
    timeLineStep,
    timeLineMinValue
} from './constants'

const messages = defineMessages({
    timelineSliderAriaLabel: {
        defaultMessage: 'Player timeline',
        description: 'Personalized reset player timeline aria label'
    }
})

export type TimeLineControlProps = {
    duration: number
    currentTime: number
    onCurrentTimeChange?: (time: number) => void
    onCurrentTimeChangeCommitted?: (time: number) => void
}

function TimeLineControl(props: TimeLineControlProps) {
    const {
        duration = defaultDuration,
        currentTime = defaultCurrentTime,
        onCurrentTimeChange,
        onCurrentTimeChangeCommitted
    } = props

    const theme = useTheme()
    const intl = useIntl()

    const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

    const playerTime = useMemo(() => {
        const formattedCurrentTime = formatPlaybackTime(intl, currentTime)
        const formattedDuration = formatPlaybackTime(intl, duration)

        return `${formattedCurrentTime} / ${formattedDuration}`
    }, [duration, currentTime, intl])

    const formatValueLabel = useCallback(
        (value) => {
            return formatPlaybackTime(intl, value)
        },
        [intl]
    )

    const handleCurrentTimeChange = useCallback(
        (event, value: number) => {
            onCurrentTimeChange?.(value)
        },
        [onCurrentTimeChange]
    )

    const handleCurrentTimeChangeCommitted = useCallback(
        (event, value) => {
            onCurrentTimeChangeCommitted?.(value)
        },
        [onCurrentTimeChangeCommitted]
    )

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                width: '100%'
            }}
            data-testid="TimeLineControl"
        >
            <Slider
                value={currentTime}
                onChange={handleCurrentTimeChange}
                onChangeCommitted={handleCurrentTimeChangeCommitted}
                color="primary"
                min={timeLineMinValue}
                max={duration}
                step={timeLineStep}
                valueLabelDisplay="auto"
                valueLabelFormat={formatValueLabel}
                aria-label={intl.formatMessage(
                    messages.timelineSliderAriaLabel
                )}
                sx={[
                    {
                        flex: '1 1 auto',
                        height: '0.85rem',
                        minHeight: '0.85rem',
                        color: theme.palette.primary.contrastText,
                        borderRadius: 0,
                        cursor: 'pointer',
                        boxSizing: 'border-box',
                        '& .MuiSlider-track': {
                            border: 0
                        }
                    },
                    isMobileView && { height: '0.65rem', minHeight: '0.65rem' }
                ]}
            />
            <Box
                data-testid="PlaybackTime"
                sx={{ pl: theme.spacing(2.75), flex: '0 0 auto' }}
                role="timer"
            >
                <Typography
                    variant="body1"
                    component="span"
                    sx={{
                        color: theme.palette.primary.contrastText,
                        fontVariantNumeric: 'tabular-nums'
                    }}
                >
                    {playerTime}
                </Typography>
            </Box>
        </Stack>
    )
}

export default React.memo(TimeLineControl)
