import {
    selectThriveResetsByIds,
    upsertManyThriveResets
} from '../../slices/thriveResets'
import {
    ThriveReset,
    useGetFavoriteResetsLazyQuery
} from '../../graphql/generated/autogenerated'
import {
    createUseLazyListHook,
    createUseLazyListReduxStateHook
} from '../../hooks/useLazyList'
import { initialLimit } from '../../components/features/ThriveResets/constants'

export const useFavoriteThriveResetsState =
    createUseLazyListReduxStateHook<ThriveReset>({
        mapItemToKey: (item) => item.id,
        selectItems: selectThriveResetsByIds,
        upsertManyItems: upsertManyThriveResets
    })

export const useFavoriteResetsLazyListData = createUseLazyListHook({
    useGetItemsLazyQuery: useGetFavoriteResetsLazyQuery,
    useLazyListState: useFavoriteThriveResetsState,
    mapQueryResult: (data) => {
        return {
            items: (
                (data?.resetV2?.video?.bookmark?.bookmarked?.items ??
                    []) as ThriveReset[]
            )
                .filter((item) => item.__typename === 'ThriveReset')
                .map((item): ThriveReset => {
                    const { thriveResetName, ...rest } =
                        // NOTE: Temporary workaround as we need to use aliases for name due to GraphQL types
                        item as unknown as Omit<ThriveReset, 'name'> & {
                            thriveResetName: string
                        }

                    return { ...rest, name: thriveResetName }
                }),
            total: data?.resetV2?.video?.bookmark?.bookmarked?.total ?? 0
        }
    },
    mapQueryVariables: (page, limit) => ({ page, limit }),
    paginationLimit: initialLimit
})
