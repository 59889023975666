import thriveResets, { thriveResetsSliceName } from './thriveResets'
import personalizedResets, {
    personalizedResetsSliceName
} from './personalizedResets'
import thriveAudioResets, {
    thriveAudioResetsSliceName
} from './thriveAudioResets'

export const reducers = {
    [thriveResetsSliceName]: thriveResets,
    [personalizedResetsSliceName]: personalizedResets,
    [thriveAudioResetsSliceName]: thriveAudioResets
}

export default reducers
