import {
    CombinedState,
    createSelector,
    createSlice,
    Draft,
    isDraft,
    original
} from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'
import { PersonalizedReset } from '../graphql/generated/autogenerated'

export const personalizedResetsSliceName = 'thriveWebReset_personalizedResets'

export type PersonalizedResetsState = {
    byId: { [id: string]: PersonalizedReset }
}

const initialState: PersonalizedResetsState = {
    byId: {}
}

export type RootStateWithPersonalizedResetsState = CombinedState<{
    [personalizedResetsSliceName]: PersonalizedResetsState
}>

const upsertOneReducer = (
    draft: Draft<PersonalizedResetsState>,
    reset: PersonalizedReset
): PersonalizedResetsState => {
    const { byId } = draft

    const oldReset = byId[reset.id]

    // If reset has not changed, do nothing
    if (
        oldReset && isDraft(oldReset)
            ? isEqual(original(oldReset), reset)
            : isEqual(oldReset, reset)
    ) {
        return draft
    }

    byId[reset.id] = reset

    return draft
}

const removeOneReducer = (
    draft: Draft<PersonalizedResetsState>,
    reset: PersonalizedReset
): PersonalizedResetsState => {
    const { byId } = draft

    const oldReset = byId[reset.id]

    // If reset is not in the store, do nothing
    if (!oldReset) {
        return draft
    }

    delete byId[reset.id]

    return draft
}

export const personalizedResetsSlice = createSlice({
    name: personalizedResetsSliceName,
    initialState,
    reducers: {
        upsertManyPersonalizedResets: (
            state,
            { payload }: { payload: PersonalizedReset[] }
        ) => payload.reduce(upsertOneReducer, state),
        removeManyPersonalizedResets: (
            state,
            { payload }: { payload: PersonalizedReset[] }
        ) => payload.reduce(removeOneReducer, state),
        upsertOnePersonalizedReset: (
            state,
            { payload }: { payload: PersonalizedReset }
        ) => upsertOneReducer(state, payload)
    }
})

export const {
    upsertManyPersonalizedResets,
    upsertOnePersonalizedReset,
    removeManyPersonalizedResets
} = personalizedResetsSlice.actions

export type PersonalizedResetsSliceActions =
    (typeof personalizedResetsSlice.actions)[keyof typeof personalizedResetsSlice.actions]

export const selectPersonalizedResetsState = (
    rootState: RootStateWithPersonalizedResetsState
) => rootState[personalizedResetsSliceName]

// TODO: Temporary workaround, remove hardcoded return types once pnpm + typescript issue is resolved.

export const selectPersonalizedResetById = createSelector(
    (state) => selectPersonalizedResetsState(state),
    (_, id: string) => id,
    (state, id) => state.byId[id]
) as (
    state: RootStateWithPersonalizedResetsState,
    id: string
) => PersonalizedReset

export const selectPersonalizedResetsByIds = createSelector(
    (state) => selectPersonalizedResetsState(state),
    (_, ids: string[]) => ids,
    (state, ids) => ids.map((id) => state.byId[id]).filter(Boolean)
) as (
    state: RootStateWithPersonalizedResetsState,
    ids: string[]
) => PersonalizedReset[]

export default personalizedResetsSlice.reducer
