import { alpha, Card, Stack, useMediaQuery, useTheme } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { ThriveResetAudio } from '../../../../graphql/generated/autogenerated'
import { useGetNextRandomThriveAudioReset } from '../../../../hooks/useGetNextReset'
import withPostThriveAudioResetActionsTracking from '../../../../tracking/withPostThriveAudioResetActionsTracking'
import { PostResetNextResetPreview } from '../../../elements/PostResetScreen'
import PostThriveAudioResetActions from './PostThriveAudioResetActions'

const PostThriveAudioResetActionsTrackable =
    withPostThriveAudioResetActionsTracking(PostThriveAudioResetActions)

export type PostThriveAudioResetProps = {
    isOpen: boolean
    reset: ThriveResetAudio
    utmSource?: string
    onNext?: (nextReset: ThriveResetAudio, isAutoPlay?: boolean) => void
    onReplay?: () => void
    onCancel?: () => void
    onAutoPlayToggle?: (
        thriveReset: ThriveResetAudio,
        isAutoplay: boolean
    ) => void
}

function PostThriveAudioReset(props: PostThriveAudioResetProps): JSX.Element {
    const { isOpen, reset, utmSource, onNext, onReplay, onAutoPlayToggle } =
        props

    const theme = useTheme()

    const isMobileView = useMediaQuery(theme.breakpoints.down('lg'))
    const isMobileLandscape = useMediaQuery('(max-height:500px)')

    const [nextReset] = useGetNextRandomThriveAudioReset(reset)

    const nextResetThumbnail = nextReset?.images?.sm

    const handleNext = useCallback(
        (isAutoPlay?: boolean) => onNext?.(nextReset, isAutoPlay),
        [nextReset, onNext]
    )

    const handleAutoPlayToggle = useCallback(
        (isAutoplay: boolean) => {
            onAutoPlayToggle?.(reset, isAutoplay)
        },
        [reset, onAutoPlayToggle]
    )

    return (
        <Stack
            alignItems="center"
            justifyContent={'center'}
            height={'100%'}
            width={'100%'}
            data-testid="PostThriveAudioReset"
            gap={2}
            padding={2}
        >
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    alignItems: 'center',
                    alignSelf: 'center',
                    backgroundColor: alpha(theme.palette.common.black, 0.5),
                    py: theme.spacing(2),
                    px: theme.spacing(3)
                }}
            >
                <CoreTypography variant="h4" color="white">
                    <FormattedMessage
                        defaultMessage="Did you enjoy this Reset?"
                        description="post reset message"
                    />
                </CoreTypography>

                <PostThriveAudioResetActionsTrackable
                    reset={reset}
                    utmSource={utmSource}
                    onReplay={onReplay}
                />
            </Card>

            <Stack
                direction={isMobileView ? 'column' : 'row'}
                justifyContent={'center'}
                gap={2}
                alignItems={'center'}
                sx={
                    !isMobileLandscape &&
                    !isMobileView && {
                        position: 'absolute',
                        bottom: 20,
                        right: theme.spacing(12)
                    }
                }
            >
                <PostResetNextResetPreview
                    name={nextReset?.name}
                    isOpen={isOpen}
                    description={nextReset?.description}
                    thumbnail={nextResetThumbnail}
                    onNext={handleNext}
                    onAutoPlayToggle={handleAutoPlayToggle}
                />
            </Stack>
        </Stack>
    )
}

export default React.memo(PostThriveAudioReset)
