import { useEffect, useMemo, useState } from 'react'
import { ApolloError } from '@apollo/client'
import merge from 'lodash/merge'
import {
    CreateUseGetNextResetHookOptions,
    UseGetNextRandomReset
} from './types'
import { maxGetRandomResetAttempts } from './constants'

export function createUseGetNextRandomResetHook<
    TItem extends { id: string },
    TQuery,
    TVariables
>({
    useGetNextRandomResetLazyQuery,
    mapQueryResult,
    staticQueryVariables
}: CreateUseGetNextResetHookOptions<
    TItem,
    TQuery,
    TVariables
>): UseGetNextRandomReset<TItem, TVariables> {
    return function useGetNextRandomReset(reset, options) {
        const { maxAttempts = maxGetRandomResetAttempts, variables } =
            options ?? {}

        const [nextReset, setNextReset] = useState<TItem>(null)
        const [isLoading, setIsLoading] = useState(false)
        const [error, setError] = useState<ApolloError>(null)

        const [_, { fetchMore: getRandomReset }] =
            useGetNextRandomResetLazyQuery({
                fetchPolicy: 'standby' // NOTE: To prevent auto firing first request without variables from GQL
            })

        useEffect(() => {
            if (!reset?.id) {
                setNextReset(null)
                setIsLoading(false)
                setError(null)
                return () => {
                    /* noop */
                }
            }

            let attempts = 0
            let isCancelled = false

            const load = async () => {
                setIsLoading(true)
                setError(null)

                let randomReset: TItem = null
                try {
                    const getRandomResetVariables = merge(
                        staticQueryVariables,
                        variables
                    )

                    const { data } = await getRandomReset({
                        variables: getRandomResetVariables
                    })

                    if (isCancelled) {
                        return
                    }

                    randomReset = mapQueryResult(data)
                } catch (e) {
                    setError(e)
                } finally {
                    setIsLoading(false)
                }

                if (!randomReset) {
                    setNextReset(null)
                    return
                }

                if (randomReset.id === reset.id && attempts < maxAttempts - 1) {
                    attempts++
                    load()
                    return
                }

                setNextReset(randomReset)
            }

            load()

            return () => {
                isCancelled = true
            }
        }, [getRandomReset, reset?.id, maxAttempts, variables])

        return useMemo(
            () => [nextReset, { loading: isLoading, error }],
            [nextReset, isLoading, error]
        )
    }
}
