import { useCallback, useState } from 'react'
import {
    useCreateLikeThriveAudioResetMutation,
    ThriveResetAudio,
    useDeleteLikeThriveAudioResetMutation,
    GetThriveAudioResetsQuery
} from '../../graphql/generated/autogenerated'
import { useAppDispatch } from '../../slices'
import { upsertOneThriveAudioReset } from '../../slices/thriveAudioResets'
import { useApolloClientCacheEvict } from '../../hooks/useApolloClientCacheEvict'

export type UseThriveAudioResetLikeResult = {
    isLoading: boolean
    createResetLike: (reset: ThriveResetAudio) => Promise<void>
    deleteResetLike: (reset: ThriveResetAudio) => Promise<void>
}

function useThriveAudioResetLike(): UseThriveAudioResetLikeResult {
    const [isLoading, setIsLoading] = useState(false)
    const [createLikeReset] = useCreateLikeThriveAudioResetMutation()
    const [deleteLikeReset] = useDeleteLikeThriveAudioResetMutation()
    const dispatch = useAppDispatch()
    const { evictFieldFromCache } = useApolloClientCacheEvict()

    const handleCreateResetLike = useCallback(
        async (reset: ThriveResetAudio) => {
            if (isLoading) return

            setIsLoading(true)

            try {
                const likesCount = reset.likes.numberOfLikes + 1
                const totalLikesCount = reset.likes.totalNumberOfLikes + 1
                const updatedReset: ThriveResetAudio = {
                    ...reset,
                    likes: {
                        isLiked: true,
                        numberOfLikes: likesCount,
                        totalNumberOfLikes: totalLikesCount
                    }
                }
                dispatch(upsertOneThriveAudioReset(updatedReset))

                await createLikeReset({
                    variables: {
                        thriveResetAudioId: reset.id
                    }
                })

                evictFieldFromCache<GetThriveAudioResetsQuery>(
                    'reset',
                    'thriveAudio'
                )
            } finally {
                setIsLoading(false)
            }
        },
        [createLikeReset, evictFieldFromCache, dispatch, isLoading]
    )

    const handleDeleteResetLike = useCallback(
        async (reset: ThriveResetAudio) => {
            if (isLoading) return

            setIsLoading(true)
            try {
                const likesCount = Math.max(reset.likes.numberOfLikes - 1, 0)
                const totalLikesCount = Math.max(
                    reset.likes.totalNumberOfLikes - 1,
                    0
                )
                const updatedReset: ThriveResetAudio = {
                    ...reset,
                    likes: {
                        isLiked: false,
                        numberOfLikes: likesCount,
                        totalNumberOfLikes: totalLikesCount
                    }
                }
                dispatch(upsertOneThriveAudioReset(updatedReset))

                await deleteLikeReset({
                    variables: {
                        thriveResetAudioId: reset.id
                    }
                })

                evictFieldFromCache<GetThriveAudioResetsQuery>(
                    'reset',
                    'thriveAudio'
                )
            } finally {
                setIsLoading(false)
            }
        },
        [evictFieldFromCache, dispatch, deleteLikeReset, isLoading]
    )

    return {
        isLoading,
        createResetLike: handleCreateResetLike,
        deleteResetLike: handleDeleteResetLike
    }
}

export default useThriveAudioResetLike
