import React, {
    MutableRefObject,
    PropsWithChildren,
    SyntheticEvent,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef
} from 'react'
import { defaultListeningInterval as defaultListenInterval } from './constants'

export type AudioPlayerProps = PropsWithChildren<{
    volume?: number
    listenInterval?: number
    onListen?: (time: number) => void
}> &
    React.AudioHTMLAttributes<HTMLAudioElement>

function AudioPlayer(
    props: AudioPlayerProps,
    ref: MutableRefObject<HTMLAudioElement>
): JSX.Element {
    const {
        children,
        volume,
        listenInterval = defaultListenInterval,
        onListen,
        onPlay,
        onAbort,
        onEnded,
        onPause,
        ...restProps
    } = props

    const internalRef = useRef<HTMLAudioElement>(null)
    const audioElementRef = ref ?? internalRef

    const audioElement = useMemo(
        () => audioElementRef.current,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [audioElementRef.current]
    )

    const onListenRef = useRef(onListen)
    useEffect(() => {
        onListenRef.current = onListen
    }, [onListen])

    const listenIntervalRef = useRef(listenInterval)
    useEffect(() => {
        listenIntervalRef.current = listenInterval
    }, [listenInterval])

    const listenIntervalIdRef = useRef<number | null>(null)

    useLayoutEffect(() => {
        if (!audioElement || typeof volume !== 'number') return

        audioElement.volume = volume
    }, [volume, audioElement])

    useEffect(() => {
        // cleanup
        return () => {
            if (listenIntervalIdRef.current) {
                clearInterval(listenIntervalIdRef.current)
            }
        }
    }, [])

    const startListen = useCallback(() => {
        if (listenIntervalIdRef.current || !listenIntervalRef.current) return

        listenIntervalIdRef.current = setInterval(() => {
            if (!audioElementRef.current) return

            const time = audioElementRef.current.currentTime
            onListenRef.current?.(time)
        }, listenIntervalRef.current) as unknown as number
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const stopListen = useCallback(() => {
        if (listenIntervalIdRef.current) {
            clearInterval(listenIntervalIdRef.current)
            listenIntervalIdRef.current = null
        }
    }, [])

    useEffect(() => {
        // restart interval if interval value changes
        if (listenIntervalIdRef.current) {
            stopListen()

            if (listenInterval) {
                startListen()
            }
        }
    }, [listenInterval, startListen, stopListen])

    const handlePlay = useCallback(
        (e: SyntheticEvent<HTMLAudioElement, Event>) => {
            startListen()
            onPlay?.(e)
        },
        [startListen, onPlay]
    )

    const handleAbort = useCallback(
        (e: SyntheticEvent<HTMLAudioElement, Event>) => {
            stopListen()
            onAbort?.(e)
        },
        [stopListen, onAbort]
    )

    const handleEnded = useCallback(
        (e: SyntheticEvent<HTMLAudioElement, Event>) => {
            stopListen()
            onEnded?.(e)
        },
        [stopListen, onEnded]
    )

    const handlePause = useCallback(
        (e: SyntheticEvent<HTMLAudioElement, Event>) => {
            stopListen()
            onPause?.(e)
        },
        [stopListen, onPause]
    )

    return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio
            ref={audioElementRef}
            onPlay={handlePlay}
            onAbort={handleAbort}
            onEnded={handleEnded}
            onPause={handlePause}
            {...restProps}
        >
            {children}
        </audio>
    )
}

export default React.memo(React.forwardRef(AudioPlayer))
