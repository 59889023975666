import { lazy } from 'react'
import { withPageFallback } from '../../components/fallbacks/PageFallback'
export const EditPersonalizedReset = lazy(
    () => import('./EditPersonalizedReset')
)

const EditPersonalizedResetWithFallback = withPageFallback(
    EditPersonalizedReset,
    { pageName: 'EditPersonalizedResetPage' }
)

export default EditPersonalizedResetWithFallback
