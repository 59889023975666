import {
    CombinedState,
    createSelector,
    createSlice,
    Draft,
    isDraft,
    original
} from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'
import { ThriveResetAudio } from '../graphql/generated/autogenerated'

export const thriveAudioResetsSliceName = 'thriveWebReset_thriveAudioResets'

export type ThriveAudioResetsState = {
    byId: { [id: string]: ThriveResetAudio }
}

const initialState: ThriveAudioResetsState = {
    byId: {}
}

export type RootStateWithThriveAudioResetsState = CombinedState<{
    [thriveAudioResetsSliceName]: ThriveAudioResetsState
}>

const upsertOneReducer = (
    draft: Draft<ThriveAudioResetsState>,
    reset: ThriveResetAudio
): ThriveAudioResetsState => {
    const { byId } = draft

    const oldReset = byId[reset.id]

    // If reset has not changed, do nothing
    if (
        oldReset && isDraft(oldReset)
            ? isEqual(original(oldReset), reset)
            : isEqual(oldReset, reset)
    ) {
        return draft
    }

    byId[reset.id] = reset

    return draft
}

export const thriveAudioResetsSlice = createSlice({
    name: thriveAudioResetsSliceName,
    initialState,
    reducers: {
        upsertManyThriveAudioResets: (
            state,
            { payload }: { payload: ThriveResetAudio[] }
        ) => payload.reduce(upsertOneReducer, state),
        upsertOneThriveAudioReset: (
            state,
            { payload }: { payload: ThriveResetAudio }
        ) => upsertOneReducer(state, payload)
    }
})

export const { upsertManyThriveAudioResets, upsertOneThriveAudioReset } =
    thriveAudioResetsSlice.actions

export type ThriveAudioResetsSliceActions =
    (typeof thriveAudioResetsSlice.actions)[keyof typeof thriveAudioResetsSlice.actions]

export const selectThriveAudioResetsState = (
    rootState: RootStateWithThriveAudioResetsState
) => rootState[thriveAudioResetsSliceName]

// TODO: Temporary workaround, remove hardcoded return types once pnpm + typescript issue is resolved.

export const selectThriveAudioResetById = createSelector(
    (state) => selectThriveAudioResetsState(state),
    (_, id: string) => id,
    (state, id) => state.byId[id]
) as (
    state: RootStateWithThriveAudioResetsState,
    id: string
) => ThriveResetAudio

export const selectThriveAudioResetsByIds = createSelector(
    (state) => selectThriveAudioResetsState(state),
    (_, ids: string[]) => ids,
    (state, ids) => ids.map((id) => state.byId[id]).filter(Boolean)
) as (
    state: RootStateWithThriveAudioResetsState,
    ids: string[]
) => ThriveResetAudio[]

export default thriveAudioResetsSlice.reducer
