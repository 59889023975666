import { lazy } from 'react'
import { withPageFallback } from '../../components/fallbacks/PageFallback'

export const NewPersonalizedReset = lazy(() => import('./NewPersonalizedReset'))

const NewPersonalizedResetWithFallback = withPageFallback(
    NewPersonalizedReset,
    { pageName: 'NewPersonalizedResetPage' }
)

export default NewPersonalizedResetWithFallback
