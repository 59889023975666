import { InMemoryCacheConfig } from '@apollo/client'

export const cacheOptions: any = {
    typePolicies: {
        Query: {
            merge: true
        },
        ResetQuery: {
            merge: true
        },
        ThriveResetQuery: {
            merge: true
        },
        ThriveResetAudioQuery: {
            merge: true
        },
        PersonalizedResetQuery: {
            merge: true
        },
        FavoriteResetQuery: {
            merge: true
        },
        PersonalizedImageQuery: {
            merge: true
        },
        ThriveImageQuery: {
            merge: true
        },
        ImageQuery: {
            merge: true
        },
        ThriveResetTheme: {
            merge: true,
            keyFields: ['theme']
        },
        ThriveResetAudioTheme: {
            merge: true,
            keyFields: ['theme']
        }
    }
}
