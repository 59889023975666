import { Box, alpha, useMediaQuery } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { AnimationPlaybackControls, motion, useAnimate } from 'framer-motion'
import { memo, useEffect, useMemo, useRef } from 'react'
import useElementSize from '../../../../hooks/useElementSize'
import useScreenOrientation from '../../../../hooks/useScreenOrientation'
import useSyncStateWithRef from '../../../../hooks/useSyncStateWithRef'
import { breathAnimationZIndex } from '../constants'
import { getBreathAnimationTransactions } from './animations'
import {
    innerBubbleMaxHeight,
    innerBubbleMinHeight,
    innerBubbleMobileMinHeight,
    outerBubbleMaxHeight,
    outerBubbleMinHeight,
    outerBubbleMobileMinHeight
} from './constants'

export type ThriveBreathIndicatorProps = {
    paused?: boolean
    restartAnimationToken?: number
    isFullScreen?: boolean
}

function ThriveBreathIndicator(props: ThriveBreathIndicatorProps) {
    const { paused = false, restartAnimationToken, isFullScreen = true } = props

    const theme = useTheme()
    const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

    const containerRef = useRef<HTMLDivElement>(null)
    const { width, height } = useElementSize(containerRef)

    const outerAnimationControlsRef = useRef<AnimationPlaybackControls>(null)
    const [outerScope, outerAnimate] = useAnimate()

    const innerAnimationControlsRef = useRef<AnimationPlaybackControls>(null)
    const [innerScope, innerAnimate] = useAnimate()

    const { orientation } = useScreenOrientation()
    const pausedRef = useSyncStateWithRef(paused)

    const sizeUnit = useMemo(() => (isFullScreen ? 'vw' : 'px'), [isFullScreen])
    const scale = useMemo(() => {
        if (isFullScreen) {
            return 1
        }

        const value = width / 100

        if (value * outerBubbleMaxHeight < height) {
            return value
        }

        // make sure that it fits the height
        return (height * 0.95) / outerBubbleMaxHeight
    }, [isFullScreen, width, height])

    useEffect(() => {
        const outerControls = outerAnimate(
            getBreathAnimationTransactions(
                outerScope,
                !isFullScreen || isMobileView
                    ? outerBubbleMobileMinHeight
                    : outerBubbleMinHeight,
                outerBubbleMaxHeight,
                isFullScreen,
                scale
            ),
            {
                repeat: Infinity,
                repeatType: 'loop'
            }
        )
        outerAnimationControlsRef.current = outerControls

        if (pausedRef.current) {
            outerControls.pause()
        }

        const innerControls = innerAnimate(
            getBreathAnimationTransactions(
                innerScope,
                !isFullScreen || isMobileView
                    ? innerBubbleMobileMinHeight
                    : innerBubbleMinHeight,
                innerBubbleMaxHeight,
                isFullScreen,
                scale
            ),
            {
                repeat: Infinity,
                repeatType: 'loop'
            }
        )
        innerAnimationControlsRef.current = innerControls

        if (pausedRef.current) {
            innerControls.pause()
        }

        return () => {
            outerControls.stop()
            outerAnimationControlsRef.current = null

            innerControls.stop()
            innerAnimationControlsRef.current = null
        }
    }, [
        innerAnimate,
        innerScope,
        isMobileView,
        outerAnimate,
        outerScope,
        orientation,
        pausedRef,
        isFullScreen,
        scale
    ])

    useEffect(() => {
        if (paused) {
            outerAnimationControlsRef.current?.pause()
            innerAnimationControlsRef.current?.pause()
        } else {
            outerAnimationControlsRef.current?.play()
            innerAnimationControlsRef.current?.play()
        }
    }, [paused])

    useEffect(() => {
        if (restartAnimationToken) {
            outerAnimationControlsRef.current?.stop()
            innerAnimationControlsRef.current?.stop()

            outerAnimationControlsRef.current?.play()
            innerAnimationControlsRef.current?.play()
        }
    }, [restartAnimationToken])

    return (
        <Box
            ref={containerRef}
            sx={{
                position: 'absolute',
                display: 'flex',
                overflow: 'hidden',
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                zIndex: breathAnimationZIndex
            }}
            data-testid="ThriveBreathIndicator"
        >
            <motion.div
                key={'thrive-outer-breath-indicator'}
                ref={outerScope}
                initial={false}
                style={{
                    position: 'absolute',
                    alignSelf: 'flex-end',
                    width: `${2 * outerBubbleMinHeight * scale}${sizeUnit}`,
                    height: `${outerBubbleMinHeight * scale}${sizeUnit}`,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: alpha(theme.palette.common.white, 0.6),
                    borderBottom: '0',
                    borderTopLeftRadius: `${
                        2 * outerBubbleMinHeight * scale
                    }${sizeUnit}`,
                    borderTopRightRadius: `${
                        2 * outerBubbleMinHeight * scale
                    }${sizeUnit}`
                }}
            />
            <motion.div
                key={'thrive-inner-breath-indicator'}
                ref={innerScope}
                initial={false}
                style={{
                    position: 'absolute',
                    alignSelf: 'flex-end',
                    width: `${2 * innerBubbleMinHeight * scale}${sizeUnit}`,
                    height: `${innerBubbleMinHeight * scale}${sizeUnit}`,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: alpha(theme.palette.common.white, 0.6),
                    backgroundColor: alpha(theme.palette.common.white, 0.25),
                    borderBottom: '0',
                    borderTopLeftRadius: `${
                        2 * innerBubbleMinHeight * scale
                    }${sizeUnit}`,
                    borderTopRightRadius: `${
                        2 * innerBubbleMinHeight * scale
                    }${sizeUnit}`
                }}
            />
        </Box>
    )
}

export default memo(ThriveBreathIndicator)
