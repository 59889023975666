import { frameDurationMultiplier } from './constants'

/**
 *
 * @param frameDuration -- duration of one frame in seconds
 * @returns duration of appearing animation in milliseconds
 */
export function getAppearingAnimationDuration(frameDuration: number): number {
    return (frameDuration + frameDuration * frameDurationMultiplier) * 1000
}

/**
 *
 * @param frameDuration -- duration of one frame in seconds
 * @param framePosition -- index of frame among all frames
 * @returns duration of delay in milliseconds before appearing animation will start
 */
export function getAppearingAnimationDelay(
    frameDuration: number,
    framePosition: number
): number {
    return frameDuration * framePosition * 1000
}
