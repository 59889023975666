import { defineMessages, MessageDescriptor } from 'react-intl'
import { WebApplicationLocale } from '@thriveglobal/thrive-web-core'

export type LocalizedIdNamePair = [string, MessageDescriptor]

export const cardHeight = 333
export const initialLimit = 9
export const listItemMinHeight = 10

export const messages = defineMessages({
    video: {
        defaultMessage: 'video',
        description: 'Video reset tab title'
    },
    audio: {
        defaultMessage: 'audio',
        description: 'Audio reset tab title'
    },
    allResets: {
        defaultMessage: 'All Resets',
        description: 'All resets label'
    },
    favorites: {
        defaultMessage: 'Favorites',
        description: 'Favorites resets label'
    }
})

export enum ResetListTabIds {
    Video = 'video',
    Audio = 'audio'
}

export const tabsDOMIds = {
    [ResetListTabIds.Video]: 'reset-list-video-tab',
    [ResetListTabIds.Audio]: 'reset-list-audio-tab'
} as const

export const tabPanelsDOMIds = {
    [ResetListTabIds.Video]: 'reset-list-video-tab-panel',
    [ResetListTabIds.Audio]: 'reset-list-audio-tab-panel'
} as const

export const resetListPanelTabTitleMap = {
    [ResetListTabIds.Video]: messages.video,
    [ResetListTabIds.Audio]: messages.audio
} as const

export const resetListPanelTabIdTitlePairs: LocalizedIdNamePair[] =
    Object.entries(resetListPanelTabTitleMap)

export enum DefaultThemes {
    All = 'All',
    Favorite = 'Favorite'
}

export const supportedAudioResetsLocales: WebApplicationLocale[] = [
    'en-US',
    'pseudo'
]

export enum ResetKeywords {
    Intro = 'INTRO'
}
