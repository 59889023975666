import { Paths } from '../../types'
import set from 'lodash/set'
import get from 'lodash/get'

// Unlike _.set, setPathValue does not produce a deep copy, it mutates only the specified path
export const setPathValue = <T extends Object>(
    source: T,
    path: Paths<T>,
    value: any
): T => {
    if (!path) {
        return value
    }

    const [pathEntry, ...restPath] = path.split('.')
    const newSource: T = { ...source }

    if (restPath.length === 0) {
        set(newSource, pathEntry, value)
        return newSource
    }

    const subPath = restPath.join('.')
    const subPathValue = get(source, pathEntry) ?? {}

    const newSubValue = setPathValue(subPathValue, subPath, value)

    set(newSource, pathEntry, newSubValue)

    return newSource
}

export default setPathValue
