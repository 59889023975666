import { Stack } from '@mui/material'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import { LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useRef } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ResetFeatureFlags } from '../../../../config/features'
import { ResetTypes } from '../../../../constants/resetType'
import {
    Feedback,
    ThriveResetAudio
} from '../../../../graphql/generated/autogenerated'
import { useThriveAudioFavoriteReset } from '../../../../hooks/useResetFavorite'
import useAudioResetFeedback from '../../../../hooks/useResetFeedback/useAudioResetFeedback'
import { useThriveAudioResetLike } from '../../../../hooks/useResetLike'
import { PostResetActionButton } from '../../../elements/PostResetScreen'
import ShareResetButton from '../../../elements/ShareResetButton'

export type PostThriveAudioResetActionsProps = {
    reset: ThriveResetAudio
    onReplay?: () => void
    onShared?: () => void
    onFavored?: (isChecked: boolean) => void
    onClapped?: (isChecked: boolean) => void
    onLiked?: () => void
    onDisliked?: () => void
}

const messages = defineMessages({
    replayAriaLabel: {
        defaultMessage: 'Replay',
        description: 'Post Reset replay button aria label'
    },
    favoriteAriaLabel: {
        defaultMessage: 'Favorite',
        description: 'Post Reset favorite button aria label'
    },
    clapAriaLabel: {
        defaultMessage: 'Clap',
        description: 'Post Reset clap button aria label'
    },
    shareAriaLabel: {
        defaultMessage: 'Share',
        description: 'Post Reset share button aria label'
    },
    copied: {
        defaultMessage: 'Copied!',
        description: 'Post Reset copy reset url to clipboard success message'
    },
    likeAriaLabel: {
        defaultMessage: 'Like',
        description: 'Post Reset like button aria label'
    },
    dislikeAriaLabel: {
        defaultMessage: 'Dislike',
        description: 'Post Reset dislike button aria label'
    },
    likeButtonTooltip: {
        defaultMessage: 'Show me more like this',
        description: 'Post Reset like button tooltip'
    },
    dislikeButtonTooltip: {
        defaultMessage: 'Show me less like this',
        description: 'Post Reset dislike button tooltip'
    },
    favoriteButtonTooltip: {
        defaultMessage: 'Favorite',
        description: 'Post Reset favorite button tooltip'
    },
    copyButtonTooltip: {
        defaultMessage: 'Copy link',
        description: 'Post Reset copy button tooltip'
    }
})

function PostThriveResetActions(
    props: PostThriveAudioResetActionsProps
): JSX.Element {
    const {
        reset: thriveAudioReset,
        onReplay,
        onClapped,
        onFavored,
        onShared,
        onLiked,
        onDisliked
    } = props

    const intl = useIntl()

    const isResetFeedbackEnabled = useIsFeatureEnabled(
        ResetFeatureFlags.IsResetFeedbackEnabled,
        false,
        true
    )

    const contentContainerRef = useRef<HTMLDivElement>()

    const {
        createResetLike,
        deleteResetLike,
        isLoading: isLikeLoading
    } = useThriveAudioResetLike()

    const {
        createFavoriteReset,
        deleteFavoriteReset,
        isLoading: isFavoriteLoading
    } = useThriveAudioFavoriteReset()

    const { updateFeedback, isLoading: isFeedbackLoading } =
        useAudioResetFeedback()

    const isUpdating = isLikeLoading || isFavoriteLoading || isFeedbackLoading

    const isClapped = thriveAudioReset?.likes.isLiked ?? false
    const isFavorite = thriveAudioReset?.bookmarked ?? false
    const isLiked = thriveAudioReset?.latestFeedback === Feedback.Like || false
    const isDisliked =
        thriveAudioReset?.latestFeedback === Feedback.Dislike || false

    const handleClapClick = useCallback(() => {
        if (isUpdating || !thriveAudioReset) {
            return
        }

        const isChecked = !thriveAudioReset.likes.isLiked
        if (isChecked) {
            createResetLike(thriveAudioReset)
        } else {
            deleteResetLike(thriveAudioReset)
        }

        onClapped?.(isChecked)
    }, [
        createResetLike,
        deleteResetLike,
        isUpdating,
        thriveAudioReset,
        onClapped
    ])

    const handleFavoriteClick = useCallback(() => {
        if (isUpdating || !thriveAudioReset) {
            return
        }

        const isChecked = !thriveAudioReset.bookmarked
        if (isChecked) {
            createFavoriteReset(thriveAudioReset)
        } else {
            deleteFavoriteReset(thriveAudioReset)
        }

        onFavored?.(isChecked)
    }, [
        createFavoriteReset,
        deleteFavoriteReset,
        isUpdating,
        thriveAudioReset,
        onFavored
    ])

    const handleLikeClick = useCallback(async () => {
        if (thriveAudioReset.latestFeedback !== Feedback.Like && !isUpdating) {
            await updateFeedback(Feedback.Like, thriveAudioReset)
            onLiked?.()
        }
    }, [updateFeedback, isUpdating, thriveAudioReset, onLiked])

    const handleDislikeClick = useCallback(async () => {
        if (
            thriveAudioReset.latestFeedback !== Feedback.Dislike &&
            !isUpdating
        ) {
            await updateFeedback(Feedback.Dislike, thriveAudioReset)
            onDisliked?.()
        }
    }, [updateFeedback, isUpdating, thriveAudioReset, onDisliked])

    return (
        <Stack
            direction="row"
            spacing={{ xs: 1, md: 2 }}
            ref={contentContainerRef}
            data-testid="PostThriveAudioResetActions"
        >
            {!isResetFeedbackEnabled && (
                <>
                    <PostResetActionButton
                        onClick={onReplay}
                        aria-label={intl.formatMessage(
                            messages.replayAriaLabel
                        )}
                        data-testid="ReplayButton"
                        id={`postResetReplayButton_${thriveAudioReset?.id}`}
                    >
                        <LeafIcon icon={'arrow-rotate-left'} />
                    </PostResetActionButton>
                    <PostResetActionButton
                        isPressed={isClapped}
                        isUpdating={isUpdating}
                        onClick={handleClapClick}
                        data-testid="ClapButton"
                        aria-label={intl.formatMessage(messages.clapAriaLabel)}
                        id={`postResetClapButton_${thriveAudioReset?.id}`}
                    >
                        <LeafIcon
                            icon={'hands-clapping'}
                            iconStyle={isClapped ? 'solid' : 'regular'}
                        />
                    </PostResetActionButton>
                </>
            )}

            {isResetFeedbackEnabled && (
                <>
                    <PostResetActionButton
                        isPressed={isLiked}
                        isUpdating={isFeedbackLoading}
                        onClick={handleLikeClick}
                        data-testid="LikeButton"
                        aria-label={intl.formatMessage(messages.likeAriaLabel)}
                        tooltip={intl.formatMessage(messages.likeButtonTooltip)}
                        id={`postResetLikeButton_${thriveAudioReset?.id}`}
                    >
                        <LeafIcon
                            icon={'thumbs-up'}
                            iconStyle={isLiked ? 'solid' : 'regular'}
                        />
                    </PostResetActionButton>
                    <PostResetActionButton
                        isPressed={isDisliked}
                        isUpdating={isFeedbackLoading}
                        onClick={handleDislikeClick}
                        data-testid="DislikeButton"
                        aria-label={intl.formatMessage(
                            messages.dislikeAriaLabel
                        )}
                        tooltip={intl.formatMessage(
                            messages.dislikeButtonTooltip
                        )}
                        id={`postResetDislikeButton_${thriveAudioReset?.id}`}
                    >
                        <LeafIcon
                            icon={'thumbs-down'}
                            iconStyle={isDisliked ? 'solid' : 'regular'}
                        />
                    </PostResetActionButton>
                </>
            )}

            <PostResetActionButton
                isUpdating={isUpdating}
                isPressed={isFavorite}
                onClick={handleFavoriteClick}
                data-testid="FavoriteButton"
                aria-label={intl.formatMessage(messages.favoriteAriaLabel)}
                tooltip={intl.formatMessage(messages.favoriteButtonTooltip)}
                id={`postResetFavoriteButton_${thriveAudioReset?.id}`}
            >
                <LeafIcon
                    icon={'heart'}
                    iconStyle={isFavorite ? 'solid' : 'regular'}
                />
            </PostResetActionButton>

            <ShareResetButton
                resetId={thriveAudioReset?.id}
                resetName={thriveAudioReset?.name}
                resetType={ResetTypes.ThriveAudio}
                ButtonComponent={PostResetActionButton}
                contentContainerRef={contentContainerRef}
                id={`postResetShareButton_${thriveAudioReset?.id}`}
                onShared={onShared}
                data-testid="ShareButton"
                tooltip={intl.formatMessage(messages.copyButtonTooltip)}
                aria-label={intl.formatMessage(messages.shareAriaLabel)}
            >
                <LeafIcon icon={'link'} />
            </ShareResetButton>
        </Stack>
    )
}

export default React.memo(PostThriveResetActions)
