import { ResetAsset } from '../types'
import {
    Image,
    PersonalizedQuote,
    PersonalizedReset,
    ThriveQuote
} from '../graphql/generated/autogenerated'

export function isImageAsset(asset: ResetAsset): asset is Image {
    if (!asset) return false

    const hasImageType = asset?.__typename === 'Image'
    const hasImageUrl = Boolean((asset as Image)?.urls?.xl)
    const hasThumbnail = Boolean((asset as Image)?.urls?.xs)

    return hasImageType || (hasImageUrl && hasThumbnail)
}

export function isPersonalizedImageAsset(asset: Image): asset is Image {
    if (!asset) return false

    // NOTE: workaround till the backend doesn't have different types for Thrive image and Personalized image
    return Boolean(asset.user?.id)
}

export function isQuoteAsset(asset: ResetAsset): asset is ThriveQuote {
    if (!asset) return false

    return asset?.__typename === 'ThriveQuote'
}

export function isPersonalizedQuoteAsset(
    asset: ResetAsset
): asset is PersonalizedQuote {
    if (!asset) return false

    return asset?.__typename === 'PersonalizedQuote'
}

export function getUploadProgressPercentage(
    uploadedFilesNumber: number,
    total: number
) {
    if (total <= 0) {
        return 0
    }

    return Math.round((uploadedFilesNumber / total) * 100)
}

export function getExampleReset() {
    return {
        __typename: 'PersonalizedReset',
        _: null,
        name: null,
        id: '2e5cb710-3a9e-4381-b761-764ac44e544c',
        shared: [],
        frames: [
            {
                __typename: 'Image',
                id: 'b03be603-4067-45e0-8267-70ed1fbd56b2',
                urls: {
                    __typename: 'ImageUrls',
                    xs: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/5be3a471-ec64-409f-bdb3-713e71f99e00/xs',
                    sm: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/5be3a471-ec64-409f-bdb3-713e71f99e00/sm',
                    md: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/5be3a471-ec64-409f-bdb3-713e71f99e00/md',
                    lg: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/5be3a471-ec64-409f-bdb3-713e71f99e00/lg',
                    xl: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/5be3a471-ec64-409f-bdb3-713e71f99e00/xl'
                }
            } as Image,
            {
                __typename: 'Image',
                id: '857806e1-e2f8-4db0-87e9-8224d5a05960',
                urls: {
                    __typename: 'ImageUrls',
                    xs: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/3d9dec0b-d861-4ed5-87a8-2a1827365e00/xs',
                    sm: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/3d9dec0b-d861-4ed5-87a8-2a1827365e00/sm',
                    md: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/3d9dec0b-d861-4ed5-87a8-2a1827365e00/md',
                    lg: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/3d9dec0b-d861-4ed5-87a8-2a1827365e00/lg',
                    xl: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/3d9dec0b-d861-4ed5-87a8-2a1827365e00/xl'
                }
            } as Image,
            {
                __typename: 'Image',
                id: '54a5809a-7208-4ea8-a6f2-78952227bee1',
                urls: {
                    __typename: 'ImageUrls',
                    xs: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/55671a57-1628-411d-1c6c-648c01d4f200/xs',
                    sm: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/55671a57-1628-411d-1c6c-648c01d4f200/sm',
                    md: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/55671a57-1628-411d-1c6c-648c01d4f200/md',
                    lg: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/55671a57-1628-411d-1c6c-648c01d4f200/lg',
                    xl: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/55671a57-1628-411d-1c6c-648c01d4f200/xl'
                }
            } as Image,
            {
                _: null,
                __typename: 'Image',
                id: '1d39c55a-c50e-4955-9de9-22c6765e15ce',
                urls: {
                    __typename: 'ImageUrls',
                    xs: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/c2c9ecee-c989-49ea-feaf-6fbd64d03100/xs',
                    sm: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/c2c9ecee-c989-49ea-feaf-6fbd64d03100/sm',
                    md: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/c2c9ecee-c989-49ea-feaf-6fbd64d03100/md',
                    lg: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/c2c9ecee-c989-49ea-feaf-6fbd64d03100/lg',
                    xl: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/c2c9ecee-c989-49ea-feaf-6fbd64d03100/xl'
                }
            } as Image,
            {
                _: null,
                __typename: 'Image',
                id: '7515a8ef-1d89-4e9d-bb47-a62d9010cce0',
                urls: {
                    __typename: 'ImageUrls',
                    xs: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/5ab36f70-68f6-4f8c-4905-7e12df340f00/xs',
                    sm: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/5ab36f70-68f6-4f8c-4905-7e12df340f00/sm',
                    md: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/5ab36f70-68f6-4f8c-4905-7e12df340f00/md',
                    lg: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/5ab36f70-68f6-4f8c-4905-7e12df340f00/lg',
                    xl: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/5ab36f70-68f6-4f8c-4905-7e12df340f00/xl'
                }
            } as Image,
            {
                __typename: 'Image',
                id: '64a9254d-5da5-4b8f-be47-1ff64bb02fd9',
                urls: {
                    __typename: 'ImageUrls',
                    xs: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/d55fc981-45f9-41d9-f0ea-86209b139c00/xs',
                    sm: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/d55fc981-45f9-41d9-f0ea-86209b139c00/sm',
                    md: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/d55fc981-45f9-41d9-f0ea-86209b139c00/md',
                    lg: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/d55fc981-45f9-41d9-f0ea-86209b139c00/lg',
                    xl: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/d55fc981-45f9-41d9-f0ea-86209b139c00/xl'
                }
            } as Image
        ],
        audio: {
            __typename: 'Audio',
            id: '206862d9-f448-4751-a4f3-01e8c5fe6fe8',
            url: 'https://assets.thriveglobal.com/reset/audio/Clair%20de%20Lune%20_%20Debussy.mp3',
            title: 'Clair de Lune',
            author: 'Debussy',
            duration: 60,
            durationInSeconds: 60,
            provider: 'THRIVE',
            createdAt: null,
            updatedAt: null
        },
        durationInSeconds: 60,
        audioStartTime: 0,
        audioEndTime: 60,
        createdAt: null,
        updatedAt: null,
        user: null
    } as PersonalizedReset
}
