import { useCallback, useMemo, useState } from 'react'
import {
    defaultLoadMoreLimit,
    defaultPaginationLimit,
    defaultTotal,
    initialPage
} from './constants'
import usePagination from './usePagination'

export type InfinityScrollPaginationOptions = {
    total?: number
    limit?: number
    initialLoadMoreLimit?: number
}

function useInfinityScrollPagination({
    total = defaultTotal,
    limit: initialLimit = defaultPaginationLimit,
    initialLoadMoreLimit = defaultLoadMoreLimit
}: InfinityScrollPaginationOptions) {
    const paginationOptions = useMemo(() => {
        return {
            total,
            limit: initialLimit
        }
    }, [total, initialLimit])

    const { limit, page, setNextPage, hasMore, resetPagination } =
        usePagination(paginationOptions)

    const [loadMoreLimit, setLoadMoreLimit] = useState(initialLoadMoreLimit)
    const increaseLoadMoreLimit = useCallback(() => {
        setLoadMoreLimit((prevLimit) => loadMoreLimit + prevLimit)
    }, [loadMoreLimit])

    return {
        limit,
        page,

        setNextPage,
        hasMore,

        loadMoreLimit,
        increaseLoadMoreLimit,
        resetPagination
    }
}

export default useInfinityScrollPagination
