import { useCallback, useState } from 'react'
import { useAppDispatch } from '../../slices'
import {
    GetFavoriteThriveAudioResetsQuery,
    GetThriveAudioResetsQuery,
    ThriveResetAudio,
    useCreateFavoriteThriveAudioResetMutation,
    useDeleteFavoriteThriveAudioResetMutation
} from '../../graphql/generated/autogenerated'
import { upsertOneThriveAudioReset } from '../../slices/thriveAudioResets'
import { useFavoriteThriveAudioResets } from '../../hooks/useFavoriteResets'
import { useApolloClientCacheEvict } from '../../hooks/useApolloClientCacheEvict'

export type UseThriveAudioFavoriteResetResult = {
    isLoading: boolean
    createFavoriteReset: (reset: ThriveResetAudio) => Promise<void>
    deleteFavoriteReset: (reset: ThriveResetAudio) => Promise<void>
}

function useThriveAudioFavoriteReset(): UseThriveAudioFavoriteResetResult {
    const { addOneFavoriteReset, removeOneFavoriteReset } =
        useFavoriteThriveAudioResets()
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [createFavoriteReset] = useCreateFavoriteThriveAudioResetMutation()
    const [deleteFavoriteReset] = useDeleteFavoriteThriveAudioResetMutation()
    const { evictFieldFromCache } = useApolloClientCacheEvict()

    const handleCreateFavoriteReset = useCallback(
        async (reset: ThriveResetAudio) => {
            if (isLoading) {
                return
            }
            setIsLoading(true)
            try {
                // Create favorite reset optimistically as just 1 quick request is required
                const updatedReset: ThriveResetAudio = {
                    ...reset,
                    bookmarked: true
                }
                dispatch(upsertOneThriveAudioReset(updatedReset))
                addOneFavoriteReset(updatedReset)

                await createFavoriteReset({
                    variables: {
                        thriveResetAudioId: reset.id
                    }
                })

                evictFieldFromCache<GetFavoriteThriveAudioResetsQuery>(
                    'resetV2',
                    'audio.bookmark'
                )
                evictFieldFromCache<GetThriveAudioResetsQuery>(
                    'reset',
                    'thriveAudio'
                )
            } finally {
                setIsLoading(false)
            }
        },
        [
            addOneFavoriteReset,
            createFavoriteReset,
            dispatch,
            evictFieldFromCache,
            isLoading
        ]
    )

    const handleDeleteFavoriteReset = useCallback(
        async (reset: ThriveResetAudio) => {
            if (isLoading) {
                return
            }

            setIsLoading(true)
            try {
                // Delete favorite reset pessimistically as 2 requests are required
                // (delete + load 1 element from the next page) and we don't want to case a race
                await deleteFavoriteReset({
                    variables: {
                        thriveResetAudioId: reset.id
                    }
                })

                const updatedReset: ThriveResetAudio = {
                    ...reset,
                    bookmarked: false
                }

                dispatch(upsertOneThriveAudioReset(updatedReset))
                await removeOneFavoriteReset(updatedReset)

                evictFieldFromCache<GetFavoriteThriveAudioResetsQuery>(
                    'resetV2',
                    'audio.bookmark'
                )
                evictFieldFromCache<GetThriveAudioResetsQuery>(
                    'reset',
                    'thriveAudio'
                )
            } finally {
                setIsLoading(false)
            }
        },
        [
            removeOneFavoriteReset,
            deleteFavoriteReset,
            dispatch,
            evictFieldFromCache,
            isLoading
        ]
    )

    return {
        isLoading,
        createFavoriteReset: handleCreateFavoriteReset,
        deleteFavoriteReset: handleDeleteFavoriteReset
    }
}

export default useThriveAudioFavoriteReset
