import React, { ComponentProps, ComponentType, useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useGetNextRandomThriveReset } from '../../../../hooks/useGetNextReset'
import PostThriveReset from './PostThriveReset'

export interface PostThriveResetWithGetNextRandomThriveResetProps
    extends Omit<ComponentProps<typeof PostThriveReset>, 'nextThriveReset'> {
    keywords?: string[]
}

export function withGetNextRandomThriveReset(
    PostThriveResetComponent: ComponentType<
        ComponentProps<typeof PostThriveReset>
    >
) {
    return React.memo(function PostThriveResetWithGetNextRandomThriveReset(
        props: PostThriveResetWithGetNextRandomThriveResetProps
    ) {
        const { thriveReset } = props
        const { keywords, ...restProps } = props

        const getNextRandomThriveResetOptions = useMemo(() => {
            return !isEmpty(keywords)
                ? { variables: { filter: { keyword: { in: keywords } } } }
                : null
        }, [keywords])

        const [nextThriveReset] = useGetNextRandomThriveReset(
            thriveReset,
            getNextRandomThriveResetOptions
        )

        return (
            <PostThriveResetComponent
                {...restProps}
                nextThriveReset={nextThriveReset}
            />
        )
    })
}
