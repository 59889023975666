import { keyframes } from '@emotion/react'

export const appearingNew = keyframes({
    '0%': {
        opacity: 0
    },
    '15%': {
        opacity: 1
    },
    '90%': {
        opacity: 1
    },
    '100%': {
        opacity: 0
    }
})

export const appearing = keyframes({
    '0%': {
        opacity: 0
    },
    '20%': {
        opacity: 1
    },
    '100%': {
        opacity: 1
    }
})

export const disappearing = keyframes({
    '0%': {
        opacity: 1
    },
    '100%': {
        opacity: 0
    }
})
