import React from 'react'
import { Box, Grow, Theme } from '@mui/material'
import PlayState, { PlayStateProps } from './PlayState'
import { SxProps } from '@mui/system'

export interface PlayStateAnimatedProps extends PlayStateProps {
    animationToken: unknown
    sx?: SxProps<Theme>
}

function PlayStateAnimated(props: PlayStateAnimatedProps) {
    const { paused, animationToken, sx } = props

    return (
        <Grow
            in={Boolean(animationToken)}
            timeout={{
                exit: 600
            }}
        >
            <Box
                height="100%"
                width="100%"
                sx={sx}
                data-testid="PlayStateAnimated"
            >
                <PlayState paused={paused} />
            </Box>
        </Grow>
    )
}

export default React.memo(PlayStateAnimated)
