import React, { useMemo } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

function PauseIcon(props: SvgIconProps) {
    const { sx, ...restProps } = props

    const iconStyles: SvgIconProps['sx'] = useMemo(() => {
        return sx ?? {}
    }, [sx])

    return (
        <SvgIcon
            viewBox="0 0 48 48"
            width="48"
            height="48"
            fill="#FFFFFF"
            data-testid="CFPauseIcon"
            sx={{
                width: '4.8rem',
                height: '4.8rem',
                ...iconStyles
            }}
            {...restProps}
        >
            <path d="M16 12h3a2 2 0 0 1 2 2v20a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2V14c0-1.1.9-2 2-2zm13 0h3a2 2 0 0 1 2 2v20a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2V14c0-1.1.9-2 2-2z"></path>
        </SvgIcon>
    )
}

export default React.memo(PauseIcon)
