import { useCallback } from 'react'
import { Reference, StoreObject, useApolloClient, Cache } from '@apollo/client'
import { Paths } from '../../types'
import { setPathValue } from '../../utils/object'

type QueryField<TQuery> = keyof Omit<TQuery, '__typename'>
type EvictOptions = Omit<Cache.ModifyOptions, 'fields' | 'id'>

export type UseApolloClientCacheEvictResult = {
    evictFieldFromCache: <
        TQuery,
        TField extends QueryField<TQuery> = QueryField<TQuery>
    >(
        field: TField,
        path?: Paths<TQuery[TField]>,
        options?: EvictOptions
    ) => void

    evictObjectFromCache: (
        object: StoreObject | Reference,
        options?: EvictOptions
    ) => void
}

export function useApolloClientCacheEvict(): UseApolloClientCacheEvictResult {
    const client = useApolloClient()

    const handleEvictFieldFromCache = useCallback(
        <TQuery, TField extends QueryField<TQuery> = QueryField<TQuery>>(
            field: TField,
            path?: Paths<TQuery[TField]>,
            options: EvictOptions = {}
        ) => {
            client.cache.modify({
                fields: {
                    [field]: (previous: TQuery[TField], { DELETE }) =>
                        setPathValue(previous, path, DELETE)
                },
                ...options
            })
        },
        [client]
    )

    const handleEvictObjectFromCache = useCallback(
        (object: StoreObject | Reference, options: EvictOptions = {}) => {
            const objectId = client.cache.identify(object)
            if (objectId) {
                client.cache.evict({ id: objectId, ...options })
            }
        },
        [client]
    )

    return {
        evictFieldFromCache: handleEvictFieldFromCache,
        evictObjectFromCache: handleEvictObjectFromCache
    }
}

export default useApolloClientCacheEvict
