import React, { ComponentType } from 'react'
import { AspectRatio } from 'react-aspect-ratio'
import 'react-aspect-ratio/aspect-ratio.css'

export type AspectRatioProps = {
    aspectRatio: number
    fullScreen?: boolean
}

export default function withAspectRatio<TProps>(
    Component: ComponentType<TProps>
) {
    return React.memo((props: TProps & AspectRatioProps) => {
        const { fullScreen, aspectRatio } = props

        if (fullScreen) {
            return <Component {...props} />
        }

        return (
            <AspectRatio data-testid="AspectRatioWrapper" ratio={aspectRatio}>
                <Component {...props} />
            </AspectRatio>
        )
    })
}
