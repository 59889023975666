import { useCallback, useState } from 'react'
import { useAppDispatch } from '../../slices'
import {
    GetFavoriteResetsQuery,
    GetThriveResetsQuery,
    ThriveReset,
    useCreateFavoriteThriveResetMutation,
    useDeleteFavoriteThriveResetMutation
} from '../../graphql/generated/autogenerated'
import { upsertOneThriveReset } from '../../slices/thriveResets'
import { useFavoriteResets } from '../../hooks/useFavoriteResets'
import { useApolloClientCacheEvict } from '../../hooks/useApolloClientCacheEvict'

export type UseThriveFavoriteResetResult = {
    isLoading: boolean
    createFavoriteReset: (reset: ThriveReset) => Promise<void>
    deleteFavoriteReset: (reset: ThriveReset) => Promise<void>
}

function useThriveFavoriteReset(): UseThriveFavoriteResetResult {
    const { addOneFavoriteReset, removeOneFavoriteReset } = useFavoriteResets()
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [createFavoriteReset] = useCreateFavoriteThriveResetMutation()
    const [deleteFavoriteReset] = useDeleteFavoriteThriveResetMutation()
    const { evictFieldFromCache } = useApolloClientCacheEvict()

    const handleCreateFavoriteReset = useCallback(
        async (reset: ThriveReset) => {
            if (isLoading) {
                return
            }
            setIsLoading(true)
            try {
                // Create favorite reset optimistically as just 1 quick request is required
                const updatedReset: ThriveReset = { ...reset, bookmarked: true }
                dispatch(upsertOneThriveReset(updatedReset))
                addOneFavoriteReset(updatedReset)

                await createFavoriteReset({
                    variables: {
                        resetId: reset.id
                    }
                })

                evictFieldFromCache<GetFavoriteResetsQuery>('resetV2', 'video')
                evictFieldFromCache<GetThriveResetsQuery>('reset', 'thrive')
            } finally {
                setIsLoading(false)
            }
        },
        [
            addOneFavoriteReset,
            createFavoriteReset,
            dispatch,
            evictFieldFromCache,
            isLoading
        ]
    )

    const handleDeleteFavoriteReset = useCallback(
        async (reset: ThriveReset) => {
            if (isLoading) {
                return
            }

            setIsLoading(true)
            try {
                // Delete favorite reset pessimistically as 2 requests are required
                // (delete + load 1 element from the next page) and we don't want to case a race
                await deleteFavoriteReset({
                    variables: {
                        resetId: reset.id
                    }
                })

                const updatedReset: ThriveReset = {
                    ...reset,
                    bookmarked: false
                }

                dispatch(upsertOneThriveReset(updatedReset))
                await removeOneFavoriteReset(updatedReset)

                evictFieldFromCache<GetFavoriteResetsQuery>('resetV2', 'video')
                evictFieldFromCache<GetThriveResetsQuery>('reset', 'thrive')
            } finally {
                setIsLoading(false)
            }
        },
        [
            removeOneFavoriteReset,
            deleteFavoriteReset,
            dispatch,
            evictFieldFromCache,
            isLoading
        ]
    )

    return {
        isLoading,
        createFavoriteReset: handleCreateFavoriteReset,
        deleteFavoriteReset: handleDeleteFavoriteReset
    }
}

export default useThriveFavoriteReset
