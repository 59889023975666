import { useCallback, useState } from 'react'
import {
    Feedback,
    GetThriveResetsQuery,
    ThriveReset,
    useCreateResetFeedbackMutation
} from '../../graphql/generated/autogenerated'
import { upsertOneThriveReset } from '../../slices/thriveResets'
import { useApolloClientCacheEvict } from '../useApolloClientCacheEvict'
import { useAppDispatch } from '../../slices'

export type UseResetFeedbackResult = {
    isLoading: boolean
    updateFeedback: (feedback: Feedback, reset: ThriveReset) => Promise<void>
}

function useResetFeedback(): UseResetFeedbackResult {
    const [createResetFeedback] = useCreateResetFeedbackMutation()
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useAppDispatch()
    const { evictFieldFromCache } = useApolloClientCacheEvict()

    const handleFeedback = useCallback(
        async (feedback: Feedback, reset: ThriveReset) => {
            if (isLoading) {
                return
            }
            setIsLoading(true)
            try {
                const updatedReset: ThriveReset = {
                    ...reset,
                    latestFeedback: feedback
                }
                dispatch(upsertOneThriveReset(updatedReset))
                await createResetFeedback({
                    variables: {
                        feedback,
                        createId: reset.id
                    }
                })
                evictFieldFromCache<GetThriveResetsQuery>('reset', 'thrive')
            } finally {
                setIsLoading(false)
            }
        },
        [createResetFeedback, dispatch, evictFieldFromCache, isLoading]
    )

    return {
        isLoading,
        updateFeedback: handleFeedback
    }
}

export default useResetFeedback
