import {
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef
} from 'react'

export type UseHTMLAudioEventResult = {
    // bind event manually
    bindEvent: (api: HTMLAudioElement, eventCallback: EventListener) => void
    unbindEvent: (api: HTMLAudioElement) => void
}

// bind to iframe player event. supports just one callback per event
function useHTMLAudioEvent(
    event: string,
    audioApi: MutableRefObject<HTMLAudioElement | null>,
    callback: EventListener
): UseHTMLAudioEventResult {
    const callbackRef = useRef<EventListener>(null)

    const unbindEvent = useCallback(
        (api: HTMLAudioElement) => {
            if (!api || !callbackRef.current) {
                return
            }

            api?.removeEventListener?.(event, callbackRef.current)
            callbackRef.current = null
        },
        [event]
    )

    const bindEvent = useCallback(
        (api: HTMLAudioElement, eventCallback: EventListener) => {
            unbindEvent(api)

            if (eventCallback) {
                api?.addEventListener?.(event, eventCallback)
                callbackRef.current = eventCallback
            }
        },
        [event, unbindEvent]
    )

    useEffect(() => {
        const api = audioApi?.current

        if (!api) return

        unbindEvent(api)

        if (callback) {
            api?.addEventListener?.(event, callback)
            callbackRef.current = callback
        }

        return () => {
            if (callback) {
                api?.removeEventListener?.(event, callback)
                callbackRef.current = null
            }
        }
    }, [callback, event, audioApi, unbindEvent])

    return useMemo<UseHTMLAudioEventResult>(
        () => ({ bindEvent, unbindEvent }),
        [bindEvent, unbindEvent]
    )
}

export default useHTMLAudioEvent
