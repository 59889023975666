import { defaultPaginationLimit, defaultTotal, initialPage } from './constants'
import { useCallback, useMemo, useState } from 'react'

export type PaginationOptions = {
    total?: number
    limit?: number
}

function usePagination({
    total = defaultTotal,
    limit = defaultPaginationLimit
}: PaginationOptions) {
    const [page, setPage] = useState(initialPage)

    const hasMore = useMemo(() => {
        return total / (limit * page) > 1
    }, [total, limit, page])

    const setNextPage = useCallback(() => {
        const nextPage = hasMore ? page + 1 : page

        setPage(nextPage)
        return nextPage
    }, [hasMore, page])

    const resetPagination = useCallback(() => {
        setPage(initialPage)
    }, [])

    return {
        limit,
        page,
        hasMore,
        setNextPage,
        resetPagination
    }
}

export default usePagination
