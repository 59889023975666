import { lazy } from 'react'
import { withPageFallback } from '../../../components/fallbacks/PageFallback'

export const ThriveDailyReset = lazy(() => import('./ThriveDailyReset'))

const ThriveDailyResetWithFallback = withPageFallback(ThriveDailyReset, {
    pageName: 'ThriveDailyResetPage'
})

export default ThriveDailyResetWithFallback
