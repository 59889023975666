import withPostThriveResetTracking from '../../../../tracking/withPostThriveResetTracking'
import PostThriveReset from './PostThriveReset'
import { withGetNextRandomThriveReset } from './withGetNextRandomThriveReset'

const PostThriveResetTrackable = withPostThriveResetTracking(PostThriveReset)

const PostThriveResetWithGetNextRandomThriveResetTrackable =
    withGetNextRandomThriveReset(PostThriveResetTrackable)

export {
    PostThriveResetTrackable,
    PostThriveResetWithGetNextRandomThriveResetTrackable
}

export * from './PostThriveReset'

export default PostThriveReset
