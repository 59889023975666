import { Box, Stack, useMediaQuery } from '@mui/material'
import { useSetMobileNavigationState } from '@thriveglobal/thrive-web-core'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, {
    MutableRefObject,
    RefObject,
    SyntheticEvent,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
    type ReactNode
} from 'react'
import useTemporaryAnimationToken from '../../../hooks/useTemporalAnimationToken'
import ThriveAudioResetLogo from '../../icons/ThriveAudioResetLogo'
import AudioPlayer from '../AudioPlayer'
import {
    ClosePlayerPopupButton,
    InitialPlayButton,
    PlayerContentFullscreenLoading,
    PlayerControls,
    listenInterval,
    playerControlsZIndex,
    useResetPlayerVolume,
    volumeLevelMin
} from '../CustomResetPlayer'
import { PlayStateAnimated } from '../PlayState'
import {
    PostResetScreenContainer,
    postResetTimeOffsetInSeconds
} from '../PostResetScreen'
import { postResetOverlayZIndex } from './constants'

export type RenderPostResetProps = {
    isPostResetDisplayed: boolean
}

export type RenderPostReset = (props: RenderPostResetProps) => ReactNode

export type ResetAudioPlayerProps = {
    src: string
    durationInSeconds: number
    autoPlay?: boolean
    renderPostReset?: RenderPostReset
    onClose: () => void
    // player callbacks
    onPlay?: () => void
    onPause?: () => void
    onTimeUpdate?: (newCurrentTime: number) => void
    onEnded?: () => void
    onLoadedMetadata?: () => void
    onVolumeChanged?: () => void
    onError?: () => void
}

export type ResetAudioPlayerRef = {
    play: () => void
    pause: () => void
    replay: () => void
    elementRef: RefObject<HTMLAudioElement | null>
}

function ResetAudioPlayer(
    props: ResetAudioPlayerProps,
    ref: MutableRefObject<ResetAudioPlayerRef>
): JSX.Element {
    const {
        src,
        durationInSeconds,
        autoPlay,
        renderPostReset,
        onEnded,
        onPlay,
        onLoadedMetadata,
        onVolumeChanged,
        onPause,
        onError,
        onClose,
        onTimeUpdate
    } = props
    const theme = useTheme()

    const audioPlayerRef = useRef<HTMLAudioElement>(null)

    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const [volume, setVolume] = useResetPlayerVolume()
    const [playbackStartedOnce, setPlaybackStartedOnce] = useState(false)
    const [isPostResetDisplayed, setIsPostResetDisplayed] = useState(false)
    const [paused, setPaused] = useState(true)

    const {
        animationToken: playStateAnimationToken,
        updateAnimationToken: updatePlayStateAnimationToken
    } = useTemporaryAnimationToken()

    const setMobileNavbarState = useSetMobileNavigationState()

    const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

    const PostResetElement = useMemo<ReactNode>(
        () => renderPostReset?.({ isPostResetDisplayed }),
        [renderPostReset, isPostResetDisplayed]
    )

    const hasPostReset = useMemo<boolean>(
        () => !!PostResetElement,
        [PostResetElement]
    )

    const muted = useMemo(() => {
        return volume <= volumeLevelMin
    }, [volume])

    useEffect(() => {
        setMobileNavbarState(false)

        return function cleanup() {
            setMobileNavbarState(true)
        }
    }, [setMobileNavbarState])

    const play = useCallback(() => {
        const audioPlayerElement = audioPlayerRef.current

        audioPlayerElement?.play?.().then(() => {
            setPlaybackStartedOnce(true)
        })
        setPaused(audioPlayerElement?.paused ?? true)
    }, [])

    const pause = useCallback(() => {
        const audioPlayerElement = audioPlayerRef.current

        audioPlayerElement?.pause()
        setPaused(audioPlayerElement?.paused ?? true)
    }, [])

    // HACK: 1. autoPlay causing audio to keep playing by OS after modal is closed in Chrome on OSX, so trigger play manually once audio is ready
    // HACK: 2. onCanPlay is not supported by Safari, use onLoadedMetadata instead
    const handleLoadedMetadata = useCallback(() => {
        const audioPlayerElement = audioPlayerRef.current

        const audioDuration = audioPlayerElement?.duration ?? durationInSeconds
        setDuration(audioDuration)

        const isPaused = !!audioPlayerElement?.paused
        if (src && isPaused && autoPlay) {
            play()
        }

        onLoadedMetadata?.()
    }, [durationInSeconds, src, autoPlay, play, onLoadedMetadata])

    const handlePlayControlClick = useCallback(() => {
        play()
    }, [play])

    const handlePauseControlClick = useCallback(() => {
        pause()
    }, [pause])

    const resetAudioPlayback = useCallback(() => {
        const audioPlayerElement = audioPlayerRef.current
        if (audioPlayerElement) {
            audioPlayerElement.currentTime = 0
        }
    }, [])

    const resetResetPlayback = useCallback(() => {
        resetAudioPlayback()
    }, [resetAudioPlayback])

    const handleVolumeChange = useCallback(
        (newVolume: number) => {
            setVolume(newVolume)
        },
        [setVolume]
    )

    const handleListenAudio = useCallback(
        (time: number) => {
            const newCurrentTime = time
            setCurrentTime(newCurrentTime)

            onTimeUpdate?.(newCurrentTime)

            const resetDuration =
                audioPlayerRef.current?.duration ?? durationInSeconds

            const showPostReset =
                resetDuration - newCurrentTime <= postResetTimeOffsetInSeconds

            setIsPostResetDisplayed(hasPostReset && showPostReset)
        },

        [onTimeUpdate, durationInSeconds, hasPostReset]
    )

    const handleEnded = useCallback(() => {
        pause()

        resetResetPlayback()

        setMobileNavbarState(true)

        onEnded?.()
    }, [pause, resetResetPlayback, setMobileNavbarState, onEnded])

    const handlePlayerClose = useCallback(
        (event: SyntheticEvent) => {
            event?.stopPropagation()
            setMobileNavbarState(true)

            onClose()
        },
        [onClose, setMobileNavbarState]
    )

    const handleControlsContainerClick = useCallback(
        (event: SyntheticEvent) => {
            event.stopPropagation()
        },
        []
    )

    const handleInitialPlayClick = useCallback(
        (event: SyntheticEvent) => {
            event.stopPropagation()

            play()
        },
        [play]
    )

    const handleMiddlePlayerClick = useCallback(
        (event: SyntheticEvent) => {
            event.stopPropagation()

            updatePlayStateAnimationToken()

            if (paused) {
                play()
            } else {
                pause()
            }
        },
        [play, paused, pause, updatePlayStateAnimationToken]
    )

    const handleCurrentTimeChangeManually = useCallback(
        (newCurrentTime: number) => {
            setCurrentTime(newCurrentTime)

            pause()
        },
        [pause]
    )

    const handleCurrentTimeChangeCommittedManually = useCallback(
        (newCurrentTime: number) => {
            const audioPlayerElement = audioPlayerRef.current

            if (audioPlayerElement) {
                audioPlayerElement.currentTime = newCurrentTime
            }

            play()
        },
        [play]
    )

    const replay = useCallback(() => {
        const audioPlayerElement = audioPlayerRef.current
        if (!audioPlayerElement) {
            return
        }

        audioPlayerElement.currentTime = 0

        if (paused) {
            play()
        }
    }, [paused, play])

    useImperativeHandle(
        ref,
        (): ResetAudioPlayerRef => {
            return {
                play,
                pause,
                replay,
                elementRef: audioPlayerRef
            }
        },
        [audioPlayerRef, play, pause, replay]
    )

    return (
        <Box
            position="absolute"
            width="100%"
            height="100%"
            bgcolor={theme.palette.primary.main}
            data-testid="ThriveAudioResetPlayerContent"
        >
            {src ? (
                <>
                    <Box
                        sx={[
                            {
                                position: 'absolute',
                                bottom: 0,
                                width: '100%',
                                px: theme.spacing(2.75),
                                pb: theme.spacing(1.375),
                                zIndex: playerControlsZIndex
                            },
                            isMobileView && {
                                px: 0,
                                pb: 0
                            }
                        ]}
                        style={{ visibility: 'visible' }}
                        onClick={handleControlsContainerClick}
                        data-testid="ThriveAudioResetPlayerControlsContainer"
                    >
                        <PlayerControls
                            onPlay={handlePlayControlClick}
                            onPause={handlePauseControlClick}
                            isPlaying={!paused}
                            onVolumeChange={handleVolumeChange}
                            volume={volume}
                            currentTime={currentTime}
                            duration={duration}
                            onCurrentTimeChange={
                                handleCurrentTimeChangeManually
                            }
                            onCurrentTimeChangeCommitted={
                                handleCurrentTimeChangeCommittedManually
                            }
                        />
                    </Box>

                    {!isPostResetDisplayed && playbackStartedOnce && (
                        <Box
                            data-testid="ThriveAudioResetAnimation"
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}
                        >
                            <ThriveAudioResetLogo />
                        </Box>
                    )}

                    <Stack
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: playerControlsZIndex
                        }}
                        alignItems="center"
                        justifyContent="center"
                        role="button"
                        onClick={handleMiddlePlayerClick}
                    >
                        {!playbackStartedOnce && (
                            <InitialPlayButton
                                onClick={handleInitialPlayClick}
                            />
                        )}
                        <PlayStateAnimated
                            paused={paused}
                            animationToken={playStateAnimationToken}
                        />
                    </Stack>

                    {src && (
                        <div data-testid="ThriveAudioResetAudio">
                            <AudioPlayer
                                ref={audioPlayerRef}
                                src={src}
                                autoPlay={autoPlay}
                                controlsList="nodownload"
                                volume={volume}
                                muted={muted}
                                onLoadedMetadata={handleLoadedMetadata}
                                onPlay={onPlay}
                                onPause={onPause}
                                onVolumeChange={onVolumeChanged}
                                onEnded={handleEnded}
                                onError={onError}
                                onListen={handleListenAudio}
                                listenInterval={listenInterval}
                            />
                        </div>
                    )}

                    {hasPostReset && (
                        <PostResetScreenContainer
                            open={isPostResetDisplayed}
                            overlayZIndex={postResetOverlayZIndex}
                        >
                            {PostResetElement}
                        </PostResetScreenContainer>
                    )}
                </>
            ) : (
                <PlayerContentFullscreenLoading
                    loadingColor={theme.palette.primary.contrastText}
                />
            )}

            <ClosePlayerPopupButton
                onClick={handlePlayerClose}
                sx={{ top: 0, zIndex: playerControlsZIndex }}
            />
        </Box>
    )
}

export default React.memo(React.forwardRef(ResetAudioPlayer))
