import { AnimationScope, AnimationSequence } from 'framer-motion'

// breath indicator v3
export const getBreathAnimationTransactions = (
    scope: AnimationScope<any>,
    bubbleMinHeight: number,
    bubbleMaxHeight: number,
    isFullScreen = true,
    scale = 1
): AnimationSequence => {
    const sizeUnit = isFullScreen ? 'vw' : 'px'

    return [
        [
            scope.current,
            {
                width: [
                    `${bubbleMinHeight * 2 * scale}${sizeUnit}`,
                    `${bubbleMaxHeight * 2 * scale}${sizeUnit}`
                ],
                height: [
                    `${bubbleMinHeight * scale}${sizeUnit}`,
                    `${bubbleMaxHeight * scale}${sizeUnit}`
                ],
                borderTopLeftRadius: [
                    `${bubbleMinHeight * 2 * scale}${sizeUnit}`,
                    `${bubbleMaxHeight * 2 * scale}${sizeUnit}`
                ],
                borderTopRightRadius: [
                    `${bubbleMinHeight * 2 * scale}${sizeUnit}`,
                    `${bubbleMaxHeight * 2 * scale}${sizeUnit}`
                ]
            },
            {
                duration: 6,
                ease: [0.3, 0, 0.5, 1]
            }
        ],
        [
            scope.current,
            {
                width: [
                    `${bubbleMaxHeight * 2 * scale}${sizeUnit}`,
                    `${bubbleMaxHeight * 2 * scale}${sizeUnit}`
                ]
            },
            { duration: 1.5 }
        ],
        [
            scope.current,
            {
                width: [
                    `${bubbleMaxHeight * 2 * scale}${sizeUnit}`,
                    `${bubbleMinHeight * 2 * scale}${sizeUnit}`
                ],
                height: [
                    `${bubbleMaxHeight * scale}${sizeUnit}`,
                    `${bubbleMinHeight * scale}${sizeUnit}`
                ],
                borderTopLeftRadius: [
                    `${bubbleMaxHeight * 2 * scale}${sizeUnit}`,
                    `${bubbleMinHeight * 2 * scale}${sizeUnit}`
                ],
                borderTopRightRadius: [
                    `${bubbleMaxHeight * 2 * scale}${sizeUnit}`,
                    `${bubbleMinHeight * 2 * scale}${sizeUnit}`
                ]
            },
            { duration: 6, ease: [0.3, 0, 0.5, 1] }
        ],
        [
            scope.current,
            {
                width: [
                    `${bubbleMinHeight * 2 * scale}${sizeUnit}`,
                    `${bubbleMinHeight * 2 * scale}${sizeUnit}`
                ]
            },
            { duration: 1.5 }
        ]
    ]
}
