import { useLocalStorage } from '@thriveglobal/thrive-web-core'
import { useMemo } from 'react'
import {
    isResetAutoplayEnabledDefaultValue,
    isResetAutoplayEnabledStorageKey
} from './constants'

export type UseIsResetAutoplayEnabledOptions = {
    defaultValue?: boolean
}

export type UseIsResetAutoplayEnabledResult = [
    boolean,
    (value: boolean) => void
]

function useIsResetAutoplayEnabled({
    defaultValue
}: UseIsResetAutoplayEnabledOptions = {}): UseIsResetAutoplayEnabledResult {
    const [isEnabled, setIsEnabled] = useLocalStorage<boolean>(
        isResetAutoplayEnabledStorageKey,
        defaultValue ?? isResetAutoplayEnabledDefaultValue
    )

    return useMemo<UseIsResetAutoplayEnabledResult>(
        () => [isEnabled, setIsEnabled],
        [isEnabled, setIsEnabled]
    )
}

export default useIsResetAutoplayEnabled
