import { Button, type ButtonProps } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { useIsMsTeams } from '@thriveglobal/thrive-web-ms-teams-app'
import { AvoTypes } from '@thriveglobal/thrive-web-tracking'
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    type ComponentType
} from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { ResetTypes } from '../../../constants/resetType'
import useResetShareUrls from '../../../hooks/useResetShareUrls/useResetShareUrls'
import type {
    ThriveAudioResetParams,
    ThriveAudioResetSearchParams
} from '../../../pages/ThriveAudioReset/types'
import type {
    ThriveResetParams,
    ThriveResetSearchParams
} from '../../../pages/ThriveReset/types'
import { Routes } from '../../../routes/routes'
import withShareResetPopupTracking from '../../../tracking/withShareResetPopupTracking'
import { copyTextToClipboard } from '../../../utils/clipboard'
import ShareResetPopup from '../ShareResetPopup'
import { autoCloseCopyTooltipTimeout } from '../ShareResetPopup/constants'
import { CopiedTooltipStyled } from '../ShareResetPopup/ShareResetPopupStyled'

export type ShareResetButtonProps<T extends ButtonProps> = {
    ButtonComponent?: ComponentType<T>
    resetId: string
    resetName: string
    resetType: ResetTypes
    contentContainerRef: React.RefObject<HTMLDivElement>
    isMsTeamsSharingDisabled?: boolean
    onShared?: () => void
} & T

const messages = defineMessages({
    shareAriaLabel: {
        defaultMessage: 'Share',
        description: 'Post Reset share button aria label'
    },
    copied: {
        defaultMessage: 'Copied!',
        description: 'Post Reset copy reset url to clipboard success message'
    },

    copyButtonTooltip: {
        defaultMessage: 'Copy link',
        description: 'Post Reset copy button tooltip'
    }
})

export function ShareResetButton<T extends ButtonProps>(
    props: ShareResetButtonProps<T>
): JSX.Element {
    const {
        children,
        ButtonComponent,
        resetId,
        resetName,
        resetType,
        onShared,
        contentContainerRef,
        isMsTeamsSharingDisabled,
        ...restProps
    } = props

    const intl = useIntl()
    const theme = useTheme()

    const { isMsTeams } = useIsMsTeams()

    const copySuccessTooltipTimeout = useRef<NodeJS.Timeout | number>()
    const [isCopySuccessTooltipOpen, setIsCopySuccessTooltipOpen] =
        useState(false)

    const [isSharing, setIsSharing] = useState(false)

    const resetRouteParams = useMemo<
        ThriveResetParams | ThriveAudioResetParams
    >(() => ({ id: resetId }), [resetId])

    const resetSearchParams = useMemo<
        ThriveResetSearchParams | ThriveAudioResetSearchParams
    >(
        () => ({
            resetType: 'shared_reset' as AvoTypes.ResetTypeValueType
        }),
        []
    )

    const resetRoute = useMemo(() => {
        switch (resetType) {
            case ResetTypes.ThriveAudio:
                return Routes.ThriveAudioReset
            case ResetTypes.ThriveVideo:
                return Routes.ThriveReset
            default:
                return Routes.ThriveReset
        }
    }, [resetType])

    const contentFormatType =
        useMemo<AvoTypes.ContentFormatTypeValueType>(() => {
            switch (resetType) {
                case ResetTypes.ThriveAudio:
                    return 'audio'
                case ResetTypes.ThriveVideo:
                    return 'video'
                default:
                    return 'video'
            }
        }, [resetType])

    const { resetUrl: shareResetUrl, resetPath: shareResetPath } =
        useResetShareUrls<ThriveResetParams, ThriveResetSearchParams>(
            resetRouteParams,
            resetRoute,
            resetSearchParams
        )

    const clearCopySuccessTimeout = useCallback(() => {
        if (copySuccessTooltipTimeout.current) {
            clearTimeout(copySuccessTooltipTimeout.current)
            copySuccessTooltipTimeout.current = null
        }
    }, [])

    const handleShareClick = useCallback(async () => {
        if (isMsTeams && !isMsTeamsSharingDisabled) {
            setIsSharing(true)
            onShared?.()
            return
        }

        clearCopySuccessTimeout()

        await copyTextToClipboard(shareResetUrl, {
            container: contentContainerRef.current
        })

        setIsCopySuccessTooltipOpen(true)

        copySuccessTooltipTimeout.current = setTimeout(() => {
            setIsCopySuccessTooltipOpen(false)
            clearCopySuccessTimeout()
        }, autoCloseCopyTooltipTimeout)

        onShared?.()
    }, [
        isMsTeams,
        isMsTeamsSharingDisabled,
        clearCopySuccessTimeout,
        shareResetUrl,
        contentContainerRef,
        onShared
    ])

    const handleShareClose = useCallback(() => {
        setIsSharing(false)
    }, [])

    useEffect(() => {
        return () => {
            clearCopySuccessTimeout()
        }
    }, [clearCopySuccessTimeout])

    const ShareResetButtonType = useMemo<ComponentType<ButtonProps>>(
        () => ButtonComponent ?? Button,
        [ButtonComponent]
    )

    const ShareResetPopupTrackable = useMemo(
        () =>
            withShareResetPopupTracking(ShareResetPopup, {
                contentFormatType
            }),
        [contentFormatType]
    )

    return (
        <>
            <CopiedTooltipStyled
                open={isCopySuccessTooltipOpen}
                title={intl.formatMessage(messages.copied)}
                placement="top"
                sx={{ fontSize: theme.typography.body1.fontSize }}
            >
                <ShareResetButtonType onClick={handleShareClick} {...restProps}>
                    {children}
                </ShareResetButtonType>
            </CopiedTooltipStyled>
            {isSharing && (
                <ShareResetPopupTrackable
                    id={resetId}
                    title={resetName}
                    content={
                        <FormattedMessage
                            defaultMessage="Share this Reset with other Thrive users."
                            description="Share reset with other users description"
                        />
                    }
                    open={isSharing}
                    url={shareResetUrl}
                    path={shareResetPath}
                    onClose={handleShareClose}
                />
            )}
        </>
    )
}

export default React.memo(ShareResetButton) as typeof ShareResetButton
