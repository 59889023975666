const BASE = 1024
const KB_EXPONENT = 1
const MB_EXPONENT = 2

export function convertBytesToMB(bytes: number): number {
    if (bytes <= 0) {
        return 0
    }

    return bytes / Math.pow(BASE, MB_EXPONENT)
}

export function convertMBToBytes(mb: number) {
    if (mb <= 0) {
        return 0
    }

    return mb * Math.pow(BASE, MB_EXPONENT)
}
