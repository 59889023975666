import { FullAppFallback } from '@thriveglobal/thrive-web-errors'
import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import ErrorBoundaryWrapper from './components/fallbacks/ErrorBoundaryWrapper'
import Root from './root.component'

const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundary(error, info, props) {
        return (
            <ErrorBoundaryWrapper
                boundaryName="ResetAppContainer"
                boundaryType="MicroFrontend"
                errorOnMount={error}
                fallbackComponent={FullAppFallback}
            />
        )
    }
})

export const { bootstrap, mount, unmount } = lifecycles

export * from './shared'
