import { Box, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import ThriveSolidBackgroundCard from '../../elements/ThriveSolidBackgroundCard/ThriveSolidBackgroundCard'

export type ThriveQuoteCardProps = {
    id: string
    quote: string
    author: string
    hideContent?: boolean
    fullScreen: boolean
    textColor?: string
    backgroundColor?: string
}

function ThriveQuoteCard(props: ThriveQuoteCardProps) {
    const {
        id,
        quote,
        author,
        hideContent,
        fullScreen = false,
        textColor,
        backgroundColor
    } = props

    const intl = useIntl()

    return (
        <ThriveSolidBackgroundCard
            key={id}
            idx={id}
            fullScreen={fullScreen}
            dominantColor={backgroundColor}
        >
            <Typography component="h3">
                <Box padding={1} flexWrap="nowrap">
                    {!hideContent && (
                        <Typography
                            variant="h3"
                            component="div"
                            align="center"
                            color={textColor}
                            sx={{
                                fontSize: '2rem'
                            }}
                        >
                            "{quote}"
                        </Typography>
                    )}
                </Box>
                <Box padding={2}>
                    {!hideContent && (
                        <Typography
                            variant="h3"
                            component="div"
                            align="center"
                            color={textColor}
                            sx={{
                                fontSize: '2.5rem'
                            }}
                        >
                            - {author.toLocaleUpperCase(intl.locale)}
                        </Typography>
                    )}
                </Box>
            </Typography>
        </ThriveSolidBackgroundCard>
    )
}

export default React.memo(ThriveQuoteCard)
