import React from 'react'
import withAspectRatio from '../../elements/AspectRatio/withAspectRatio'
import ThriveBlurPhotoBackground from '../../elements/ThriveBlurPhotoBackground/ThriveBlurPhotoBackground'
import ThriveImage from './ThriveImage'

export type ThrivePhotoCardProps = {
    id: string
    src: string
    fullScreen: boolean
}

const ThriveBlurPhotoBackgroundWithAspectRatio = withAspectRatio(
    ThriveBlurPhotoBackground
)

function ThrivePhotoCard(props: ThrivePhotoCardProps) {
    const { id, src, fullScreen = false } = props
    const aspectRatio = 16 / 9

    return (
        <ThriveBlurPhotoBackgroundWithAspectRatio
            src={src}
            fullScreen={fullScreen}
            aspectRatio={aspectRatio}
        >
            <ThriveImage key={id} src={src} />
        </ThriveBlurPhotoBackgroundWithAspectRatio>
    )
}

export default React.memo(ThrivePhotoCard)
