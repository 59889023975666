import { useCallback, useEffect, useRef } from 'react'

function useTrackOnce<T extends (...args: any) => any = any>(
    track: (...args: Parameters<T>) => ReturnType<T>,
    resetToken: unknown = null
) {
    const calledRef = useRef(false)

    const trackOnce = useCallback(
        (...args: Parameters<T>): ReturnType<T> => {
            if (!calledRef.current) {
                calledRef.current = true
                return track(...args)
            }
        },
        [track]
    )

    useEffect(() => {
        if (resetToken) {
            calledRef.current = false
        }
    }, [resetToken])

    return trackOnce
}

export default useTrackOnce
