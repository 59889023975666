import { CombinedState, EnhancedStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook } from 'react-redux'
import {
    useAppDispatch as useCoreDispatch,
    useAppSelector as useCoreSelector,
    store as coreStore,
    SettingsState
} from '@thriveglobal/thrive-web-core'
import {
    ThriveResetsSliceActions,
    thriveResetsSliceName,
    ThriveResetsState
} from './thriveResets'
import {
    PersonalizedResetsSliceActions,
    personalizedResetsSliceName,
    PersonalizedResetsState
} from './personalizedResets'
import {
    ThriveAudioResetsSliceActions,
    thriveAudioResetsSliceName,
    ThriveAudioResetsState
} from './thriveAudioResets'
import { settingsSliceName } from './settings'

export type AppState = CombinedState<{
    [settingsSliceName]: SettingsState
    [thriveResetsSliceName]: ThriveResetsState
    [thriveAudioResetsSliceName]: ThriveAudioResetsState
    [personalizedResetsSliceName]: PersonalizedResetsState
}>

type CoreStore = typeof coreStore

export type AppStore = EnhancedStore<
    AppState,
    | ThriveResetsSliceActions
    | PersonalizedResetsSliceActions
    | ThriveAudioResetsSliceActions
> &
    CoreStore

export const useAppSelector =
    useCoreSelector as unknown as TypedUseSelectorHook<AppState>

export const useAppDispatch = useCoreDispatch as () => AppStore['dispatch']
