import {
    Backdrop,
    Box,
    Fade,
    Modal,
    Stack,
    useMediaQuery,
    useTheme
} from '@mui/material'
import { useSetMobileNavigationState } from '@thriveglobal/thrive-web-core'
import {
    MutableRefObject,
    SyntheticEvent,
    forwardRef,
    memo,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from 'react'
import {
    getHasVideoCompleted,
    resetCompletePercentage
} from '../../../components/elements/Player/withOnWatched'
import { defaultResetDurationInSeconds } from '../../../constants/limitations'
import { PersonalizedReset } from '../../../graphql/generated/autogenerated'
import useTemporaryAnimationToken from '../../../hooks/useTemporalAnimationToken'
import useTrackOnce from '../../../tracking/useTrackOnce'
import { ResetAsset } from '../../../types'
import AudioPlayer from '../../elements/AudioPlayer'
import {
    ClosePlayerPopupButton,
    InitialPlayButton,
    PlayerControls as PersonalizedResetPlayerControls,
    PlayerContentFullscreenLoading,
    commonAutoHideControlsTimeout,
    listenInterval,
    longAutoHideControlsTimeout,
    playerControlsZIndex,
    volumeLevelMin
} from '../../elements/CustomResetPlayer'
import { PlayStateAnimated } from '../../elements/PlayState'
import FrameSlide from '../FramesSlideshow/FrameSlide'
import FramesSlideshow from '../FramesSlideshow/FramesSlideshowNew'
import PersonalizedResetPlayerRef from '../PersonalizedReset/PersonalizedResetPlayerRef'
import ThriveBreathIndicatorAnimated from './ThriveBreathIndicator'
import ThriveInhaleExhaleIndicator from './ThriveInhaleExhaleIndicator'
import usePersonalizedResetPlayerVolume from './usePersonalizedResetPlayerVolume'
import { getPersonalizedResetAudioSegmentDuration } from './utils'

export type PersonalizedResetPlayerProps = {
    personalizedReset: PersonalizedReset
    open: boolean
    autoPlay?: boolean
    controls?: boolean
    showClose?: boolean
    onClose: () => void
    onEnd: () => void
    onTimeUpdate?: (time: number) => void
    onWatched?: () => void
}

function PersonalizedResetPlayer(
    props: PersonalizedResetPlayerProps,
    ref: MutableRefObject<PersonalizedResetPlayerRef>
): JSX.Element {
    const {
        personalizedReset,
        open,
        autoPlay,
        controls = true,
        showClose = true,
        onClose,
        onEnd,
        onTimeUpdate,
        onWatched
    } = props

    const [currentTime, setCurrentTime] = useState(0)
    const [playbackStartedOnce, setPlaybackStartedOnce] = useState(false)
    const [paused, setPaused] = useState(true)

    const {
        animationToken: playStateAnimationToken,
        updateAnimationToken: updatePlayStateAnimationToken
    } = useTemporaryAnimationToken()
    const [restartAnimationToken, setRestartAnimationToken] =
        useState<number>(null)
    const updateRestartAnimationToken = useCallback(() => {
        setRestartAnimationToken(Date.now())
    }, [])

    const [autoHideControlsTimeout, setAutoHideControlsTimeout] = useState(
        commonAutoHideControlsTimeout
    )
    const {
        animationToken: autoHideControlsAnimationToken,
        updateAnimationToken: updateAutoHideControlsAnimationToken
    } = useTemporaryAnimationToken(autoHideControlsTimeout)
    const handleControlsContainerMouseEnter = useCallback(() => {
        setAutoHideControlsTimeout(longAutoHideControlsTimeout)
    }, [])
    const handleControlsContainerMouseLeave = useCallback(() => {
        setAutoHideControlsTimeout(commonAutoHideControlsTimeout)
    }, [])

    const setMobileNavbarState = useSetMobileNavigationState()

    const theme = useTheme()
    const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

    const audioPlayerRef = useRef<HTMLAudioElement>(null)
    const audio = personalizedReset?.audio

    const frames = useMemo(
        () => (personalizedReset?.frames ?? []) as ResetAsset[],
        [personalizedReset?.frames]
    )

    const framesCount = personalizedReset?.frames?.length

    const audioStartTime = personalizedReset?.audioStartTime ?? 0
    const audioEndTime =
        personalizedReset?.audioEndTime ?? defaultResetDurationInSeconds

    const [volume, setVolume, { adjustedVolume }] =
        usePersonalizedResetPlayerVolume({
            time: Math.max(
                currentTime ? currentTime - audioStartTime ?? 0 : 0,
                0
            ),
            duration: Math.max(
                audioEndTime ? audioEndTime - audioStartTime ?? 0 : 0,
                0
            )
        })

    const audioDuration = useMemo<number>(
        () => getPersonalizedResetAudioSegmentDuration(personalizedReset),
        [personalizedReset]
    )

    const firstFrame = personalizedReset?.frames?.[0] as ResetAsset

    const resetAudio = useCallback(() => {
        const audioPlayerElement = audioPlayerRef.current
        if (audioPlayerElement) {
            audioPlayerElement.currentTime = 0
        }
    }, [])

    const play = useCallback(() => {
        const audioPlayerElement = audioPlayerRef.current

        audioPlayerElement?.play()

        setPaused(audioPlayerElement?.paused ?? true)
    }, [])

    const pause = useCallback(() => {
        const audioPlayerElement = audioPlayerRef.current

        audioPlayerElement?.pause()
        setPaused(audioPlayerElement?.paused ?? true)
    }, [])

    const muted = useMemo(() => {
        return volume <= volumeLevelMin
    }, [volume])

    const resetPlayback = useCallback(() => {
        resetAudio()
        setPlaybackStartedOnce(false)
        updateRestartAnimationToken()
    }, [updateRestartAnimationToken, resetAudio])

    // HACK: 1. autoPlay causing audio to keep playing by OS after modal is closed in Chrome on OSX, so trigger play manually once audio is ready
    // HACK: 2. onCanPlay is not supported by Safari, use onLoadedMetadata instead
    const handleLoadedMetadata = useCallback(() => {
        const audioPlayerElement = audioPlayerRef.current

        const isPaused = !!audioPlayerElement?.paused
        if (open && audio && isPaused && autoPlay) {
            if (audioPlayerElement && audioStartTime > 0) {
                audioPlayerElement.currentTime = audioStartTime
            }

            play()
        }
    }, [open, audio, autoPlay, audioStartTime, play])

    const handlePlayControlClick = useCallback(() => {
        play()
    }, [play])

    const handlePauseControlClick = useCallback(() => {
        pause()
    }, [pause])

    const handlePlayerContentClick = useCallback(
        (event: SyntheticEvent) => {
            event.stopPropagation()

            updatePlayStateAnimationToken()

            if (paused) {
                play()
            } else {
                pause()
            }
        },
        [play, paused, pause, updatePlayStateAnimationToken]
    )

    const handlePlayerClose = useCallback(
        (event: SyntheticEvent) => {
            event?.stopPropagation()
            setMobileNavbarState(true)

            onClose()
        },
        [onClose, setMobileNavbarState]
    )

    const handleControlsContainerClick = useCallback(
        (event: SyntheticEvent) => {
            event.stopPropagation()
        },
        []
    )

    const handleInitialPlayClick = useCallback(
        (event: SyntheticEvent) => {
            event.stopPropagation()

            const audioPlayerElement = audioPlayerRef.current
            if (audioPlayerElement && audioStartTime > 0) {
                audioPlayerElement.currentTime = audioStartTime
            }

            play()
        },
        [audioStartTime, play]
    )

    const handleEnded = useCallback(() => {
        pause()

        resetPlayback()

        setMobileNavbarState(true)

        onEnd()
    }, [pause, resetPlayback, setMobileNavbarState, onEnd])

    const handleVolumeChange = useCallback(
        (newVolume: number) => {
            setVolume(newVolume)
        },
        [setVolume]
    )

    const onWatchedOnce = useTrackOnce(onWatched, personalizedReset?.id)

    const handleListen = useCallback(
        (time: number) => {
            setCurrentTime(time)

            onTimeUpdate?.(time)

            const hasVideoCompleted = getHasVideoCompleted(
                resetCompletePercentage,
                time,
                audioEndTime
            )
            if (hasVideoCompleted) {
                onWatchedOnce?.()
            }

            if (time >= audioEndTime) {
                handleEnded()
            }
        },
        [audioEndTime, onTimeUpdate, onWatchedOnce, handleEnded]
    )

    const handlePlay = useCallback(() => {
        setPlaybackStartedOnce(true)
    }, [])

    const handlePlayerContentMouseMove = useCallback(() => {
        updateAutoHideControlsAnimationToken()
    }, [updateAutoHideControlsAnimationToken])

    const handleFocusControlsContainer = useCallback(() => {
        setAutoHideControlsTimeout(longAutoHideControlsTimeout)
        updateAutoHideControlsAnimationToken()
    }, [updateAutoHideControlsAnimationToken])

    useEffect(() => {
        if (open) {
            setMobileNavbarState(false)
        }

        return function cleanup() {
            setMobileNavbarState(true)
        }
    }, [open, setMobileNavbarState])

    useImperativeHandle(
        ref,
        (): PersonalizedResetPlayerRef => {
            return {
                play,
                pause,
                volume,
                setVolume: handleVolumeChange,
                elementRef: audioPlayerRef
            }
        },
        [audioPlayerRef, play, pause, volume, handleVolumeChange]
    )

    return (
        <Modal
            open={open}
            onClose={handlePlayerClose}
            closeAfterTransition={true}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
            color="primary"
        >
            <Fade in={open} onExited={onClose}>
                <Box
                    display="flex"
                    flexDirection="column"
                    data-testid="PersonalizedResetPlayerContainer"
                >
                    <Box
                        position="absolute"
                        width="100%"
                        height="100%"
                        bgcolor="#000000"
                        data-testid="PersonalizedResetPlayerContent"
                        role="button"
                        {...(controls && {
                            onClick: handlePlayerContentClick,
                            onMouseMove: handlePlayerContentMouseMove
                        })}
                    >
                        {personalizedReset ? (
                            <>
                                {!playbackStartedOnce && firstFrame && (
                                    <FrameSlide frame={firstFrame} />
                                )}

                                <FramesSlideshow
                                    frames={frames}
                                    framesCount={framesCount}
                                    audioDuration={audioDuration}
                                    paused={paused}
                                    restartAnimationToken={
                                        restartAnimationToken
                                    }
                                />

                                <ThriveInhaleExhaleIndicator
                                    paused={paused}
                                    restartAnimationToken={
                                        restartAnimationToken
                                    }
                                />

                                <ThriveBreathIndicatorAnimated
                                    paused={paused}
                                    restartAnimationToken={
                                        restartAnimationToken
                                    }
                                />

                                <Fade
                                    in={
                                        Boolean(
                                            autoHideControlsAnimationToken
                                        ) && controls
                                    }
                                    timeout={{ exit: 150, enter: 250 }}
                                >
                                    <Box
                                        sx={[
                                            {
                                                position: 'absolute',
                                                bottom: 0,
                                                width: '100%',
                                                px: theme.spacing(2.75),
                                                pb: theme.spacing(1.375),
                                                zIndex: playerControlsZIndex
                                            },
                                            isMobileView && {
                                                px: 0,
                                                pb: 0
                                            }
                                        ]}
                                        style={{ visibility: 'visible' }}
                                        onClick={handleControlsContainerClick}
                                        onMouseEnter={
                                            handleControlsContainerMouseEnter
                                        }
                                        onMouseLeave={
                                            handleControlsContainerMouseLeave
                                        }
                                        onFocus={handleFocusControlsContainer}
                                        data-testid="PersonalizedResetPlayerControlsContainer"
                                    >
                                        <PersonalizedResetPlayerControls
                                            onPlay={handlePlayControlClick}
                                            onPause={handlePauseControlClick}
                                            isPlaying={!paused}
                                            onVolumeChange={handleVolumeChange}
                                            volume={volume}
                                            currentTime={currentTime}
                                            duration={audioDuration}
                                        />
                                    </Box>
                                </Fade>

                                {controls && (
                                    <Stack
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            zIndex: playerControlsZIndex
                                        }}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        {!playbackStartedOnce && (
                                            <InitialPlayButton
                                                onClick={handleInitialPlayClick}
                                            />
                                        )}
                                        <PlayStateAnimated
                                            paused={paused}
                                            animationToken={
                                                playStateAnimationToken
                                            }
                                        />
                                    </Stack>
                                )}

                                {audio && (
                                    <div data-testid="PersonalizedResetAudio">
                                        <AudioPlayer
                                            ref={audioPlayerRef}
                                            autoPlay={autoPlay}
                                            src={audio.url}
                                            onLoadedMetadata={
                                                handleLoadedMetadata
                                            }
                                            onPlay={handlePlay}
                                            volume={adjustedVolume}
                                            muted={muted}
                                            controlsList={'nodownload'}
                                            onEnded={handleEnded}
                                            onListen={handleListen}
                                            listenInterval={listenInterval}
                                        />
                                    </div>
                                )}
                            </>
                        ) : (
                            <PlayerContentFullscreenLoading />
                        )}

                        {controls && showClose && (
                            <ClosePlayerPopupButton
                                onClick={handlePlayerClose}
                                sx={{ top: 0, zIndex: playerControlsZIndex }}
                            />
                        )}
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}

export default memo(forwardRef(PersonalizedResetPlayer))
