import { Box, Fade } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { PropsWithChildren } from 'react'

export type PostResetScreenContainerProps = PropsWithChildren<{
    open: boolean
    overlayZIndex: number
}>

function PostResetScreenContainer(props: PostResetScreenContainerProps) {
    const { children, open, overlayZIndex } = props
    const { spacing } = useTheme()

    return (
        <Fade in={open}>
            <Box
                sx={{
                    position: 'absolute',
                    top: spacing(10),
                    left: 0,
                    width: '100%',
                    height: `calc(100% - ${spacing(2 * 10 + 5)})`,
                    zIndex: overlayZIndex,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                aria-hidden={!open}
                data-testid="PostResetScreenContainer"
            >
                {children}
            </Box>
        </Fade>
    )
}

export default React.memo(PostResetScreenContainer)
