import { useCallback, useEffect, useMemo, useState } from 'react'
import isNull from 'lodash/isNull'
import head from 'lodash/head'
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit'
import { ErrorWithCode } from '../../types'
import { ErrorCodes } from '../../constants/errorCodes'
import {
    UseGetItemQuery,
    UseGetItemQueryMapper,
    UseSingleResetOptions,
    UseSingleResetResult
} from './types'
import { AppState, useAppDispatch, useAppSelector } from '../../slices'

export type UseSingleReset<T, TVariables> = (
    options?: UseSingleResetOptions<T, TVariables>
) => UseSingleResetResult<T>

export type CreateUseSingleResetHookOptions<TItem, TQuery, TVariables> = {
    useGetSingleResetLazyQuery: UseGetItemQuery<TQuery, TVariables>
    selectItemsByIds: (state: AppState, ids: string[]) => TItem[]
    upsertManyItems?: ActionCreatorWithOptionalPayload<TItem[], string>
    mapQueryResult: UseGetItemQueryMapper<TItem, TQuery>
}

export function createUseSingleResetHook<
    TItem extends { id: string },
    TQuery,
    TVariables
>({
    useGetSingleResetLazyQuery,
    selectItemsByIds,
    upsertManyItems,
    mapQueryResult
}: CreateUseSingleResetHookOptions<TItem, TQuery, TVariables>): UseSingleReset<
    TItem,
    TVariables
> {
    return function useSingleReset({ id, variables } = {}) {
        const [requestedItemId, setRequestedItemId] = useState<string>(id)

        useEffect(() => {
            setRequestedItemId(id)
        }, [id])

        const dispatch = useAppDispatch()

        const [isLoading, setIsLoading] = useState(false)
        const [error, setError] = useState<Error>(null)

        const takeByIdOrFirst = useCallback(
            (collection: TItem[], resetId?: string) => {
                return resetId
                    ? collection.find((item) => item.id === resetId)
                    : head(collection)
            },
            []
        )

        const existingReset = useAppSelector((state) => {
            const itemsByIds = selectItemsByIds(state, [requestedItemId])

            return head(itemsByIds)
        })

        const [getReset] = useGetSingleResetLazyQuery({
            variables
        })

        const isLoaded = !!existingReset

        useEffect(() => {
            if (isLoaded || isNull(requestedItemId)) {
                return () => {
                    // noop
                }
            }

            let isCancelled = false

            async function process() {
                setIsLoading(true)
                setError(null)

                try {
                    const { data, error: fetchError } = await getReset()

                    if (isCancelled) {
                        return
                    }

                    const { items: requestedItems } = mapQueryResult(data)
                    const item = takeByIdOrFirst(
                        requestedItems,
                        requestedItemId
                    )

                    if (item) {
                        setRequestedItemId(item.id)

                        dispatch(upsertManyItems(requestedItems))
                    } else if (fetchError) {
                        setError(fetchError)
                    } else {
                        setError(
                            new ErrorWithCode(
                                'Reset not found',
                                ErrorCodes.NotFound
                            )
                        )
                    }
                } catch (e) {
                    setError(e)
                } finally {
                    setIsLoading(false)
                }
            }

            process()

            return () => {
                isCancelled = true
            }
        }, [getReset, isLoaded, takeByIdOrFirst, requestedItemId, dispatch])

        return useMemo(
            () => ({ reset: existingReset, isLoading, error }),
            [existingReset, isLoading, error]
        )
    }
}
