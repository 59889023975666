import { lazy } from 'react'
import { withPageFallback } from '../../../components/fallbacks/PageFallback'

export const ThriveRandomReset = lazy(() => import('./ThriveRandomReset'))

const ThriveRandomResetWithFallback = withPageFallback(ThriveRandomReset, {
    pageName: 'ThriveRandomResetPage'
})

export default ThriveRandomResetWithFallback
