import React from 'react'
import FrameSwitchAnimation from './FrameSwitchAnimationNew'
import FrameSlide from './FrameSlide'
import { ResetAsset } from '../../../types'

export type FramesSlideshowProps = {
    frames: ResetAsset[]
    framesCount: number
    audioDuration: number
    paused?: boolean
    restartAnimationToken?: number
}

function FramesSlideshow(props: FramesSlideshowProps) {
    const {
        frames,
        framesCount = 1,
        audioDuration,
        paused,
        restartAnimationToken
    } = props

    return (
        <>
            {frames?.map((frame, index) => {
                return (
                    <FrameSwitchAnimation
                        key={index}
                        index={index}
                        framesCount={framesCount}
                        audioDuration={audioDuration}
                        paused={paused}
                        restartAnimationToken={restartAnimationToken}
                    >
                        <FrameSlide frame={frame} />
                    </FrameSwitchAnimation>
                )
            })}
        </>
    )
}

export default React.memo(FramesSlideshow)
