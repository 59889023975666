import { lazy } from 'react'
import { withPageFallback } from '../../components/fallbacks/PageFallback'

export const ThriveReset = lazy(() => import('./ThriveReset'))

const ThriveResetWithFallback = withPageFallback(ThriveReset, {
    pageName: 'ThriveResetPage'
})

export default ThriveResetWithFallback
