import { createSelector, CombinedState } from '@reduxjs/toolkit'
import {
    SettingsState,
    settingsSlice,
    WebApplicationLocale
} from '@thriveglobal/thrive-web-core'

export const settingsSliceName = settingsSlice.name

export type RootStateWithSettingsState = CombinedState<{
    [settingsSliceName]: SettingsState
}>

export const selectSettingsState = (rootState: RootStateWithSettingsState) => {
    return rootState[settingsSliceName]
}

export const selectUserLocale = createSelector(
    (state) => selectSettingsState(state),
    (settingsState) => settingsState.locale
) as (state: RootStateWithSettingsState) => WebApplicationLocale
