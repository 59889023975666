import React, { useMemo } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

function PlayIcon(props: SvgIconProps) {
    const { sx, ...restProps } = props

    const iconStyles: SvgIconProps['sx'] = useMemo(() => {
        return sx ?? {}
    }, [sx])

    return (
        <SvgIcon
            viewBox="0 0 48 48"
            width="48"
            height="48"
            fill="#FFFFFF"
            data-testid="CFPlayIcon"
            sx={{
                width: '4.8rem',
                height: '4.8rem',
                ...iconStyles
            }}
            {...restProps}
        >
            <path d="M14 15.06V33a2 2 0 0 0 2.93 1.77l17.09-8.97a2 2 0 0 0 0-3.54L16.93 13.3A2 2 0 0 0 14 15.06z"></path>
        </SvgIcon>
    )
}

export default React.memo(PlayIcon)
