import React, { PropsWithChildren } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    SxProps,
    Theme
} from '@mui/material'
import { defineMessages, useIntl } from 'react-intl'
import {
    useTheme,
    CoreTypography,
    IconButton,
    LeafIcon
} from '@thriveglobal/thrive-web-leafkit'

export type InformationPopupProps = PropsWithChildren<{
    open: boolean
    title: string
    closeButtonText?: string
    onClose: () => void
    dialogSx?: SxProps<Theme>
}>

const messages = defineMessages({
    close: {
        defaultMessage: 'Close',
        description: 'Information popup Close button text'
    },
    closeAriaLabel: {
        defaultMessage: 'Close',
        description: 'Close information popup button aria label'
    }
})

function InformationPopup(props: InformationPopupProps) {
    const { open, title, children, closeButtonText, onClose, dialogSx } = props

    const theme = useTheme()
    const intl = useIntl()

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="information-dialog-title"
            aria-describedby="information-dialog-description"
            fullWidth
            maxWidth="sm"
            sx={dialogSx}
            data-testid="InformationPopup"
        >
            <DialogTitle
                sx={{
                    px: theme.spacing(4),
                    pt: theme.spacing(4),
                    pb: theme.spacing(0.5),
                    position: 'relative'
                }}
            >
                <CoreTypography
                    id="information-dialog-title"
                    variant="h4"
                    component="div"
                    sx={{ pt: theme.spacing(4), pr: theme.spacing(4) }}
                >
                    {title}
                </CoreTypography>

                <IconButton
                    aria-label={intl.formatMessage(messages.closeAriaLabel)}
                    onClick={onClose}
                    color="secondary"
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: theme.spacing(4),
                        top: theme.spacing(4)
                    }}
                >
                    <LeafIcon icon={'xmark'} />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ px: theme.spacing(4) }}>
                <DialogContentText id="information-dialog-description">
                    <CoreTypography variant="body1" color="text.primary">
                        {children}
                    </CoreTypography>
                </DialogContentText>
            </DialogContent>
            <DialogActions
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    px: theme.spacing(4),
                    pb: theme.spacing(4)
                }}
            >
                <Button
                    variant="contained"
                    color="secondary"
                    autoFocus
                    onClick={onClose}
                    data-testid="CloseButton"
                >
                    <CoreTypography customVariant="buttonNormal">
                        {closeButtonText ?? intl.formatMessage(messages.close)}
                    </CoreTypography>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default React.memo(InformationPopup)
