import { ComponentType } from 'react'
import { FavoriteThriveAudioResetsProvider } from './FavoriteThriveAudioResetsProvider'

export default function withFavoriteThriveAudioResetsProvider<Props>(
    Component: ComponentType<Props>
) {
    return (props: Props): JSX.Element => {
        return (
            <FavoriteThriveAudioResetsProvider>
                <Component {...props} />
            </FavoriteThriveAudioResetsProvider>
        )
    }
}
