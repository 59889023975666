import React, { useMemo } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

function VolumeMutedIcon(props: SvgIconProps) {
    const { sx, ...restProps } = props

    const iconSx = useMemo(() => {
        return {
            height: '4.8rem',
            width: '4.8rem',
            ...(sx ?? {})
        } as SvgIconProps['sx']
    }, [sx])

    return (
        <SvgIcon
            viewBox="0 0 512 512"
            fill="currentColor"
            sx={iconSx}
            {...restProps}
        >
            <path d="M215.03 71.05 126.06 160H24c-13.26 0-24 10.74-24 24v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c15.03 15.03 40.97 4.47 40.97-16.97V88.02c0-21.46-25.96-31.98-40.97-16.97zM461.64 256l45.64-45.64c6.3-6.3 6.3-16.52 0-22.82l-22.82-22.82c-6.3-6.3-16.52-6.3-22.82 0L416 210.36l-45.64-45.64c-6.3-6.3-16.52-6.3-22.82 0l-22.82 22.82c-6.3 6.3-6.3 16.52 0 22.82L370.36 256l-45.63 45.63c-6.3 6.3-6.3 16.52 0 22.82l22.82 22.82c6.3 6.3 16.52 6.3 22.82 0L416 301.64l45.64 45.64c6.3 6.3 16.52 6.3 22.82 0l22.82-22.82c6.3-6.3 6.3-16.52 0-22.82L461.64 256z"></path>
        </SvgIcon>
    )
}

export default React.memo(VolumeMutedIcon)
