import { defineMessages } from 'react-intl'

export const pageTitleMessages = defineMessages({
    pageTitleDefault: {
        defaultMessage: 'Thrive - Reset',
        description: 'Thrive personal Reset player default page title'
    },
    pageTitle: {
        defaultMessage: 'Thrive - Reset - {resetName}',
        description: 'Thrive personal Reset player page title'
    }
})
