import { RefCallback, useCallback, useRef } from 'react'
import { restartAnimation } from './utils'

function useRestartAnimations() {
    const animationsRef = useRef<HTMLElement[]>([])

    const addElementWithAnimation: RefCallback<HTMLElement> = useCallback(
        (element: HTMLElement) => {
            animationsRef.current.push(element)
        },
        []
    )

    const restartAnimationsOnElements = useCallback(() => {
        animationsRef.current.forEach(restartAnimation)
    }, [])

    return {
        addElementWithAnimation,
        restartAnimationsOnElements
    }
}

export default useRestartAnimations
