import React, {
    ComponentType,
    MutableRefObject,
    useCallback,
    useRef
} from 'react'
import { StreamPlayerApi } from '@cloudflare/stream-react'
import clamp from 'lodash/clamp'
import { getProgressInPercent } from '../../../hooks/useAudioProgress/utils'
import useTrackOnce from '../../../tracking/useTrackOnce'
import { PlayerProps } from './types'

export interface PlayerWithOnWatchedProps extends PlayerProps {
    // Callback will trigger once the user reaches watchedPercentage
    onWatched?: () => void
    // Percentage value in [0, 100] range
    watchedPercentage?: number
}

export const maxPercentage = 100
export const minPercentage = 0
export const resetCompletePercentage = 80

export function getHasVideoCompleted(
    watchedCompletionPercentage: number,
    currentTime: number,
    duration: number
) {
    const progress = getProgressInPercent(currentTime, duration)
    return progress >= watchedCompletionPercentage
}

function withOnWatched<T extends PlayerProps>(
    PlayerComponent: ComponentType<PlayerProps>
) {
    return React.memo(
        React.forwardRef<StreamPlayerApi, PlayerWithOnWatchedProps & T>(
            function PlayerWithOnWatched(
                props,
                ref: MutableRefObject<StreamPlayerApi>
            ) {
                const {
                    watchedPercentage = resetCompletePercentage,
                    onTimeUpdate,
                    onWatched,
                    ...restProps
                } = props
                const watchedPercentageNormalized = clamp(
                    watchedPercentage,
                    minPercentage,
                    maxPercentage
                )

                const innerPlayerApiRef = useRef<StreamPlayerApi>(null)
                const playerApiRef = ref ?? innerPlayerApiRef

                const onWatchedOnce = useTrackOnce(onWatched, props.src)

                const handleTimeUpdate: PlayerProps['onTimeUpdate'] =
                    useCallback(
                        (...args) => {
                            const { currentTime = 0, duration = 0 } =
                                playerApiRef?.current ?? {}

                            const hasVideoCompleted = getHasVideoCompleted(
                                resetCompletePercentage,
                                currentTime,
                                duration
                            )
                            if (
                                Boolean(playerApiRef.current) &&
                                hasVideoCompleted
                            ) {
                                onWatchedOnce()
                            }

                            onTimeUpdate?.call(null, ...args)
                        },
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                        [
                            onTimeUpdate,
                            onWatchedOnce,
                            watchedPercentageNormalized
                        ] // Ignore playerApiRef
                    )

                return (
                    <PlayerComponent
                        {...restProps}
                        ref={playerApiRef}
                        onTimeUpdate={handleTimeUpdate}
                    />
                )
            }
        )
    )
}

export default withOnWatched
