import { MutableRefObject, forwardRef, memo, useEffect, useMemo } from 'react'
import { ErrorCodes } from '../../../constants/errorCodes'
import { PersonalizedReset } from '../../../graphql/generated/autogenerated'
import withPersonalizedResetPlayerTracking from '../../../tracking/withPersonalizedResetPlayerTracking'
import { isErrorWithCode } from '../../../utils/errors'
import PersonalizedResetNotFoundPopup from '../../elements/PersonalizedResetNotFoundPopup'
import { PersonalizedResetPlayerNew } from '../PersonalizedResetPlayer'
import { useSinglePersonalizedReset } from './useSinglePersonalizedReset'
import PersonalizedResetPlayerRef from './PersonalizedResetPlayerRef'

const TrackablePersonalizedResetPlayerNew = withPersonalizedResetPlayerTracking(
    PersonalizedResetPlayerNew
)

export type PersonalizedResetProps = {
    id: string
    autoPlay?: boolean
    utmSource?: string
    controls?: boolean
    showClose?: boolean
    onClose: () => void
    onEnded: () => void
    onWatched?: () => void
    onError?: (error: Error) => void
    onResetLoaded?: (reset: PersonalizedReset) => void
}

function PersonalizedResetWrapper(
    props: PersonalizedResetProps,
    ref: MutableRefObject<PersonalizedResetPlayerRef>
): JSX.Element {
    const {
        id,
        autoPlay,
        utmSource,
        controls,
        showClose,
        onClose,
        onEnded,
        onError,
        onResetLoaded,
        onWatched
    } = props

    const { reset: personalizedReset, error } = useSinglePersonalizedReset({
        id,
        variables: {
            ids: [id]
        }
    })

    useEffect(() => {
        if (personalizedReset) {
            onResetLoaded?.(personalizedReset)
        }
    }, [personalizedReset, onResetLoaded])

    const isResetNotFound = useMemo(
        () => isErrorWithCode(error, ErrorCodes.NotFound),
        [error]
    )

    useEffect(() => {
        if (!!error && !isErrorWithCode(error, ErrorCodes.NotFound)) {
            onError(error)
        }
    }, [error, onError])

    return (
        <>
            <TrackablePersonalizedResetPlayerNew
                open={true}
                personalizedReset={personalizedReset}
                autoPlay={autoPlay}
                utmSource={utmSource}
                controls={controls}
                showClose={showClose}
                ref={ref}
                onClose={onClose}
                onEnd={onEnded}
                onWatched={onWatched}
            />
            {isResetNotFound && (
                <PersonalizedResetNotFoundPopup onClose={onClose} />
            )}
        </>
    )
}

export default memo(forwardRef(PersonalizedResetWrapper))
