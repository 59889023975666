import { MAX_VOLUME, MIN_VOLUME } from '@thriveglobal/thrive-web-core'

export const playerControlsZIndex = 200

// NOTE: trigger time for onListen callback
export const listenInterval = 100 // ms

export const commonAutoHideControlsTimeout = 2000
export const longAutoHideControlsTimeout = 3500

export const volumeLevelMin = MIN_VOLUME
export const volumeLevelMax = MAX_VOLUME
export const volumeChangeStep = 0.01
