export const appendSearchParams = (
    pathOrUrl: string,
    searchParams: string
): string => {
    if (!searchParams) {
        return pathOrUrl
    }

    const separator = pathOrUrl.includes('?') ? '&' : '?'

    return `${pathOrUrl}${separator}${searchParams}`
}
