import { ComponentProps } from 'react'
import { ThriveApplication } from '@thriveglobal/thrive-web-core'

type SentryConfig = ComponentProps<typeof ThriveApplication>['sentryOptions']

export const sentryOptions: SentryConfig = {
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENV,
    release: process.env.GIT_REF,
    ignoreErrors: ['ResizeObserver loop limit exceeded']
}
