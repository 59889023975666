import { useSetPageTitle } from '@thriveglobal/thrive-web-core'
import {
    MutableRefObject,
    RefObject,
    forwardRef,
    memo,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState
} from 'react'
import { useIntl } from 'react-intl'
import {
    PersonalizedReset,
    useFirePersonalResetWatchedEventMutation
} from '../../../graphql/generated/autogenerated'
import PersonalizedResetContent from './PersonalizedReset'
import { pageTitleMessages as messages } from './constants'
import useHTMLAudioEvent from './useHTMLAudioElementEvent'
import withPersonalizedResetPlayerTracking from '../../../tracking/withPersonalizedResetPlayerTracking'
import { getExampleReset } from '../../../utils/utils'
import { PersonalizedResetPlayerNew } from '../PersonalizedResetPlayer'
import PersonalizedResetPlayerRef from './PersonalizedResetPlayerRef'

const TrackablePersonalizedResetPlayerNew = withPersonalizedResetPlayerTracking(
    PersonalizedResetPlayerNew
)

export type PersonalizedResetPopupPlayerProps = {
    id: string
    autoCloseWhenEnded?: boolean
    autoPlay?: boolean
    controls?: boolean
    showClose?: boolean
    utmSource?: string
    onClose: () => void
    onPause?: EventListener
    onPlay?: EventListener
    onVolumeChange?: EventListener
    onCanPlayThrough?: EventListener
    onLoadedMetadata?: EventListener
    onLoadedData?: EventListener
    onEnded?: () => void
}

function PersonalizedResetPopupPlayer(
    props: PersonalizedResetPopupPlayerProps,
    ref: MutableRefObject<PersonalizedResetPlayerRef>
): JSX.Element {
    const intl = useIntl()
    const {
        id,
        controls,
        showClose,
        onClose,
        utmSource,
        onPause,
        onPlay,
        onVolumeChange,
        onCanPlayThrough,
        onLoadedMetadata,
        onLoadedData,
        onEnded
    } = props

    const [pageTitle, setPageTitle] = useState<string>(
        intl.formatMessage(messages.pageTitleDefault)
    )

    useSetPageTitle(pageTitle)

    const autoCloseWhenEnded = props?.autoCloseWhenEnded ?? false
    const autoPlay = props?.autoPlay ?? false

    useEffect(() => {
        if (!id) {
            onClose()
        }
    }, [id, onClose])

    const handleClose = useCallback(() => {
        onClose()
    }, [onClose])

    const handleEnded = useCallback(() => {
        onEnded?.()

        if (autoCloseWhenEnded) {
            onClose()
        }
    }, [onClose, onEnded, autoCloseWhenEnded])

    const handleError = useCallback(() => {
        onClose()
    }, [onClose])

    const handleResetLoaded = useCallback(
        (reset: PersonalizedReset) => {
            const title = intl.formatMessage(messages.pageTitle, {
                resetName: reset.name
            })

            setPageTitle(title)
        },
        [intl]
    )

    const [firePersonalResetWatchedEvent] =
        useFirePersonalResetWatchedEventMutation()
    const handleWatched = useCallback(async () => {
        await firePersonalResetWatchedEvent({
            variables: {
                resetId: id
            }
        })
    }, [firePersonalResetWatchedEvent, id])

    const { bindEvent: bindPauseEvent, unbindEvent: unbindPauseEvent } =
        useHTMLAudioEvent('pause', ref?.current?.elementRef, onPause)
    const { bindEvent: bindPlayEvent, unbindEvent: unbindPlayEvent } =
        useHTMLAudioEvent('play', ref?.current?.elementRef, onPlay)
    const {
        bindEvent: bindCanPlayThroughEvent,
        unbindEvent: unbindCanPlayThroughEvent
    } = useHTMLAudioEvent(
        'canplaythrough',
        ref?.current?.elementRef,
        onCanPlayThrough
    )
    const {
        bindEvent: bindLoadedMetadataEvent,
        unbindEvent: unbindLoadedMetadataEvent
    } = useHTMLAudioEvent(
        'loadedmetadata',
        ref?.current?.elementRef,
        onLoadedMetadata
    )
    const {
        bindEvent: bindLoadedDataEvent,
        unbindEvent: unbindLoadedDataEvent
    } = useHTMLAudioEvent('loadeddata', ref?.current?.elementRef, onLoadedData)
    const {
        bindEvent: bindVolumeChangeEvent,
        unbindEvent: unbindVolumeChangeEvent
    } = useHTMLAudioEvent(
        'volumechange',
        ref?.current?.elementRef,
        onVolumeChange
    )

    useLayoutEffect(() => {
        if (!ref?.current?.elementRef?.current) {
            return
        }

        bindPauseEvent(ref.current.elementRef.current, onPause)
        bindPlayEvent(ref.current.elementRef.current, onPlay)
        bindVolumeChangeEvent(ref.current.elementRef.current, onVolumeChange)
        bindCanPlayThroughEvent(
            ref.current.elementRef.current,
            onCanPlayThrough
        )
        bindLoadedMetadataEvent(
            ref.current.elementRef.current,
            onLoadedMetadata
        )
        bindLoadedDataEvent(ref.current.elementRef.current, onLoadedData)

        return () => {
            unbindPauseEvent(ref.current.elementRef.current)
            unbindPlayEvent(ref.current.elementRef.current)
            unbindVolumeChangeEvent(ref.current.elementRef.current)
            unbindCanPlayThroughEvent(ref.current.elementRef.current)
            unbindLoadedMetadataEvent(ref.current.elementRef.current)
            unbindLoadedDataEvent(ref.current.elementRef.current)
        }
    }, [ref?.current])

    const exampleReset = useMemo(() => getExampleReset(), [])
    const isExampleReset = useMemo(
        () => id === exampleReset.id,
        [exampleReset, id]
    )

    if (isExampleReset) {
        return (
            <TrackablePersonalizedResetPlayerNew
                open={true}
                personalizedReset={exampleReset}
                autoPlay={autoPlay}
                utmSource={utmSource}
                onClose={handleClose}
                onEnd={handleEnded}
                onWatched={handleWatched}
            />
        )
    }

    return (
        <PersonalizedResetContent
            id={id}
            ref={ref}
            controls={controls}
            showClose={showClose}
            autoPlay={autoPlay}
            utmSource={utmSource}
            onClose={handleClose}
            onEnded={handleEnded}
            onError={handleError}
            onWatched={handleWatched}
            onResetLoaded={handleResetLoaded}
        />
    )
}

export default memo(forwardRef(PersonalizedResetPopupPlayer))
