import { useCallback, useMemo } from 'react'
import unionBy from 'lodash/unionBy'
import {
    PersonalizedReset,
    useSharePersonalizedResetMutation,
    useUnsharePersonalizedResetMutation
} from '../../../graphql/generated/autogenerated'
import { useAppDispatch } from '../../../slices'
import { upsertOnePersonalizedReset } from '../../../slices/personalizedResets'

export type UseSharePersonalizedResetResult = {
    isLoading: boolean
    error?: string
    sharePersonalizedReset: (reset: PersonalizedReset) => Promise<string>
    unsharePersonalizedReset: (reset: PersonalizedReset) => Promise<boolean>
}

function useSharePersonalizedReset(): UseSharePersonalizedResetResult {
    const dispatch = useAppDispatch()

    const [share, { loading: isSharing, error: sharingError }] =
        useSharePersonalizedResetMutation()
    const [unshare, { loading: isUnsharing, error: unsharingError }] =
        useUnsharePersonalizedResetMutation()

    const isLoading = isSharing || isUnsharing
    const error = (sharingError || unsharingError)?.message

    const handleShareReset = useCallback(
        async (reset: PersonalizedReset) => {
            const { data } = await share({
                variables: {
                    resetId: reset.id
                },
                update: (cache, result) => {
                    if (!result?.data?.reset?.personalized?.share?.id) {
                        return
                    }

                    const objectId = cache.identify(reset)
                    if (!objectId) {
                        return
                    }

                    cache.modify({
                        id: objectId,
                        fields: {
                            shared: (previous: PersonalizedReset['shared']) =>
                                unionBy(
                                    previous,
                                    [result.data.reset.personalized.share],
                                    (item) => item.id
                                )
                        }
                    })
                }
            })

            const sharedId = data?.reset?.personalized?.share?.id
            if (sharedId) {
                const updatedReset: PersonalizedReset = {
                    ...reset,
                    shared: unionBy(
                        reset.shared,
                        [data.reset.personalized.share],
                        (item) => item.id
                    )
                }
                dispatch(upsertOnePersonalizedReset(updatedReset))
            }

            return sharedId
        },
        [share, dispatch]
    )

    const handleUnshareReset = useCallback(
        async (reset: PersonalizedReset) => {
            const [shared] = reset.shared

            const { data } = await unshare({
                variables: {
                    sharedId: shared.id
                },
                update: (cache, result) => {
                    if (!result?.data?.reset?.personalized?.unshare) {
                        return
                    }

                    const objectId = cache.identify(reset)
                    if (!objectId) {
                        return
                    }

                    cache.modify({
                        id: objectId,
                        fields: {
                            shared: (previous: PersonalizedReset['shared']) =>
                                previous?.filter(
                                    (item) => item.id !== shared.id
                                )
                        }
                    })
                }
            })

            const isUnshared = !!data?.reset?.personalized?.unshare
            if (isUnshared) {
                const updatedReset: PersonalizedReset = {
                    ...reset,
                    shared: reset.shared?.filter(
                        (item) => item.id !== shared.id
                    )
                }

                dispatch(upsertOnePersonalizedReset(updatedReset))
            }

            return isUnshared
        },
        [unshare, dispatch]
    )

    return useMemo(
        () => ({
            isLoading,
            error,
            sharePersonalizedReset: handleShareReset,
            unsharePersonalizedReset: handleUnshareReset
        }),
        [isLoading, error, handleShareReset, handleUnshareReset]
    )
}

export default useSharePersonalizedReset
