import { lazy } from 'react'
import { withPageFallback } from '../../components/fallbacks/PageFallback'

export const ThriveAudioReset = lazy(() => import('./ThriveAudioReset'))

const ThriveAudioResetWithFallback = withPageFallback(ThriveAudioReset, {
    pageName: 'ThriveAudioResetPage'
})

export default ThriveAudioResetWithFallback
