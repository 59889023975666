import { alpha, Card, Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { AvoTypes } from '@thriveglobal/thrive-web-tracking'
import React, { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { ThriveReset } from '../../../../graphql/generated/autogenerated'
import withPostThriveResetActionsTracking from '../../../../tracking/withPostThriveResetActionsTracking'
import { PostResetNextResetPreview } from '../../../elements/PostResetScreen'
import PostThriveResetActions from './PostThriveResetActions'

const TrackablePostThriveResetActions = withPostThriveResetActionsTracking(
    PostThriveResetActions
)

export type PostThriveResetProps = {
    isOpen: boolean
    thriveReset: ThriveReset
    utmSource?: string
    resetType?: AvoTypes.ResetTypeValueType
    onNext?: (nextThriveReset: ThriveReset, isAutoPlay?: boolean) => void
    onReplay?: () => void
    nextThriveReset: ThriveReset
    onAutoPlayToggle?: (thriveReset: ThriveReset, isAutoplay: boolean) => void
}

function PostThriveReset(props: PostThriveResetProps): JSX.Element {
    const {
        isOpen,
        thriveReset,
        utmSource,
        onNext,
        onReplay,
        nextThriveReset,
        resetType,
        onAutoPlayToggle
    } = props

    const theme = useTheme()
    const isMobileView = useMediaQuery(theme.breakpoints.down('lg'))
    const isMobileLandscape = useMediaQuery('(max-height:500px)')

    const nextThriveResetThumbnail = useMemo(
        () =>
            nextThriveReset?.landscape?.thumbnail ??
            nextThriveReset?.portrait?.thumbnail,
        [nextThriveReset]
    )

    const handleNext = useCallback(
        (isAutoPlay?: boolean) => onNext?.(nextThriveReset, isAutoPlay),
        [nextThriveReset, onNext]
    )

    const handleAutoPlayToggle = useCallback(
        (isAutoplay: boolean) => {
            onAutoPlayToggle?.(nextThriveReset, isAutoplay)
        },
        [nextThriveReset, onAutoPlayToggle]
    )

    return (
        <Stack
            alignItems="center"
            justifyContent={'center'}
            height={'100%'}
            width={'100%'}
            data-testid="PostThriveReset"
            gap={2}
            padding={2}
        >
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    alignItems: 'center',
                    alignSelf: 'center',
                    backgroundColor: alpha(theme.palette.common.black, 0.5),
                    py: theme.spacing(2),
                    px: theme.spacing(3)
                }}
            >
                <CoreTypography variant="h4" color="white">
                    <FormattedMessage
                        defaultMessage="Did you enjoy this Reset?"
                        description="post reset message"
                    />
                </CoreTypography>

                <TrackablePostThriveResetActions
                    thriveReset={thriveReset}
                    utmSource={utmSource}
                    onReplay={onReplay}
                    resetType={resetType}
                />
            </Card>

            <Stack
                direction={isMobileView ? 'column' : 'row'}
                justifyContent={'center'}
                gap={2}
                alignItems={'center'}
                sx={
                    !isMobileLandscape &&
                    !isMobileView && {
                        position: 'absolute',
                        bottom: 20,
                        right: theme.spacing(12)
                    }
                }
            >
                <PostResetNextResetPreview
                    data-testid="PostThriveReset-next-reset"
                    isOpen={isOpen}
                    name={nextThriveReset?.name}
                    description={nextThriveReset?.description}
                    thumbnail={nextThriveResetThumbnail}
                    onNext={handleNext}
                    onAutoPlayToggle={handleAutoPlayToggle}
                />
            </Stack>
        </Stack>
    )
}

export default React.memo(PostThriveReset)
