import React, { useEffect, useMemo, PropsWithChildren } from 'react'
import { Box } from '@mui/material'
import { ClassNames } from '@emotion/react'
import { appearingNew as appearing } from './animations'
import useRestartAnimations from '../../../hooks/useRestartAnimations'
import { defaultFramesCount, defaultDuration } from './constants'
import {
    getAppearingAnimationDuration,
    getAppearingAnimationDelay
} from './utils'
import { framesZIndex } from '../PersonalizedResetPlayer/constants'

export type FrameSwitchAnimationProps = PropsWithChildren<{
    audioDuration?: number
    framesCount?: number
    index: number
    paused: boolean
    restartAnimationToken?: number
}>

function FrameSwitchAnimation(props: FrameSwitchAnimationProps) {
    const {
        audioDuration,
        framesCount,
        index,
        children,
        paused = false,
        restartAnimationToken
    } = props

    const { addElementWithAnimation, restartAnimationsOnElements } =
        useRestartAnimations()

    const frameDuration =
        (audioDuration || defaultDuration) / (framesCount || defaultFramesCount)

    const appearingAnimationStyles = useMemo(() => {
        const appearingDuration = `${getAppearingAnimationDuration(
            frameDuration
        )}ms`
        const appearingDelay = `${getAppearingAnimationDelay(
            frameDuration,
            index
        )}ms`

        return {
            opacity: 0,
            animation: appearing,
            animationName: appearing.name,
            animationDirection: 'normal',
            animationIterationCount: 1,
            animationFillMode: 'forwards',
            animationTimingFunction: 'ease-in-out',
            animationDuration: appearingDuration,
            animationDelay: appearingDelay,
            animationPlayState: paused ? 'paused' : 'running'
        }
    }, [frameDuration, index, paused])

    useEffect(() => {
        if (restartAnimationToken) {
            restartAnimationsOnElements()
        }
    }, [restartAnimationToken, restartAnimationsOnElements])

    return (
        <ClassNames>
            {({ css, cx }) => (
                <Box
                    position="absolute"
                    width="100%"
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    className={cx(css(appearingAnimationStyles))}
                    data-testid="FrameSwitchAppearingAnimation"
                    ref={addElementWithAnimation}
                    sx={{
                        zIndex: framesZIndex - index
                    }}
                >
                    {children}
                </Box>
            )}
        </ClassNames>
    )
}

export default React.memo(FrameSwitchAnimation)
