import React, { MutableRefObject, useCallback, useEffect, useRef } from 'react'
import { ThriveResetAudio } from '../../../graphql/generated/autogenerated'

import withPostThriveAudioResetTracking from '../../../tracking/withPostThriveAudioResetTracking'
import ResetAudioPlayer, {
    ResetAudioPlayerRef,
    type RenderPostResetProps
} from '../../elements/ResetAudioPlayer'
import PostThriveAudioReset from '../ThriveAudioReset/PostThriveAudioReset'

const PostThriveAudioResetTrackable =
    withPostThriveAudioResetTracking(PostThriveAudioReset)

export type ThriveAudioResetPlayerProps = {
    thriveAudioReset: ThriveResetAudio
    autoPlay?: boolean
    onClose: () => void
    utmSource: string
    onNext?: (
        nextThriveAudioReset: ThriveResetAudio,
        isAutoPlay?: boolean
    ) => void
    // player callbacks
    onPlay?: () => void
    onPause?: () => void
    onTimeUpdate?: (newCurrentTime: number) => void
    onEnded?: () => void
    onLoadedMetadata?: () => void
    onVolumeChanged?: () => void
    onError?: () => void
}

function ThriveAudioResetPlayer(
    props: ThriveAudioResetPlayerProps,
    ref: MutableRefObject<HTMLAudioElement>
) {
    const {
        thriveAudioReset,
        onNext,
        onEnded,
        onPlay,
        onLoadedMetadata,
        onVolumeChanged,
        onPause,
        onError,
        autoPlay,
        onClose,
        onTimeUpdate,
        utmSource
    } = props

    const resetAudioPlayerRef = useRef<ResetAudioPlayerRef>(null)

    useEffect(() => {
        if (ref) {
            ref.current = resetAudioPlayerRef.current?.elementRef?.current
        }
    }, [resetAudioPlayerRef.current?.elementRef, ref])

    const handleReplay = useCallback(() => {
        resetAudioPlayerRef.current?.replay()
    }, [])

    const renderPostResetScreen = useCallback(
        ({ isPostResetDisplayed }: RenderPostResetProps) => {
            return (
                <PostThriveAudioResetTrackable
                    isOpen={isPostResetDisplayed}
                    reset={thriveAudioReset}
                    onReplay={handleReplay}
                    onNext={onNext}
                    utmSource={utmSource}
                />
            )
        },
        [thriveAudioReset, utmSource, handleReplay, onNext]
    )

    return (
        <ResetAudioPlayer
            ref={resetAudioPlayerRef}
            src={thriveAudioReset?.mp3Url}
            durationInSeconds={thriveAudioReset?.durationInSeconds}
            autoPlay={autoPlay}
            renderPostReset={renderPostResetScreen}
            onLoadedMetadata={onLoadedMetadata}
            onPlay={onPlay}
            onPause={onPause}
            onEnded={onEnded}
            onClose={onClose}
            onTimeUpdate={onTimeUpdate}
            onVolumeChanged={onVolumeChanged}
            onError={onError}
        />
    )
}

export default React.memo(React.forwardRef(ThriveAudioResetPlayer))
