import type { FallbackProps } from '@thriveglobal/thrive-web-errors'
import React from 'react'
import ErrorBoundaryWrapper from '../ErrorBoundaryWrapper'
import DefaultPageFallback from './PageFallback'

export type WithPageFallbackProps = {
    pageName: string
    PageFallback?: React.ComponentType<FallbackProps>
}

function withPageFallback<T>(
    Page: React.ComponentType<T>,
    { pageName, PageFallback }: WithPageFallbackProps
) {
    return React.memo(function PageWithFallback(props: T) {
        return (
            <ErrorBoundaryWrapper
                boundaryName={pageName}
                boundaryType={'Page'}
                fallbackComponent={PageFallback ?? DefaultPageFallback}
            >
                <Page {...props} />
            </ErrorBoundaryWrapper>
        )
    })
}

export default withPageFallback
